import React from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Icon } from 'ui/atoms';
import { colors } from 'ui/theme';
import { useLanguage } from 'hooks';
import style from './style.module.scss';
export const TechSupportHint = observer(({ isAuth, onClick }) => {
    const { i18n } = useLanguage();
    return (React.createElement("div", { className: style['tech-support__wrapper'] },
        React.createElement("div", { className: style['tech-support__header'] },
            React.createElement(Icon, { name: "headphones", width: "44", height: "50", fill: colors.white }),
            React.createElement("div", { className: style['tech-support__text'] }, i18n.t(isAuth
                ? 'TECHSUPPORT_HINT'
                : 'TECHSUPPORT_HINT_UNREGISTRED'))),
        React.createElement(Button, { theme: "outline-gradient", className: style['tech-support__btn'], onClick: onClick }, i18n.t(isAuth
            ? 'TECHSUPPORT_HINT_CREATE_TICKET'
            : 'TECHSUPPORT_HINT_REGISTER'))));
});
