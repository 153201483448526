var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useController } from 'react-hook-form';
import { isHasError } from 'utils/validate';
import { Input } from 'ui/atoms';
export const FieldText = (_a) => {
    var { name } = _a, rest = __rest(_a, ["name"]);
    const { field, fieldState, formState } = useController({
        name,
    });
    const error = isHasError(fieldState, formState);
    return (React.createElement(Input, Object.assign({ error: error }, rest, field)));
};
