import * as GamesCatalog from './GamesCatalog';
import * as Game from './Game';
export const routes = [
    {
        path: '/catalog',
        component: GamesCatalog.default,
        preload: GamesCatalog.preload,
    },
    {
        path: '/game/:slug',
        component: Game.default,
        preload: Game.preload,
    },
];
