var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import style from './style.module.scss';
export const StatusBox = forwardRef((_a, ref) => {
    var { status } = _a, rest = __rest(_a, ["status"]);
    return (React.createElement("div", Object.assign({ ref: ref, className: style.box, "data-status": status }, rest)));
});
StatusBox.displayName = 'StatusBox';
