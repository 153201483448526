import React from 'react';
import { colors } from 'ui/theme';
import style from './style.module.scss';
const getSize = (size) => {
    switch (size) {
        case 'mlarge':
            return {
                y1: '0',
                x1: '222',
                x2: '222',
                y2: '187',
            };
        case 'small':
            return {
                x1: '85',
                y1: '0',
                x2: '85',
                y2: '245',
            };
        case 'extra-small':
            return {
                x1: '21',
                y1: '0',
                x2: '21',
                y2: '42',
            };
        case 'medium':
        default:
            return {
                x1: '150',
                y1: '0',
                x2: '150',
                y2: '337',
            };
    }
};
const DEFAULT_SIZE = {
    y1: '0',
    x1: '50%',
    x2: '50%',
    y2: '100%',
};
export const GradientBorder = ({ viewBox = '0 0 300 337', rx = '19.5', size, idPrefix, }) => {
    const id = `gradeint-border-${idPrefix}`;
    return (React.createElement("svg", { viewBox: viewBox, fill: "none", xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none", className: style.svg },
        React.createElement("rect", { x: "0.5", y: "0.5", rx: rx, stroke: `url(#${id})` }),
        React.createElement("defs", null,
            React.createElement("linearGradient", Object.assign({ id: id }, (size ? getSize(size) : DEFAULT_SIZE), { gradientUnits: "userSpaceOnUse" }),
                React.createElement("stop", { stopColor: colors.primary }),
                React.createElement("stop", { stopColor: colors.primaryOpacity27, offset: "1" })))));
};
