var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Image } from '../Image';
import style from './style.module.scss';
const getSize = (size) => {
    switch (size) {
        case 'medium':
            return {
                x1: '-64.509',
                y1: '114.171',
                x2: '198.647',
                y2: '-16.8952',
            };
        case 'small':
        default:
            return {
                x1: '-16.976',
                y1: '30.0449',
                x2: '52.2754',
                y2: '-4.44611',
            };
    }
};
export const Avatar = (_a) => {
    var { size = 'small', idPrefix } = _a, rest = __rest(_a, ["size", "idPrefix"]);
    const id = `avatar-${idPrefix}`;
    return (React.createElement("div", { className: style.wrapper },
        React.createElement(Image, Object.assign({}, rest)),
        React.createElement("svg", { className: style.svg, width: "100%", height: "100%", fill: "none", xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none" },
            React.createElement("rect", { x: "1", y: "1", rx: "4", stroke: `url(#${id})`, strokeWidth: "2" }),
            React.createElement("defs", null,
                React.createElement("linearGradient", Object.assign({ id: id, gradientUnits: "userSpaceOnUse" }, getSize(size)),
                    React.createElement("stop", { stopColor: "#FF7549" }),
                    React.createElement("stop", { offset: "1", stopColor: "#FF7549", stopOpacity: "0" }))))));
};
