import { action, computed, makeObservable, observable, } from 'mobx';
import { getDefaultLanguageState, Language, languageMask, } from 'utils/constants';
import { isBrowser, isNotNull } from 'utils/selectors';
import http from 'utils/http';
import { catchError } from 'utils/catchError';
export class CommonStore {
    constructor(store) {
        Object.defineProperty(this, "store", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "cookieSSROnly", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "language", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: Language.RU
        });
        Object.defineProperty(this, "authModalVisible", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "mobileMenuVisible", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "pending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "settings", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: getDefaultLanguageState()
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "setLanguage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (language) => this.language = language
        });
        Object.defineProperty(this, "setCookie", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (cookie) => this.cookieSSROnly = cookie
        });
        Object.defineProperty(this, "setStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (status) => this.status = status
        });
        Object.defineProperty(this, "fetchPending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => this.pending = true
        });
        Object.defineProperty(this, "fetchSuccessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (settings) => {
                this.pending = false;
                this.settings = settings;
            }
        });
        Object.defineProperty(this, "fetchFailed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (error) => {
                this.pending = false;
                this.error = error || null;
            }
        });
        Object.defineProperty(this, "toggleAuthModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (val) => {
                if (val) {
                    document.body.classList.add('disable-scroll');
                }
                else {
                    document.body.classList.remove('disable-scroll');
                }
                this.authModalVisible = isNotNull(val)
                    ? val
                    : !this.authModalVisible;
            }
        });
        Object.defineProperty(this, "toggleMobileMenu", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (val) => this.mobileMenuVisible = isNotNull(val)
                ? val
                : !this.mobileMenuVisible
        });
        Object.defineProperty(this, "changeLanguage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (newLang) => {
                if (isBrowser) {
                    const { pathname, search } = this.store.routingStore.location;
                    const switchToRussion = newLang === Language.RU;
                    const path = pathname.replace(languageMask, '');
                    const newLangPrefix = switchToRussion ? '' : `/${Language.EN}`;
                    this.store.routingStore.history.replace(`${newLangPrefix}${path === '/'
                        ? ''
                        : path}${search}`);
                    this.setLanguage(newLang);
                    return;
                }
                this.setLanguage(newLang);
            }
        });
        Object.defineProperty(this, "fetchSettignsList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                if (Object.keys(this.settings[this.language]).length)
                    return;
                this.fetchPending();
                try {
                    const settings = await http.get('/settings');
                    this.fetchSuccessed(settings);
                }
                catch (e) {
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                }
            }
        });
        Object.defineProperty(this, "redirectToTechSupport", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { isAuth } = this.store.authStore;
                if (isAuth) {
                    this.store.routingStore.push('/profile/tickets');
                }
                else {
                    this.toggleAuthModal();
                }
            }
        });
        Object.defineProperty(this, "mobileRedirectToTechSupport", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                const { isAuth } = this.store.authStore;
                if (isAuth) {
                    this.store.routingStore.push('/profile/tickets');
                }
                else {
                    this.toggleMobileMenu();
                    this.toggleAuthModal();
                }
            }
        });
        this.store = store;
        makeObservable(this, {
            status: observable,
            cookieSSROnly: observable,
            language: observable,
            authModalVisible: observable,
            mobileMenuVisible: observable,
            pending: observable,
            settings: observable,
            error: observable,
            settingsList: computed,
            headers: computed,
            setLanguage: action.bound,
            setCookie: action.bound,
            setStatus: action.bound,
            fetchPending: action.bound,
            fetchSuccessed: action.bound,
            fetchFailed: action.bound,
            toggleAuthModal: action.bound,
            toggleMobileMenu: action.bound,
            fetchSettignsList: action,
            changeLanguage: action,
            redirectToTechSupport: action,
            mobileRedirectToTechSupport: action,
        });
    }
    get settingsList() {
        return this.settings[this.language];
    }
    get headers() {
        const headers = {};
        if (this.cookieSSROnly) {
            headers.Cookie = this.cookieSSROnly;
        }
        return headers;
    }
}
