import React, { useCallback } from 'react';
import { Language } from 'utils/constants';
import { useLanguage } from 'hooks';
import { colors } from 'ui/theme';
import { Icon } from '../Icon';
import style from './style.module.scss';
const LangPreview = {
    en: 'ENG',
    ru: 'RUS',
};
export const LanguageSwitcher = ({ onChange }) => {
    const { changeLanguage, language } = useLanguage();
    const switchLanguage = language === Language.RU
        ? Language.EN
        : Language.RU;
    const handleChange = useCallback(() => {
        changeLanguage(switchLanguage);
        onChange === null || onChange === void 0 ? void 0 : onChange();
    }, [switchLanguage]);
    return (React.createElement("button", { type: "button", className: style.wrapper },
        React.createElement("div", { className: style.button, "data-disabled": "true" },
            React.createElement(Icon, { name: "earth", width: "14", height: "14", fill: colors.primary }),
            React.createElement("span", null, LangPreview[language]),
            React.createElement(Icon, { name: "sm-arrow-down", width: "10", height: "10", fill: colors.primary })),
        React.createElement("div", { className: style.dropdown },
            React.createElement("div", { className: style.button, "data-disabled": "false", onClick: handleChange },
                React.createElement("span", null, LangPreview[switchLanguage])))));
};
