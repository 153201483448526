import React, { memo } from 'react';
import { useLanguage, useRole } from 'hooks';
import { MessageType } from 'utils/constants';
import { formatMsgDate } from 'utils/functions';
import { Files, NeedHelpRequest, Text } from './components';
const MessageComponent = ({ ticketAuthorId, ticketId, ownerId, message, }) => {
    const { author, text, type, createdAt, files } = message;
    const lang = useLanguage();
    const { user, isOwner, isTechSupport } = useRole();
    const isMyMessage = user.id === author.id;
    const date = formatMsgDate(createdAt, lang);
    const nickname = isOwner || isTechSupport
        ? author.login
        : (ticketAuthorId === user.id && author.id === user.id
            ? author.login
            : (author.id === ownerId
                ? lang.i18n.t('TICKET_MESSAGE_OWNER')
                : lang.i18n.t('TICKET_MESSAGE_TECH_SUPPORT')));
    switch (type) {
        case MessageType.Files:
        case MessageType.TextWithFiles:
            return (React.createElement(Files, { files: files, isMyMessage: isMyMessage, nickname: nickname, ticketId: ticketId, createdAt: date, text: text }));
        case MessageType.NeedHelpRequest:
        case MessageType.AutoClosed:
            return (React.createElement(NeedHelpRequest, { type: type, createdAt: date }));
        case MessageType.Text:
        default:
            return (React.createElement(Text, { isMyMessage: isMyMessage, nickname: nickname, createdAt: date, text: text }));
    }
};
export const Message = memo(MessageComponent);
