import React, { memo, useState, useCallback, } from 'react';
import { useLanguage, useMobile } from 'hooks';
import { Avatar } from 'ui/atoms';
import { trim } from 'utils/string';
import style from './style.module.scss';
const SimpleChangeAvatarForm = ({ login, avatarJPEG, avatarWebP, changeAvatarAction, }) => {
    const [isLoading, setIsLoading] = useState(false);
    const { i18n } = useLanguage();
    const { isAboveTablet } = useMobile();
    const onChange = useCallback(async ({ target }) => {
        var _a;
        if ((_a = target.files) === null || _a === void 0 ? void 0 : _a[0]) {
            setIsLoading(true);
            await changeAvatarAction(target.files[0]);
            setIsLoading(false);
        }
    }, []);
    return (React.createElement("div", { className: style.wrapper },
        React.createElement(Avatar, Object.assign({ alt: login, size: "medium", idPrefix: trim(login), jpeg: avatarJPEG, webp: avatarWebP, className: style.avatar }, (isAboveTablet
            ? {
                width: '169px',
                height: '169px',
            }
            : {}))),
        React.createElement("input", { type: "file", id: "avatar-form", accept: "image/png, image/jpeg", className: style.hidden, onChange: onChange, disabled: isLoading }),
        React.createElement("label", { htmlFor: "avatar-form", className: style.label },
            React.createElement("div", { className: style.btn }, i18n.t('PERSONAL_CHNAGE_AVATAR_SUBMIT')))));
};
export default memo(SimpleChangeAvatarForm);
