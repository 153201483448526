import React, { memo, useCallback, useState, useRef, } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useLayoutSSR, useSocket } from 'hooks';
import { createSearch } from 'utils/http';
import { getUrlParam } from 'utils/selectors';
import { TicketHeader, DialogHistory, } from './components';
import style from './style.module.scss';
const TicketItemRowComponent = ({ id, createdAt, subject, messages, needOwnerHelp, closed, unread, authorId, lastSenderId, techsupportId, ownerId, messagerStatus, needHelpActionVisible, needOwnerHelpAction, blockUserByTicketAction, }) => {
    const isMounted = useRef(false);
    const { useAfterConnect } = useSocket();
    const location = useLocation();
    const history = useHistory();
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = useCallback(() => {
        const ticket = getUrlParam(location.search, 'ticket');
        if (ticket) {
            const ids = ticket.split(',');
            const isIdInParams = ids.includes(id);
            const newTicketParam = isIdInParams
                ? ids.filter(param => param !== id)
                : [...ids, id];
            setIsOpen(!isIdInParams);
            history.replace(`${location.pathname}${createSearch({
                ticket: newTicketParam.join(',') || null,
            })}`);
            return;
        }
        setIsOpen(true);
        history.replace(`${location.pathname}${createSearch({
            ticket: id,
        })}`);
    }, [location.search]);
    useLayoutSSR(() => {
        const ticket = getUrlParam(location.search, 'ticket');
        if (ticket) {
            const ids = ticket.split(',');
            const isIdInParams = ids.includes(id);
            if (isIdInParams) {
                setIsOpen(true);
            }
        }
    }, []);
    useAfterConnect(ws => {
        if (!isMounted.current) {
            return isMounted.current = true;
        }
        if (isOpen) {
            ws.emit('request-ticket-messages', {
                ticketId: id,
            });
        }
    }, [isOpen]);
    return (React.createElement("div", { className: style.wrapper },
        React.createElement(TicketHeader, { createdAt: createdAt, subject: subject, isOpen: isOpen, closed: closed, unread: unread, authorId: authorId, needOwnerHelp: needOwnerHelp, lastSenderId: lastSenderId, toggleIsOpen: toggleIsOpen }),
        isOpen && messages && (React.createElement(DialogHistory, { ticketId: id, messages: messages, messagerStatus: messagerStatus, authorId: authorId, ownerId: ownerId, techsupportId: techsupportId, needHelpActionVisible: needHelpActionVisible, needOwnerHelpAction: needOwnerHelpAction, blockUserByTicketAction: blockUserByTicketAction }))));
};
export const TicketItemRow = memo(TicketItemRowComponent);
