import { computedFn as originalComputedFn } from 'mobx-utils';
import { isUsingStaticRendering } from 'mobx-react-lite';
import format from 'date-fns/format';
import isToday from 'date-fns/isToday';
import parseISO from 'date-fns/parseISO';
import isYesterday from 'date-fns/isYesterday';
import { isBrowser } from './selectors';
export const computedFn = (fn) => isUsingStaticRendering()
    ? fn
    : originalComputedFn(fn);
export const noop = () => { };
export const computeIsMobile = () => isBrowser
    ? window.matchMedia('(max-width: 767px)').matches
    : false;
export const computeIsTablet = () => isBrowser
    ? window.innerWidth >= 768 && window.innerWidth < 1024
    : false;
export const computeIsDesktop = () => isBrowser
    ? window.innerWidth >= 1024
    : false;
export const debounce = (func, waitFor) => {
    let timeout = null;
    const debounced = (...args) => {
        if (timeout !== null) {
            clearTimeout(timeout);
            timeout = null;
        }
        timeout = setTimeout(() => func(...args), waitFor);
    };
    return debounced;
};
export const throttle = (func, delay) => {
    let timeout = null;
    const trottled = (...args) => {
        if (!timeout) {
            timeout = setTimeout(() => {
                func.call(this, ...args);
                timeout = null;
            }, delay);
        }
    };
    return trottled;
};
export const scrollToTop = () => window.scrollTo({ top: 0, behavior: 'auto' });
export const MBToBytes = (mg) => mg * 1024 * 1024;
export const formatMsgDate = (createdAt, { i18n, locale }) => {
    const createdAtDate = parseISO(createdAt);
    return isToday(createdAtDate)
        ? `${i18n.t('TICKET_MESSAGE_TODAY')} - ${format(createdAtDate, 'HH:mm', { locale })}`
        : (isYesterday(createdAtDate)
            ? `${i18n.t('TICKET_MESSAGE_YESTERDAY')} - ${format(createdAtDate, 'HH:mm', { locale })}`
            : format(createdAtDate, 'dd MMMM yyyy - HH:mm', { locale }));
};
