import { useLanguage } from 'hooks';
import React from 'react';
import { Link } from 'react-router-dom';
import { FieldCheckbox } from '../FieldCheckbox';
import style from './style.module.scss';
export const FieldTermOfUse = () => {
    const { i18n, makeUrl } = useLanguage();
    return (React.createElement(React.Fragment, null,
        React.createElement(FieldCheckbox, { name: "termOfUse", className: style.wrapper },
            i18n.t('TERM_OF_USE_CHECKBOX_1'),
            " ",
            React.createElement(Link, { to: makeUrl('/term-of-use'), className: style.link }, i18n.t('TERM_OF_USE_CHECKBOX_2'))),
        React.createElement(FieldCheckbox, { name: "personalData", className: style.wrapper },
            i18n.t('DATA_PROCESSING_CHECKBOX_1'),
            " ",
            React.createElement(Link, { to: makeUrl('/personal'), className: style.link }, i18n.t('DATA_PROCESSING_CHECKBOX_2')))));
};
