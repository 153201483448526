import { useContext, useEffect } from 'react';
import { SocketContext } from 'providers';
export const useSocket = () => {
    const { socket } = useContext(SocketContext);
    const useAfterConnect = (cb, deps = []) => useEffect(() => {
        const listener = () => cb(socket);
        if (socket) {
            if (socket.connected) {
                cb(socket);
            }
            else {
                socket.on('connect', listener);
            }
        }
        return () => {
            if (socket) {
                socket.off('connect', listener);
            }
        };
    }, [socket, ...deps]);
    return {
        socket,
        useAfterConnect,
    };
};
