import React from 'react';
import { useLanguage, useMobile } from 'hooks';
import { ProductHeader } from 'ui/molecules';
import { AccountCardDetail } from 'ui/organisms';
const AccountTemplate = ({ account }) => {
    const { isMobile } = useMobile();
    const { i18n } = useLanguage();
    return (React.createElement(React.Fragment, null,
        React.createElement(ProductHeader, { alt: account.name, jpeg: isMobile
                ? account.backgroundVerticalJPEG
                : account.backgroundHorizontalJPEG, webp: isMobile
                ? account.backgroundVerticalWebP
                : account.backgroundHorizontalWebP, title: i18n.t('ACCOUNT_TITLE'), subTitle: account.name }),
        React.createElement(AccountCardDetail, { account: account })));
};
export default AccountTemplate;
