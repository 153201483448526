import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from 'hooks';
import style from './style.module.scss';
export const Logo = ({ text = true }) => {
    const { makeUrl } = useLanguage();
    return (React.createElement(Link, { to: makeUrl('/'), className: style.logo, "data-name": "logo" },
        React.createElement("svg", { id: "eh-logo", height: "39", width: "33.1", xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 34 39" },
            React.createElement("path", { fill: "url(#paint0_linear)", d: "M19.47 1.37s.88 5.69 3.62 9.8c2.74 4.12 10.01 5.99 10.01 5.99L19.47 1.36z" }),
            React.createElement("path", { fill: "url(#paint1_linear)", d: "M18.36 0c.02.25-1.82 38.98-1.82 38.98L27.9 24s-4.53 3.52-6.6 1.82C19.22 24.1 18.35 0 18.35 0z" }),
            React.createElement("path", { fill: "url(#paint2_linear)", d: "M21.37 25.57s5.77 1 11.73-8.41c0 0-7.15-2.8-10.01-5.99l4.43 6.68-6.15 7.72z" }),
            React.createElement("path", { fill: "url(#paint3_linear)", d: "M16.54 38.98s3.14-6.43 2.93-11.36C19.23 22.26 18.39.59 18.39.59l-1.85 38.4z" }),
            React.createElement("path", { fill: "url(#paint4_linear)", d: "M27.52 17.85l5.58-.69s-6.02-2.09-10.01-5.99l4.43 6.68z" }),
            React.createElement("path", { fill: "url(#paint5_linear)", d: "M13.64 1.38s-.88 5.7-3.62 9.8C7.28 15.3 0 17.19 0 17.19l13.64-15.8z" }),
            React.createElement("path", { fill: "url(#paint6_linear)", d: "M14.74.02C14.72.27 16.56 39 16.56 39L5.2 24.01s4.54 3.53 6.6 1.82C13.88 24.13 14.75.02 14.75.02z" }),
            React.createElement("path", { fill: "url(#paint7_linear)", d: "M11.73 25.59s-5.77 1-11.73-8.41c0 0 7.15-2.8 10.02-6l-4.44 6.68 6.15 7.73z" }),
            React.createElement("path", { fill: "url(#paint8_linear)", d: "M16.56 39s-3.14-6.43-2.92-11.37C13.87 22.27 14.72.61 14.72.61L16.56 39z" }),
            React.createElement("path", { fill: "url(#paint9_linear)", d: "M5.58 17.86L0 17.18s6.02-2.09 10.02-6l-4.44 6.68z" }),
            React.createElement("path", { fill: "url(#paint10_linear)", d: "M9.89 22.96c-.63-3.68 2.35-6.6 6.65-6.52 4.3.08 7.28 3.06 6.66 6.66a8.3 8.3 0 01-.05.24l2.04-2.56c.8-4.87-3.07-8.91-8.65-9.02-5.59-.1-9.46 3.85-8.65 8.84l.04.21 2.04 2.57a8.6 8.6 0 01-.08-.42z" }),
            React.createElement("defs", null,
                React.createElement("linearGradient", { id: "paint0_linear", x1: "19.47", x2: "33.1", y1: "9.26", y2: "9.26", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#FCEE21" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "paint1_linear", x1: "18.3", x2: "23.77", y1: "-2.06", y2: "44.05", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#F7931E" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "paint2_linear", x1: "21.45", x2: "30.08", y1: "27.67", y2: "10.37", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { offset: ".04", stopColor: "#F15A24" }),
                    React.createElement("stop", { offset: ".49", stopColor: "#FBB03B" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "paint3_linear", x1: "1203.64", x2: "1763.82", y1: "27841.3", y2: "27835.8", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#F7931E" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "paint4_linear", x1: "31.9", x2: "27.81", y1: "23.04", y2: "13.62", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", null),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24", stopOpacity: "0" })),
                React.createElement("linearGradient", { id: "paint5_linear", x1: "-27350.9", x2: "-28478", y1: "10363.4", y2: "10363.4", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#FCEE21" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "paint6_linear", x1: "-22687.8", x2: "-26498.1", y1: "22920.7", y2: "32354.8", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#F7931E" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "paint7_linear", x1: "-23629.2", x2: "-24463", y1: "11077.4", y2: "9708.66", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { offset: ".04", stopColor: "#F15A24" }),
                    React.createElement("stop", { offset: ".49", stopColor: "#FBB03B" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "paint8_linear", x1: "-5508.32", x2: "-6068.5", y1: "27844.8", y2: "27839.4", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#F7931E" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "paint9_linear", x1: "-20765.5", x2: "-20645.9", y1: "4944.32", y2: "4528.82", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", null),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24", stopOpacity: "0" })),
                React.createElement("linearGradient", { id: "paint10_linear", x1: "7.78", x2: "25.29", y1: "17.57", y2: "17.57", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { offset: ".04", stopColor: "#F15A24" }),
                    React.createElement("stop", { offset: ".53", stopColor: "#FBB03B" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })))),
        text && (React.createElement("svg", { id: "eh-text", width: "197.65", height: "13.97", xmlns: "http://www.w3.org/2000/svg", fill: "none", viewBox: "0 0 198 15" },
            React.createElement("path", { fill: "url(#eh-text0_linear)", d: "M8.018 5.88h12.051l-.73 3.46H7.288L6.9 11.136h12.051l-.73 3.46H.349L2.862 2.7.822.624h20.343l-.73 3.482H8.383L8.018 5.88z" }),
            React.createElement("path", { fill: "url(#eh-text1_linear)", d: "M28.189 11.136h11.128l-.73 3.46h-16.95L24.15 2.7 22.11.624h8.292l-2.213 10.512z" }),
            React.createElement("path", { fill: "url(#eh-text2_linear)", d: "M41.722.624h8.292l-2.943 13.972H41.25L43.763 2.7 41.722.624z" }),
            React.createElement("path", { fill: "url(#eh-text3_linear)", d: "M50.959.624h19.42l-.73 3.482h-5.715l-2.212 10.49H55.9l2.212-10.49h-3.888L50.96.624z" }),
            React.createElement("path", { fill: "url(#eh-text4_linear)", d: "M78.52 5.88H90.57l-.73 3.46H77.789l-.386 1.796h12.051l-.73 3.46H70.85L73.364 2.7 71.324.624h20.343l-.73 3.482H78.885L78.52 5.88z" }),
            React.createElement("path", { fill: "url(#eh-text5_linear)", d: "M92.611.624h8.292L99.808 5.88h6.23l1.095-5.256h5.822l-2.943 13.972h-5.822l1.117-5.256h-6.23l-1.117 5.256H92.14L94.652 2.7 92.612.624z" }),
            React.createElement("path", { fill: "url(#eh-text6_linear)", d: "M131.3 14.596h-5.822l.946-4.477h-6.23l-.946 4.477h-5.821L115.94 2.7 113.899.624h20.344L131.3 14.596zm-10.376-7.938h6.23l.537-2.552h-6.23l-.537 2.552z" }),
            React.createElement("path", { fill: "url(#eh-text7_linear)", d: "M141.246 11.136h11.986l-.73 3.46h-17.809L137.207 2.7 135.166.624h20.279l-.73 3.482h-11.987l-1.482 7.03z" }),
            React.createElement("path", { fill: "url(#eh-text8_linear)", d: "M156.39.624h8.292l-1.096 5.256h3.051l3.716-2.53.559-2.726h5.821l-.945 4.52-3.673 2.488 2.599 2.444-.945 4.52h-5.822l.58-2.725-2.599-2.53h-3.072l-1.117 5.255h-5.822L158.43 2.7 156.39.624z" }),
            React.createElement("path", { fill: "url(#eh-text9_linear)", d: "M177.205 14.596l.73-3.46h12.03l.387-1.796h-12.03l1.396-6.64-2.04-2.076H198l-.731 3.482h-12.03l-.365 1.774h12.03l-1.847 8.716h-17.852z" }),
            React.createElement("defs", null,
                React.createElement("linearGradient", { id: "eh-text0_linear", x1: "-3.245", x2: "47.587", y1: "48.135", y2: "-111.031", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#FBB03B" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "eh-text1_linear", x1: "11740", x2: "15219.2", y1: "13869.3", y2: "-9.844", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#FBB03B" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "eh-text2_linear", x1: "6528.54", x2: "12468.9", y1: "14212.2", y2: "2464.65", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#FBB03B" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "eh-text3_linear", x1: "15949.2", x2: "19149.1", y1: "14560.1", y2: "538.952", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#FBB03B" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "eh-text4_linear", x1: "19561.9", x2: "22566.4", y1: "15081.6", y2: "969.945", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#FBB03B" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "eh-text5_linear", x1: "22023.2", x2: "25027.8", y1: "15601.1", y2: "1489.45", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#FBB03B" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "eh-text6_linear", x1: "24484.6", x2: "27489.1", y1: "16120.6", y2: "2008.96", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#FBB03B" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "eh-text7_linear", x1: "26856.7", x2: "29869.7", y1: "16638.8", y2: "2530.97", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#FBB03B" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "eh-text8_linear", x1: "29396.1", x2: "32400.6", y1: "17157.3", y2: "3045.62", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#FBB03B" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
                React.createElement("linearGradient", { id: "eh-text9_linear", x1: "31824.8", x2: "34832.1", y1: "17676.8", y2: "3566.4", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#FBB03B" }),
                    React.createElement("stop", { offset: "1", stopColor: "#F15A24" })))))));
};
