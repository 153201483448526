var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback, } from 'react';
import { observer } from 'mobx-react-lite';
import { useFormContext } from 'react-hook-form';
import { DropZone, MessagerTextarea } from 'ui/atoms';
import { useLanguage, useStore } from 'hooks';
import { MBToBytes } from 'utils/functions';
import { errorMsg } from 'utils/validate';
const ALLOWED_FORMATS = ['PNG', 'JPEG', 'JPG'];
const filterFiles = (files, maxMB) => files
    .filter(file => file.size < MBToBytes(maxMB)
    && ['image/png', 'image/jpeg', 'image/jpg'].includes(file.type));
const FieldsMessagerCompnent = (_a) => {
    var _b, _c;
    var { id, textareaName, filesName, maxMB = 4, files = [], dropZoneClassName } = _a, rest = __rest(_a, ["id", "textareaName", "filesName", "maxMB", "files", "dropZoneClassName"]);
    const { i18n, language } = useLanguage();
    const { watch, register, setError, clearErrors, formState, } = useFormContext();
    const { ticketsStore } = useStore();
    const textarea = register(textareaName);
    const textareaError = formState.touchedFields[textareaName] && ((_b = formState.errors[textareaName]) === null || _b === void 0 ? void 0 : _b.message);
    const filesError = formState.touchedFields[filesName] && ((_c = formState.errors[filesName]) === null || _c === void 0 ? void 0 : _c.message);
    const textareaValue = watch(textareaName);
    const showError = useCallback((files, filtredFiles) => filtredFiles.length !== files.length
        && setError(filesName, errorMsg(i18n.t('VALIDATE_MAX_SIZE_MB')
            .replace('#mb#', `${maxMB}`)
            .replace('#formats#', ALLOWED_FORMATS.join(', ')))), []);
    const onFilesChange = useCallback(({ target }) => {
        var _a;
        clearErrors(filesName);
        if (!((_a = target.files) === null || _a === void 0 ? void 0 : _a.length))
            return;
        const files = [...target.files];
        const filtredFiles = filterFiles(files, maxMB);
        ticketsStore.addFiles(id, filtredFiles);
        showError(files, filtredFiles);
    }, [language]);
    const removeFile = useCallback((fileId) => {
        ticketsStore.removeFile(id, fileId);
        clearErrors(filesName);
    }, []);
    const pasteFiles = useCallback(event => {
        clearErrors(filesName);
        if (!event.clipboardData.files.length)
            return;
        event.preventDefault();
        const files = [...event.clipboardData.files];
        const filtredFiles = filterFiles(files, maxMB);
        ticketsStore.addFiles(id, filtredFiles);
        showError(files, filtredFiles);
    }, []);
    const handleDrop = useCallback((files) => {
        clearErrors(filesName);
        if (!files.length)
            return;
        const filtredFiles = filterFiles(files, maxMB);
        ticketsStore.addFiles(id, filtredFiles);
        showError(files, filtredFiles);
    }, []);
    return (React.createElement(React.Fragment, null,
        !rest.textareaDisabled && (React.createElement(DropZone, { className: dropZoneClassName, onDrop: handleDrop })),
        React.createElement(MessagerTextarea, Object.assign({ id: id }, textarea, rest, { value: textareaValue, files: files, onFilesChange: onFilesChange, pasteFiles: pasteFiles, removeFile: removeFile, error: filesError && textareaError
                ? `${filesError}\n${textareaError}`
                : filesError || textareaError }))));
};
export const FieldsMessager = observer(FieldsMessagerCompnent);
