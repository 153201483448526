import React from 'react';
import { useLanguage } from 'hooks';
import { MessageType } from 'utils/constants';
import style from './style.module.scss';
export const NeedHelpRequest = ({ type, createdAt }) => {
    const { i18n } = useLanguage();
    return (React.createElement("div", { className: style['need-help-request'] },
        React.createElement("div", { className: style['need-help-request__content'] }, i18n.t(type === MessageType.NeedHelpRequest
            ? 'TICKET_NEED_HELP_REQUEST'
            : 'TICKET_AUTOMATICALLY_CLOSED')),
        React.createElement("div", { className: style['need-help-request__date'] }, createdAt)));
};
