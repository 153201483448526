import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useMobile, useStore } from 'hooks';
import { FAQTemplate } from 'ui/templates';
import { observer } from 'mobx-react-lite';
const FAQ = () => {
    const { commonStore } = useStore();
    const { isAboveTablet } = useMobile();
    const redirectToTechSupportAction = commonStore.redirectToTechSupport;
    const mobileRedirectToTechSupportAction = commonStore.mobileRedirectToTechSupport;
    const settings = commonStore.settingsList;
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            settings.faqMetaTitle && (React.createElement("title", null, settings.faqMetaTitle)),
            settings.faqMetaDescription && (React.createElement("meta", { name: "description", content: settings.faqMetaDescription }))),
        React.createElement(FAQTemplate, { redirectToTechSupportAction: isAboveTablet
                ? redirectToTechSupportAction
                : mobileRedirectToTechSupportAction })));
};
export default observer(FAQ);
