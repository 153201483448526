import React from 'react';
import { observer } from 'mobx-react-lite';
import { Container, CrosshairsDecore, LoadMoreButton, } from 'ui/atoms';
import { ProblemCard, TechSupportFooter } from 'ui/molecules';
import SimpleSearchForm from 'forms/SimpleSearchForm';
import { useLanguage, useMobile } from 'hooks';
import style from './style.module.scss';
const ProblemsCatalog = ({ search = null, problems, isLoading, isShowLoadMore, loadMoreAction, redirectToTechSupportAction, }) => {
    const { i18n } = useLanguage();
    const { isAboveTablet } = useMobile();
    const hasLoadMore = isShowLoadMore && problems.length !== 0;
    return (React.createElement(Container, { className: style.container },
        isAboveTablet && (React.createElement(CrosshairsDecore, null)),
        React.createElement(SimpleSearchForm, { pathname: "/problems", titleI18n: "PROBLEMS_CATALOG_TITLE", className: style.search }),
        React.createElement("div", { className: style.grid__wrapper, "data-load-more": hasLoadMore },
            (search && !problems.length) && (React.createElement("div", { className: style['not-found'] }, i18n.t('SEARCH_NOT_FOUND'))),
            React.createElement("div", { className: style.grid }, problems.map(({ id, slug, localization }) => (React.createElement(ProblemCard, { key: id, to: `/problem/${slug}`, title: localization.title, description: localization.shortDescription }))))),
        React.createElement("div", null, hasLoadMore && (React.createElement(LoadMoreButton, { className: style['load-more'], onClick: loadMoreAction, disabled: isLoading }, i18n.t('SHOW_MORE')))),
        React.createElement(TechSupportFooter, { redirectToTechSupportAction: redirectToTechSupportAction })));
};
export default observer(ProblemsCatalog);
