import NProgress from 'nprogress';
import { action, makeObservable, observable } from 'mobx';
import http from 'utils/http';
import { catchError } from 'utils/catchError';
import { isBrowser } from 'utils/selectors';
import { unregister } from 'registerServiceWorker';
export class AuthStore {
    constructor(store) {
        Object.defineProperty(this, "store", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "isAuth", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "data", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "pending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "fetchPending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                this.pending = true;
                this.error = null;
            }
        });
        Object.defineProperty(this, "authorize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (user) => {
                this.pending = false;
                this.isAuth = true;
                this.data = user;
            }
        });
        Object.defineProperty(this, "fetchFailed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (error) => {
                this.pending = false;
                this.error = error || null;
            }
        });
        Object.defineProperty(this, "setIsAuth", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (isAuth) => this.isAuth = isAuth
        });
        Object.defineProperty(this, "setLogout", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => this.isAuth = false
        });
        Object.defineProperty(this, "login", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (values) => {
                this.fetchPending();
                NProgress.start();
                const body = Object.assign({}, values);
                try {
                    const { user } = await http.post('/users/login', body);
                    this.authorize(user);
                    NProgress.done();
                    this.store.routingStore.push('/profile');
                }
                catch (e) {
                    NProgress.done();
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                    if (e.status === 429) {
                        return 'too-many-requests';
                    }
                    return 'invalid-password';
                }
            }
        });
        Object.defineProperty(this, "register", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (values) => {
                this.fetchPending();
                NProgress.start();
                const body = {
                    login: values.login,
                    password: values.password,
                    email: values.email,
                    isReceiveNews: values.isReceiveNews,
                };
                try {
                    const { user } = await http.post('/users/register', body);
                    this.authorize(user);
                    NProgress.done();
                    this.store.routingStore.push('/profile');
                    return true;
                }
                catch (e) {
                    NProgress.done();
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                    return false;
                }
            }
        });
        Object.defineProperty(this, "profile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                if (!this.isAuth)
                    return;
                this.fetchPending();
                try {
                    const { user } = await http.get('/users/profile', {
                        headers: this.store.commonStore.headers,
                    });
                    this.authorize(user);
                }
                catch (e) {
                    if (!isBrowser) {
                        this.setIsAuth(false);
                    }
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                }
            }
        });
        Object.defineProperty(this, "logout", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                try {
                    unregister();
                    await http.post('/users/logout');
                    await this.store.bestGamesStore.fetchBestGamesList();
                    this.setLogout();
                    this.store.routingStore.push('/');
                }
                catch (e) {
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                }
            }
        });
        Object.defineProperty(this, "changeAvatar", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (avatar) => {
                this.fetchPending();
                NProgress.start();
                const body = new FormData();
                body.append('avatar', avatar);
                try {
                    const { user } = await http.post('/users/update/avatar', body);
                    this.authorize(user);
                    NProgress.done();
                }
                catch (e) {
                    NProgress.done();
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                }
            }
        });
        Object.defineProperty(this, "changePassword", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (values) => {
                this.fetchPending();
                NProgress.start();
                const body = {
                    oldPassword: values.oldPassword,
                    newPassword: values.newPassword,
                };
                try {
                    const { user } = await http.post('/users/update/password', body);
                    this.authorize(user);
                    NProgress.done();
                    return true;
                }
                catch (e) {
                    NProgress.done();
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                    return false;
                }
            }
        });
        Object.defineProperty(this, "changeEmail", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (values) => {
                this.fetchPending();
                NProgress.start();
                const body = {
                    email: values.email,
                };
                try {
                    const { user } = await http.post('/users/update/email', body);
                    this.authorize(user);
                    NProgress.done();
                    return true;
                }
                catch (e) {
                    NProgress.done();
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                    return false;
                }
            }
        });
        Object.defineProperty(this, "changeLogin", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (newLogin) => {
                this.fetchPending();
                NProgress.start();
                const body = {
                    login: newLogin,
                };
                try {
                    const { user } = await http.post('/users/update/login', body);
                    this.authorize(user);
                    NProgress.done();
                    return true;
                }
                catch (e) {
                    NProgress.done();
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                    return false;
                }
            }
        });
        this.store = store;
        makeObservable(this, {
            isAuth: observable,
            data: observable,
            pending: observable,
            error: observable,
            fetchPending: action.bound,
            authorize: action.bound,
            fetchFailed: action.bound,
            setIsAuth: action.bound,
            setLogout: action.bound,
            login: action,
            register: action,
            profile: action,
            logout: action,
            changePassword: action,
            changeEmail: action,
            changeAvatar: action,
            changeLogin: action,
        });
    }
}
