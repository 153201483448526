import React, { useRef, useCallback, } from 'react';
import { useMobile, useStatusSingletoneTooltip, } from 'hooks';
import { StatusBox } from 'ui/atoms';
import { getDocument } from 'utils/selectors';
import style from './style.module.scss';
export const StatusLazyTooltip = ({ id, status }) => {
    const { isMobile } = useMobile();
    const tooltip = useStatusSingletoneTooltip();
    const statusBoxRef = useRef(null);
    const handleDestroy = useCallback(() => tooltip.destroy(id), []);
    const handleMouseEnter = useCallback(() => {
        if (!statusBoxRef.current)
            return;
        tooltip.init(id, status, statusBoxRef.current);
        if (isMobile) {
            getDocument().addEventListener('touchstart', handleMissTouch);
        }
    }, [isMobile]);
    const handleMissTouch = useCallback(event => {
        if (!statusBoxRef.current
            || statusBoxRef.current.contains(event.target))
            return;
        getDocument().removeEventListener('touchstart', handleMissTouch);
        handleDestroy();
    }, []);
    return (React.createElement("div", { "data-name": "status-tooltip", className: style.wrapper, onMouseEnter: isMobile
            ? undefined
            : handleMouseEnter, onMouseLeave: handleDestroy, onPointerEnter: isMobile
            ? handleMouseEnter
            : undefined },
        React.createElement(StatusBox, { ref: statusBoxRef, status: status })));
};
