import React, { memo, useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { FieldText } from 'ui/molecules';
import { Button, Icon } from 'ui/atoms';
import { useLanguage, useHookForm } from 'hooks';
import { colors } from 'ui/theme';
import { errorMsg, validateMinLength, validateRequired, } from 'utils/validate';
import style from './style.module.scss';
const INITIAL_VALUES = {
    oldPassword: '',
    newPassword: '',
    newPasswordRepeat: '',
};
const ChangePasswordForm = ({ changePasswordAction }) => {
    const { i18n, language } = useLanguage();
    const form = useHookForm({
        defaultValues: INITIAL_VALUES,
        resolver: values => {
            const errors = {};
            if (validateMinLength(values.newPassword, 4)) {
                errors.newPassword = errorMsg(i18n.t('VALIDATE_LENGTH_MIN').replace('#min#', '4'));
            }
            if (validateRequired(values.newPassword)) {
                errors.newPassword = errorMsg(i18n.t('VALIDATE_REQUIRED'));
            }
            if (values.newPassword && values.newPasswordRepeat
                && values.newPassword !== values.newPasswordRepeat) {
                errors.newPasswordRepeat = errorMsg(i18n.t('VALIDATE_PASSWORD_MATCH'));
            }
            if (validateRequired(values.newPasswordRepeat)) {
                errors.newPasswordRepeat = errorMsg(i18n.t('VALIDATE_REQUIRED'));
            }
            return {
                values,
                errors,
            };
        },
    });
    const handleSubmit = useCallback(async (values) => {
        const success = await changePasswordAction(values);
        if (success) {
            return form.reset();
        }
        return form.setError('oldPassword', errorMsg(i18n.t('PERSONAL_CHANGE_PASSWORD_OLD_PASSWORD_ERROR')));
    }, [language]);
    const onSubmit = form.handleSubmit(handleSubmit);
    return (React.createElement(FormProvider, Object.assign({}, form),
        React.createElement("form", { className: style.wrapper, onSubmit: onSubmit },
            React.createElement("div", { className: style.title }, i18n.t('PERSONAL_CHANGE_PASSWORD_TITLE')),
            React.createElement(FieldText, { type: "password", name: "oldPassword", autoComplete: "off", placeholder: i18n.t('PERSONAL_CHANGE_PASSWORD_OLD_PASSWORD') }),
            React.createElement(FieldText, { type: "password", name: "newPassword", autoComplete: "off", placeholder: i18n.t('PERSONAL_CHANGE_PASSWORD_NEW_PASSWORD') }),
            React.createElement(FieldText, { type: "password", name: "newPasswordRepeat", autoComplete: "off", placeholder: i18n.t('PERSONAL_CHANGE_PASSWORD_NEW_PASSWORD_REPEAT') }),
            React.createElement(Button, { theme: "solid-gradient-gray", onClick: onSubmit, className: style.btn, disabled: form.formState.isSubmitting },
                React.createElement(Icon, { name: "diskette", width: "12", height: "12", fill: colors.white }),
                i18n.t('PERSONAL_CHANGE_PASSWORD_SUBMIT')))));
};
export default memo(ChangePasswordForm);
