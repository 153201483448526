import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { AccountsCatalogTemplate } from 'ui/templates';
import { useStore } from 'hooks';
import { observer } from 'mobx-react-lite';
const AccountsCatalog = () => {
    const { accountsStore, commonStore } = useStore();
    const accounts = accountsStore.allI18nList;
    const pagination = accountsStore.pagination;
    const isLoading = accountsStore.pending;
    const { homeTitle, homeDescription } = commonStore.settingsList;
    const fetchAccountsListAction = accountsStore.fetchAccountsList;
    const loadMoreAction = useCallback(() => fetchAccountsListAction(true), []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            homeTitle && React.createElement("title", null, homeTitle),
            homeDescription && React.createElement("meta", { name: "description", content: homeDescription })),
        React.createElement(AccountsCatalogTemplate, { accounts: accounts, isLoading: isLoading, loadMoreAction: loadMoreAction, isShowLoadMore: !!pagination.nextPage })));
};
export const preload = ({ store }) => store.accountsStore.fetchAccountsList();
export default observer(AccountsCatalog);
