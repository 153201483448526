import React from 'react';
import { ScrollArea } from 'libs/Scrollbar';
import { TicketMessagerForm } from 'forms/Tickets';
import { Messages } from '../Messages';
import style from './style.module.scss';
export const DialogHistory = ({ ticketId, authorId, messages, ownerId, techsupportId, messagerStatus, needHelpActionVisible, needOwnerHelpAction, blockUserByTicketAction, }) => (React.createElement("div", { className: style.background },
    React.createElement("div", { className: style.wrapper },
        React.createElement(ScrollArea, { containerHeight: 338, containerClassName: style.scrollbar, direction: "reverse" },
            React.createElement(Messages, { ticketId: ticketId, authorId: authorId, ownerId: ownerId, techsupportId: techsupportId, messages: messages })),
        React.createElement(TicketMessagerForm, { ticketId: ticketId, messagerStatus: messagerStatus, needHelpActionVisible: needHelpActionVisible, needOwnerHelpAction: needOwnerHelpAction, blockUserByTicketAction: blockUserByTicketAction }))));
