import { isNumber } from './selectors';
export const getMin = (...args) => Math
    .min(...args.map(value => value).filter(isNumber));
export const findIndexFromIndex = (fromIndex, list, cb, defaultValue) => {
    for (let index = fromIndex; index < list.length; index++) {
        if (cb(list[index])) {
            return index;
        }
    }
    return (defaultValue || null);
};
export const findIndexFromIndexRevert = (fromIndex, list, cb, defaultValue) => {
    for (let index = fromIndex; index >= 0; index--) {
        if (cb(list[index])) {
            return index;
        }
    }
    return defaultValue;
};
