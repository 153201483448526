import React, { useCallback, useMemo, useState, } from 'react';
import { useLocation, NavLink, Link } from 'react-router-dom';
import SimpleSearchForm from 'forms/SimpleSearchForm';
import { getUrlParam, removeUrlLang } from 'utils/selectors';
import { AuthModal } from 'ui/organisms';
import { navbarLinks } from 'ui/_nav';
import { useLanguage, useRole, useStore, useUpdate, } from 'hooks';
import { colors } from 'ui/theme';
import { LanguageSwitcher, Container, Icon, Logo, } from 'ui/atoms';
import { observer } from 'mobx-react-lite';
import style from './style.module.scss';
const MobileHeader = () => {
    const { commonStore, ticketsStore } = useStore();
    const { user, isAuth } = useRole();
    const { i18n, makeUrl } = useLanguage();
    const { search, pathname } = useLocation();
    const [isOtherOpened, setOtherOpened] = useState(null);
    const searchParam = useMemo(() => getUrlParam(search, 'search'), [search]);
    const [isSearchVisible, setSearchVisible] = useState(!!searchParam);
    const toggleAuthModalAction = commonStore.toggleAuthModal;
    const toggleMenuAction = commonStore.toggleMobileMenu;
    const notifications = ticketsStore.countNotification;
    const isAuthVisible = commonStore.authModalVisible;
    const isMenuVisible = commonStore.mobileMenuVisible;
    const isHomePage = useMemo(() => removeUrlLang(pathname) === '/', [pathname]);
    const toggleOtherOpened = useCallback(() => setOtherOpened(old => !old), []);
    const toggleSearchVisibility = useCallback(() => setSearchVisible(old => !old), []);
    const toggleMenuVisibility = useCallback(() => {
        toggleMenuAction();
        toggleAuthModalAction(false);
    }, []);
    const handleSublink = useCallback((event) => {
        event.stopPropagation();
        document.body.classList.remove('disable-scroll');
        toggleMenuAction(false);
    }, []);
    useUpdate(() => {
        toggleMenuAction(false);
        toggleAuthModalAction(false);
    }, [pathname]);
    return (React.createElement(React.Fragment, null,
        (isSearchVisible && !isHomePage) && (React.createElement("span", { className: style.spacer })),
        React.createElement("div", { className: style.background, "data-gradient": !isMenuVisible },
            React.createElement(Container, { className: style.container },
                React.createElement("div", { className: style.logo },
                    React.createElement(Logo, { text: false })),
                isAuth && (React.createElement(Link, { to: makeUrl('/profile'), className: style.login },
                    user.login,
                    notifications !== 0 && (React.createElement("div", { className: style.notification }, notifications)))),
                React.createElement("div", { className: style.search__icon, onClick: toggleSearchVisibility },
                    React.createElement(Icon, { name: "loupe", width: "24", height: "24", fill: colors.gray })),
                React.createElement("div", { className: style.humburger__icon, "data-visible": isMenuVisible, onClick: toggleMenuVisibility },
                    React.createElement(Icon, { name: "humburger", width: "30", height: "21", fill: colors.gray })),
                isSearchVisible && (React.createElement(SimpleSearchForm, null)))),
        isMenuVisible && (React.createElement("div", { className: style.menu__scroll },
            React.createElement("div", { className: style.menu__background, "data-visible": isMenuVisible }, isAuthVisible
                ? (React.createElement(AuthModal, { isVisible: isAuthVisible, toggleVisible: toggleAuthModalAction })) : (React.createElement("div", { className: style.menu__wrapper },
                !isAuth && (React.createElement("div", { className: style['sing-in__button'], onClick: toggleAuthModalAction },
                    React.createElement(Icon, { name: "sign-in", width: "17", height: "17", fill: colors.white }),
                    i18n.t('HEADER_LOGIN_BTN'))),
                navbarLinks.map(({ text, to, subLinks }) => {
                    if (subLinks) {
                        return (React.createElement("div", { key: text, className: style.link, "data-sub-links": "true", onClick: toggleOtherOpened, "data-opened": isOtherOpened },
                            React.createElement("div", null,
                                i18n.t(text),
                                React.createElement(Icon, { name: "sm-arrow-down", width: "12", height: "12", fill: colors.white })),
                            React.createElement("div", { className: style.dropdown, "data-opened": isOtherOpened }, subLinks.map(subLink => (React.createElement(NavLink, { key: subLink.to, to: makeUrl(subLink.to), className: style.sublink, activeClassName: style.sublink__active, onClick: handleSublink }, i18n.t(subLink.text)))))));
                    }
                    return (React.createElement(NavLink, Object.assign({ key: text, exact: true, to: makeUrl(to), className: style.link, activeClassName: style.link__active, onClick: toggleMenuVisibility }, (to === '/faq'
                        ? { 'data-faq': true }
                        : {})), i18n.t(text)));
                }),
                React.createElement(LanguageSwitcher, { onChange: toggleMenuVisibility }))))))));
};
export default observer(MobileHeader);
