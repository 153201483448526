import React from 'react';
import { useFormContext } from 'react-hook-form';
import { Price, Radio } from 'ui/atoms';
import { useMobile } from 'hooks';
import style from './style.module.scss';
export const FieldRadioPrices = ({ name, options, disabled, direction = 'vertical', }) => {
    const { isMobile, isTablet } = useMobile();
    const { watch, setValue } = useFormContext();
    const value = watch(name);
    return (React.createElement("div", { className: style.wrapper, "data-direction": direction, "data-center": options.length === 1 || disabled }, options.length && !disabled
        ? (React.createElement(React.Fragment, null, options.map(price => (React.createElement(Radio, { key: price.id, isActive: price.id === value.id, title: price.title, type: isMobile || isTablet
                ? 'text'
                : 'gradient', onClick: () => setValue(name, price) }))))) : (React.createElement(Price, { className: style['out-of-stock'] }))));
};
