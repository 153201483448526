import React from 'react';
import { PersonalTemplate } from 'ui/templates';
import { useStore } from 'hooks';
import { observer } from 'mobx-react-lite';
const Personal = () => {
    const { authStore } = useStore();
    const changeAvatarAction = authStore.changeAvatar;
    const changePasswordAction = authStore.changePassword;
    const changeEmailAction = authStore.changeEmail;
    const changeLoginAction = authStore.changeLogin;
    const user = authStore.data;
    return (React.createElement(PersonalTemplate, { user: user, changeEmailAction: changeEmailAction, changeLoginAction: changeLoginAction, changeAvatarAction: changeAvatarAction, changePasswordAction: changePasswordAction }));
};
export default observer(Personal);
