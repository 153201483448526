import React, { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useLanguage, useStore, useUpdate } from 'hooks';
import { AIChatForm, Header, Messages } from './components';
import style from './style.module.scss';
const AIChatHelper = () => {
    const { i18n } = useLanguage();
    const { aiChatStore } = useStore();
    const [isLoaded, setIsLoaded] = useState(false);
    const [isOpen, setIsOpen] = useState(false);
    useUpdate(() => setIsLoaded(true), [isOpen]);
    useUpdate(() => {
        if (isLoaded) {
            aiChatStore.fetchAIChat();
        }
    }, [isLoaded]);
    const handleToggle = () => setIsOpen(old => !old);
    if (isOpen && !aiChatStore.pending) {
        return (React.createElement("div", { className: style.chat },
            React.createElement(Header, { onClose: handleToggle }),
            aiChatStore.hasMessages
                ? React.createElement(Messages, null)
                : React.createElement("div", { className: style.placeholder }, i18n.t('AI_CHAT_PLACEHOLDER')),
            React.createElement(AIChatForm, { onSubmit: aiChatStore.sendMessage, disabled: aiChatStore.sending })));
    }
    return (React.createElement("div", { className: style.wrapper, onClick: handleToggle },
        "AI",
        React.createElement("span", null, i18n.t('AI_CHAT_BTN'))));
};
export default observer(AIChatHelper);
