import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLanguage, useStore } from 'hooks';
import { Cross } from './Cross';
import AILogo from './AILogo';
import style from './Header.module.scss';
const Header = ({ onClose }) => {
    const { i18n } = useLanguage();
    const { aiChatStore } = useStore();
    return (React.createElement("header", { className: style.header },
        React.createElement(AILogo, null),
        React.createElement("div", { className: style.info },
            React.createElement("div", { className: style.name }, "EliteHacks Ai"),
            React.createElement("div", { className: style.status }, i18n.t(aiChatStore.error
                ? 'AI_CHAT_STATUS_3'
                : (aiChatStore.sending
                    ? 'AI_CHAT_STATUS_2'
                    : 'AI_CHAT_STATUS_1')))),
        React.createElement(Cross, { onClick: onClose })));
};
export default observer(Header);
