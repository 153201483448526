import { CheatsStore } from 'ducks/cheats';
import { action, makeObservable, computed, observable, } from 'mobx';
import { catchError } from 'utils/catchError';
import http, { createAbortController, createSearch, isAbortError } from 'utils/http';
import { getUrlParam } from 'utils/selectors';
export class GamesStore {
    constructor(store) {
        Object.defineProperty(this, "store", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "data", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "allList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "pagination", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                nextPage: null,
            }
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "searchPending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "searchList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "searchPagination", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                nextPage: null,
            }
        });
        Object.defineProperty(this, "fetchPending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => this.pending = true
        });
        Object.defineProperty(this, "fetchFailed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (error) => {
                this.pending = false;
                this.searchPending = false;
                this.error = error || null;
                this.pagination = {
                    nextPage: null,
                };
            }
        });
        Object.defineProperty(this, "fetchSuccessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (game) => {
                this.pending = false;
                this.data = game;
            }
        });
        Object.defineProperty(this, "fetchAllSuccessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ list, nextPage }) => {
                this.pending = false;
                this.allList = list;
                this.pagination = {
                    nextPage,
                };
            }
        });
        Object.defineProperty(this, "fetchSearchSuccessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ list, nextPage }) => {
                this.searchPending = false;
                this.searchList = list;
                this.searchPagination = {
                    nextPage,
                };
            }
        });
        Object.defineProperty(this, "fetchSearchPending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => this.searchPending = true
        });
        Object.defineProperty(this, "fetchGame", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (slug) => {
                this.fetchPending();
                try {
                    const { game } = await http.get(`/games/slug/${slug}`);
                    this.fetchSuccessed(game);
                }
                catch (e) {
                    if (e.status === 404) {
                        this.store.setStatus(404);
                    }
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                }
            }
        });
        Object.defineProperty(this, "fetchGamesList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (isLoadMore = false) => {
                const { allList } = this;
                const { nextPage } = this.pagination;
                if (allList.length && (!isLoadMore || nextPage === null))
                    return;
                this.fetchPending();
                try {
                    const search = createSearch({
                        page: nextPage && isLoadMore ? nextPage : 1,
                    });
                    const res = await http.get(`/games/home${search}`);
                    this.fetchAllSuccessed({
                        list: isLoadMore
                            ? [...allList, ...res.list]
                            : res.list,
                        nextPage: res.nextPage,
                    });
                }
                catch (e) {
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                }
            }
        });
        Object.defineProperty(this, "abortSearch", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: createAbortController()
        });
        Object.defineProperty(this, "searchGamesList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (isLoadMore = false) => {
                this.abortSearch.initAbort();
                this.fetchSearchPending();
                const search = getUrlParam(this.store.routingStore.location.search, 'search');
                const { searchList } = this;
                const { nextPage } = this.searchPagination;
                try {
                    const searchQuery = createSearch({
                        page: nextPage && isLoadMore ? nextPage : 1,
                        search,
                    });
                    const res = await http.get(`/games/home${searchQuery}`, this.abortSearch.getSignal());
                    this.fetchSearchSuccessed({
                        list: isLoadMore
                            ? [...searchList, ...res.list]
                            : res.list,
                        nextPage: res.nextPage,
                    });
                }
                catch (e) {
                    if (isAbortError(e))
                        return;
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                }
            }
        });
        this.store = store;
        makeObservable(this, {
            pending: observable,
            data: observable,
            allList: observable,
            pagination: observable,
            error: observable,
            searchPending: observable,
            searchList: observable,
            searchPagination: observable,
            allI18nList: computed,
            searchI18nList: computed,
            dataI18n: computed,
            fetchPending: action.bound,
            fetchFailed: action.bound,
            fetchSuccessed: action.bound,
            fetchAllSuccessed: action.bound,
            fetchSearchSuccessed: action.bound,
            fetchSearchPending: action.bound,
            fetchGame: action,
            fetchGamesList: action,
            searchGamesList: action,
        });
    }
    get allI18nList() {
        return this.allList.map(game => (Object.assign(Object.assign({}, game), { minPrice: game.minPrice[this.store.commonStore.language], localization: undefined, cheats: undefined })))
            .sort(GamesStore.sortGameByMinPrice);
    }
    get searchI18nList() {
        return this.searchList.map(game => (Object.assign(Object.assign({}, game), { minPrice: game.minPrice[this.store.commonStore.language], localization: undefined, cheats: undefined })))
            .sort(GamesStore.sortGameByMinPrice);
    }
    get dataI18n() {
        if (!this.data)
            return this.data;
        const lang = this.store.commonStore.language;
        return Object.assign(Object.assign({}, this.data), { localization: this.data.localization[lang], cheats: this.data.cheats
                .map(CheatsStore.makeCheatI18n(lang))
                .sort(CheatsStore.sortCheats) });
    }
}
Object.defineProperty(GamesStore, "sortGameByMinPrice", {
    enumerable: true,
    configurable: true,
    writable: true,
    value: (prev, next) => {
        if (!prev.minPrice) {
            return 1;
        }
        if (!next.minPrice) {
            return -1;
        }
        return 0;
    }
});
