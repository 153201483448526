import React from 'react';
import { colors } from 'ui/theme';
import style from './style.module.scss';
export const GradientBorder = ({ idPrefix, active, onClick }) => {
    const id = `preview-border-gradeint${idPrefix}`;
    return (React.createElement("div", { className: style.wrapper, "data-visible": active },
        React.createElement("svg", { viewBox: "0 0 48 48", fill: "none", xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none", className: style.svg, onClick: onClick },
            React.createElement("rect", { x: "0.5", y: "0.5", width: "47", height: "47", rx: "9.5", stroke: `url(#${id})` }),
            React.createElement("defs", null,
                React.createElement("linearGradient", { id: id, x1: "24", y1: "28.8", x2: "42.3869", y2: "-11.0088", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: colors.orange }),
                    React.createElement("stop", { offset: "1", stopColor: colors.orangeOpacity0 }))))));
};
