import React, { useCallback, useRef } from 'react';
import { observer } from 'mobx-react-lite';
import { Button, Icon } from 'ui/atoms';
import { colors } from 'ui/theme';
import { useStore } from 'hooks';
import style from './style.module.scss';
const ModalPopup = () => {
    const wrapperRef = useRef(null);
    const { modalStore } = useStore();
    const confirmHideAction = modalStore.confirmHide;
    const rejectHideAction = modalStore.rejectHide;
    const meta = modalStore.meta;
    const isShow = modalStore.show;
    const onModalClose = useCallback(event => wrapperRef.current
        && !wrapperRef.current.contains(event.target)
        && rejectHideAction(), []);
    if (!isShow)
        return null;
    return (React.createElement("span", { onClick: onModalClose, className: style.dimmer },
        React.createElement("div", { ref: wrapperRef, className: style.wrapper },
            React.createElement("div", { className: style.header },
                React.createElement("div", null, meta.title),
                React.createElement(Icon, { name: "healing", width: "14", height: "14", fill: colors.white })),
            React.createElement("div", { className: style.description }, meta.description),
            React.createElement("div", { className: style['btn-group'] },
                React.createElement(Button, { theme: "solid-gradient", className: style.btn, onClick: confirmHideAction }, meta.buttonSubmit),
                React.createElement(Button, { theme: "outline", className: style.btn, onClick: rejectHideAction }, meta.buttonCancel)))));
};
export default observer(ModalPopup);
