var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import style from './style.module.scss';
export const Image = (_a) => {
    var { webp, jpeg, alt } = _a, rest = __rest(_a, ["webp", "jpeg", "alt"]);
    return (React.createElement("picture", { className: style.picture },
        React.createElement("source", { srcSet: webp, type: "image/webp" }),
        React.createElement("source", { srcSet: jpeg, type: "image/jpeg" }),
        React.createElement("img", Object.assign({ "data-name": "image", src: jpeg, alt: alt, draggable: "false", loading: "lazy" }, rest))));
};
