var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { NavLink } from 'react-router-dom';
import cn from 'classnames';
import { isString } from 'utils/selectors';
import style from './style.module.scss';
export const TabLink = (_a) => {
    var { className, to, children } = _a, rest = __rest(_a, ["className", "to", "children"]);
    if (isString(to) && /^http/i.test(to)) {
        return (React.createElement("a", Object.assign({ href: to, target: "_blank", rel: "noreferrer", className: cn(style.wrapper, className) }, rest), children));
    }
    return (React.createElement(NavLink, Object.assign({ to: to, activeClassName: style.active, className: cn(style.wrapper, className) }, rest), children));
};
