import React, { memo } from 'react';
import { ImageGradientIcon, TelegramGradientIcon } from 'ui/atoms';
import { useMobile } from 'hooks';
import style from '../style.module.scss';
const SendButtonComponent = ({ id, onClick, onFilesChange, disabled }) => {
    const { isMobile } = useMobile();
    const fileId = `file-${id}`;
    return (React.createElement("div", { className: style.btn__wrapper },
        React.createElement("input", { hidden: true, id: fileId, type: "file", onChange: onFilesChange, multiple: true, accept: "image/png, image/jpeg, image/jpg", disabled: disabled }),
        React.createElement("label", { htmlFor: fileId, className: style.btn__image },
            React.createElement(ImageGradientIcon, { width: isMobile ? '10' : '20', height: isMobile ? '10' : '20', idPrefix: id, disabled: disabled })),
        React.createElement("button", { type: "submit", onClick: onClick, className: style.btn__send, disabled: disabled },
            React.createElement(TelegramGradientIcon, { width: isMobile ? '11' : '25', height: isMobile ? '10' : '24', idPrefix: id, disabled: disabled }))));
};
export const SendButton = memo(SendButtonComponent);
