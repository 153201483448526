import React from 'react';
import cn from 'classnames';
import { useLanguage } from 'hooks';
import { colors } from 'ui/theme';
import { Icon } from '../Icon';
import style from './style.module.scss';
export const getCurrencyIconName = (currency) => {
    switch (currency) {
        case 'usd':
            return 'usd-currency';
        case 'rub':
        default:
            return 'rub-currency';
    }
};
export const Price = ({ from, type = 'price', amount, currency, className, }) => {
    const { i18n } = useLanguage();
    const isPrice = type === 'price';
    const size = currency === 'usd'
        ? '16'
        : '14';
    const classes = cn(style.base, isPrice
        ? style.price
        : style.discount, className);
    return (React.createElement("span", { className: classes },
        amount && currency
            ? (from
                ? i18n.t('PRICE_FROM').replace('#price#', `${amount}`)
                : amount)
            : i18n.t('PRICE_OUT_OF_STOCK'),
        currency && (React.createElement(Icon, { name: getCurrencyIconName(currency), fill: isPrice
                ? colors.primary
                : colors.backgroundGrayLight, height: size, width: size }))));
};
