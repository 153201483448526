import React from 'react';
import cn from 'classnames';
import { Icon } from 'ui/atoms';
import { colors } from 'ui/theme';
import { useLanguage } from 'hooks';
import { SOCIAL_ICONS } from 'utils/constants';
import style from './style.module.scss';
export const PersonalSocials = props => {
    const { i18n } = useLanguage();
    return (React.createElement("div", { className: style.wrapper },
        React.createElement("div", { className: style.wrapper__title }, i18n.t('PERSONAL_SOCIALS_TITLE')),
        React.createElement("div", { className: style.social__wrapper }, SOCIAL_ICONS.map(({ icon, link }) => {
            const isLinked = props[icon];
            return (React.createElement("a", { key: icon, href: link, onClick: event => isLinked && event.preventDefault(), className: cn(style.social, {
                    [style['social-active']]: isLinked,
                }) },
                React.createElement(Icon, { name: icon, width: "28", height: "28", fill: isLinked
                        ? colors.backgroundGrayLight
                        : colors.backgroundGrayDisabled })));
        }))));
};
