import React from 'react';
import { AboutUsTemplate } from 'ui/templates';
import { useMobile, useStore } from 'hooks';
const AboutUs = ({ match }) => {
    const { commonStore } = useStore();
    const { isAboveTablet } = useMobile();
    const redirectToTechSupportAction = commonStore.redirectToTechSupport;
    const mobileRedirectToTechSupportAction = commonStore.mobileRedirectToTechSupport;
    return (React.createElement(AboutUsTemplate, { type: match.params.type, redirectToTechSupportAction: isAboveTablet
            ? redirectToTechSupportAction
            : mobileRedirectToTechSupportAction }));
};
export default AboutUs;
