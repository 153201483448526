import React from 'react';
import { PendingSwitch, renderRoutes, } from 'libs/router';
import { Header, AIChatHelper, MobileHeader, } from 'ui/organisms';
import { preventScroll } from 'ui/_nav';
import { useMobile } from 'hooks';
import 'nprogress/nprogress.css';
const AppTemplate = ({ routes }) => {
    const { isMobile, isTablet } = useMobile();
    return (React.createElement(React.Fragment, null,
        isTablet || isMobile
            ? React.createElement(MobileHeader, null)
            : React.createElement(Header, null),
        React.createElement(PendingSwitch, { routes: routes, preventScroll: preventScroll }, renderRoutes(routes)),
        React.createElement(AIChatHelper, null)));
};
export default AppTemplate;
