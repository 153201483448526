import { useContext } from 'react';
import { StoreContext } from 'providers';
export const useStore = () => {
    const store = useContext(StoreContext);
    return {
        store,
        authStore: store.authStore,
        gamesStore: store.gamesStore,
        bestGamesStore: store.bestGamesStore,
        cheatsStore: store.cheatsStore,
        commonStore: store.commonStore,
        modalStore: store.modalStore,
        routingStore: store.routingStore,
        ticketsStore: store.ticketsStore,
        accountsStore: store.accountsStore,
        problemsStore: store.problemsStore,
        aiChatStore: store.aiChatStore,
    };
};
