import i18next from 'i18next';
export const defaultLng = 'en';
export const languageList = ['ru', 'en'];
export const initI18N = (lang = defaultLng) => {
    const i18n = i18next.createInstance();
    i18n.init({
        lng: lang,
        resources: {
            ru: require('./dictionaries/ru.json'),
            en: require('./dictionaries/en.json'),
        },
        fallbackLng: defaultLng,
    });
    return i18n;
};
