import React, { useEffect } from 'react';
import { useSocket, useStore } from 'hooks';
import { TechSupportTemplate } from 'ui/templates';
import { observer } from 'mobx-react-lite';
const TechSupportTabs = ({ routes }) => {
    const { socket } = useSocket();
    const { ticketsStore } = useStore();
    const subsribeSendMessagesAction = ticketsStore.subsribeSendMessages;
    const notifications = ticketsStore.notifications;
    useEffect(() => {
        if (socket) {
            socket.on('send-ticket-messages', subsribeSendMessagesAction);
            return () => {
                socket.off('send-ticket-messages');
            };
        }
    }, [socket]);
    return (React.createElement(TechSupportTemplate, { routes: routes, notifications: notifications }));
};
export default observer(TechSupportTabs);
