import React from 'react';
import { observer } from 'mobx-react-lite';
import { useLanguage, useMobile } from 'hooks';
import { ProductHeader } from 'ui/molecules';
import { AccountsCatalog } from 'ui/organisms';
import { CrosshairsDecore } from 'ui/atoms';
const AccountsCatalogTemplate = observer(({ accounts, isLoading, isShowLoadMore, loadMoreAction, }) => {
    const { isMobile, isAboveTablet } = useMobile();
    const { i18n } = useLanguage();
    return (React.createElement(React.Fragment, null,
        !isMobile && (React.createElement(ProductHeader, { title: i18n.t('ACCOUNTS_CATALOG_TITLE'), webp: "/public/images/backgrounds/RadialOrangeGradient.webp", jpeg: "/public/images/backgrounds/RadialOrangeGradient.jpg", alt: "RadialOrangeGradient" }, isAboveTablet && (React.createElement(CrosshairsDecore, null)))),
        React.createElement(AccountsCatalog, { accounts: accounts, isLoading: isLoading, isShowLoadMore: isShowLoadMore, loadMoreAction: loadMoreAction })));
});
export default AccountsCatalogTemplate;
