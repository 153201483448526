import React, { useMemo, useCallback, } from 'react';
import { observer } from 'mobx-react-lite';
import { FormProvider } from 'react-hook-form';
import { FieldsMessager, FieldSelect, } from 'ui/molecules';
import { ticketSubject } from 'ui/theme';
import { useHookForm, useLanguage, useStore, } from 'hooks';
import { errorMsg, validateMaxLength, validateMinLength, validateRequired, } from 'utils/validate';
import style from './style.module.scss';
const INITIAL_VALUES = {
    subject: null,
    message: '',
};
export const ID = 'create-ticket-form';
const CreateTicketForm = ({ createTicketAction }) => {
    const { ticketsStore } = useStore();
    const { i18n, language } = useLanguage();
    const form = useHookForm({
        defaultValues: INITIAL_VALUES,
        resolver: values => {
            const errors = {};
            if (validateRequired(values.subject)) {
                errors.subject = errorMsg(i18n.t('TICKET_SUBJECT_ERROR'));
            }
            if (validateMinLength(values.message, 10) || validateMaxLength(values.message, 500)) {
                errors.message = errorMsg(i18n.t('VALIDATE_LENGTH_MIN_MAX')
                    .replace('#min#', '10')
                    .replace('#max#', '500'));
            }
            if (validateRequired(values.message)) {
                errors.message = errorMsg(i18n.t('VALIDATE_REQUIRED'));
            }
            return {
                values,
                errors,
            };
        },
    });
    const files = ticketsStore.filesById(ID);
    const isUploadingInProgress = ticketsStore.isUploadingById(ID);
    const subjectOptions = useMemo(() => ticketSubject.map(({ label, value }) => ({
        label: i18n.t(label),
        value,
    })), [language]);
    const handleSubmit = useCallback(async (values) => {
        if (ticketsStore.isUploadingById(ID))
            return;
        const success = await createTicketAction(values);
        if (success) {
            form.reset();
            ticketsStore.resetFiles(ID);
            return;
        }
        form.setError('subject', errorMsg(i18n.t('TICKET_LIMIT_EXCEEDED_ERROR')));
    }, [language]);
    const onSubmit = form.handleSubmit(handleSubmit);
    return (React.createElement(FormProvider, Object.assign({}, form),
        React.createElement("form", { onSubmit: onSubmit, encType: "multipart/form-data" },
            React.createElement("div", { className: style.wrapper },
                React.createElement("div", { className: style.hint }, i18n.t('TICKET_CREATE_HINT')),
                React.createElement(FieldSelect, { name: "subject", placeholder: i18n.t('TICKET_SUBJECT_PLACEHOLDER'), options: subjectOptions }),
                React.createElement(FieldsMessager, { files: files, id: ID, textareaName: "message", filesName: "files", placeholder: i18n.t('TICKET_CREATE_SUBMIT'), onClick: onSubmit, textareaDisabled: false, controlsDisabled: isUploadingInProgress || form.formState.isSubmitting })))));
};
export default observer(CreateTicketForm);
