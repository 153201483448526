import { makeObservable, observable, action } from 'mobx';
export class ModalStore {
    constructor(store) {
        Object.defineProperty(this, "store", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "show", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "meta", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                type: 'primary',
                title: '',
                buttonCancel: '',
                buttonSubmit: '',
                description: '',
            }
        });
        Object.defineProperty(this, "onConfirm", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "onReject", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "showModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (modal) => {
                this.show = true;
                this.meta = modal.meta;
                this.onConfirm = modal.onConfirm;
                this.onReject = modal.onReject;
            }
        });
        Object.defineProperty(this, "hideModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (result) => {
                if (result && this.onConfirm) {
                    this.onConfirm();
                }
                else if (this.onReject) {
                    this.onReject();
                }
                this.show = false;
            }
        });
        Object.defineProperty(this, "confirmHide", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.onConfirm) === null || _a === void 0 ? void 0 : _a.call(this);
                this.hideModal();
            }
        });
        Object.defineProperty(this, "rejectHide", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => {
                var _a;
                (_a = this.onReject) === null || _a === void 0 ? void 0 : _a.call(this);
                this.hideModal(false);
            }
        });
        Object.defineProperty(this, "showAsyncModal", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (data) => new Promise((resolve, reject) => this.showModal({
                meta: data,
                onConfirm: resolve,
                onReject: () => reject(false),
            }))
        });
        this.store = store;
        makeObservable(this, {
            show: observable,
            meta: observable,
            onReject: observable,
            onConfirm: observable,
            showModal: action.bound,
            hideModal: action.bound,
            confirmHide: action.bound,
            rejectHide: action.bound,
            showAsyncModal: action.bound,
        });
    }
}
