import { makeObservable, observable, computed, action, } from 'mobx';
import { isUUID, getUrlParam, removeUrlLang, positiveOrZero, } from 'utils/selectors';
import { ID as CreateTicketId, } from 'forms/Tickets/CreateTicketForm';
import { playNotification } from 'ui/theme';
import { register } from 'registerServiceWorker';
import { noop, computedFn } from 'utils/functions';
import { catchError } from 'utils/catchError';
import http, { createSearch } from 'utils/http';
import { WS } from 'providers';
import { MessageType } from 'utils/constants';
export class TicketsStore {
    constructor(store) {
        Object.defineProperty(this, "store", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "allList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "groupedMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "notifications", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                techSupport: 0,
                techSupportMy: 0,
                tickets: 0,
                ownerNeedHelp: 0,
                ownerMy: 0,
            }
        });
        Object.defineProperty(this, "groupedMessageFiles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "fileUploading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "groupedImages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {}
        });
        Object.defineProperty(this, "findById", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: computedFn((id) => this.allList.find(ticket => ticket.id === id))
        });
        Object.defineProperty(this, "filesById", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: computedFn((ticketId) => { var _a; return (_a = this.groupedMessageFiles[ticketId]) !== null && _a !== void 0 ? _a : []; })
        });
        Object.defineProperty(this, "fileIdsById", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: computedFn((ticketId) => {
                var _a;
                return ((_a = this.groupedMessageFiles[ticketId]) !== null && _a !== void 0 ? _a : [])
                    .map(({ remote }) => remote.id);
            })
        });
        Object.defineProperty(this, "isUploadingById", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: computedFn((ticketId) => { var _a; return (_a = this.fileUploading[ticketId]) !== null && _a !== void 0 ? _a : false; })
        });
        Object.defineProperty(this, "imagesById", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: computedFn((ticketId) => { var _a; return (_a = this.groupedImages[ticketId]) !== null && _a !== void 0 ? _a : []; })
        });
        Object.defineProperty(this, "fetchAllSuccessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (list) => this.allList = list
        });
        Object.defineProperty(this, "fetchMessagesSuccessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticketId, messages }) => this.groupedMessages = Object.assign(Object.assign({}, this.groupedMessages), { [ticketId]: messages })
        });
        Object.defineProperty(this, "addNewTicket", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (ticket) => this.allList = [ticket, ...this.allList]
        });
        Object.defineProperty(this, "markAsRead", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticketId, type }) => {
                const unread = this.allList.find(ticket => ticketId === ticket.id).unread;
                this.allList = this.allList.map(ticket => ticketId === ticket.id
                    ? (Object.assign(Object.assign({}, ticket), { unread: 0 }))
                    : ticket);
                this.notifications = Object.assign(Object.assign({}, this.notifications), { tickets: type === 'client'
                        ? positiveOrZero(this.notifications.tickets - unread)
                        : this.notifications.tickets, techSupportMy: type === 'techsupport'
                        ? positiveOrZero(this.notifications.techSupportMy - unread)
                        : this.notifications.techSupportMy, ownerMy: type === 'owner'
                        ? positiveOrZero(this.notifications.ownerMy - unread)
                        : this.notifications.ownerMy });
            }
        });
        Object.defineProperty(this, "incrementUnread", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticketId, type }) => {
                this.allList = this.allList.map(ticket => ticketId === ticket.id
                    ? (Object.assign(Object.assign({}, ticket), { unread: ticket.unread + 1, updatedAt: new Date().toISOString() }))
                    : ticket);
                this.notifications = Object.assign(Object.assign({}, this.notifications), { tickets: type === 'client'
                        ? this.notifications.tickets + 1
                        : this.notifications.tickets, techSupportMy: type === 'techsupport'
                        ? this.notifications.techSupportMy + 1
                        : this.notifications.techSupportMy, ownerMy: type === 'owner'
                        ? this.notifications.ownerMy + 1
                        : this.notifications.ownerMy });
            }
        });
        Object.defineProperty(this, "removeTicket", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (ticket) => {
                const isset = this.allList.some(({ id }) => id === ticket.id);
                if (isset) {
                    const { search, pathname } = this.store.routingStore.location;
                    const ticketSearch = getUrlParam(search, 'ticket') || '';
                    const activeTickets = ticketSearch.split(',');
                    if (activeTickets.includes(ticket.id)) {
                        const newSearch = createSearch({
                            ticket: activeTickets.filter(id => id !== ticket.id).join(','),
                        });
                        this.store.routingStore.replace(`${pathname}${newSearch}`);
                    }
                    this.allList = this.allList.filter(({ id }) => id !== ticket.id);
                }
            }
        });
        Object.defineProperty(this, "setNotifications", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (notifications) => this.notifications = notifications
        });
        Object.defineProperty(this, "particialUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ id, merge }) => this.allList = this.allList.map(ticket => ticket.id === id
                ? Object.assign(Object.assign({}, ticket), merge) : ticket)
        });
        Object.defineProperty(this, "updateLastSender", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticketId, lastSenderId }) => this.allList = this.allList.map(ticket => ticket.id === ticketId
                ? Object.assign(Object.assign({}, ticket), { lastSenderId: lastSenderId, updatedAt: new Date().toISOString() }) : ticket)
        });
        Object.defineProperty(this, "updateNeedHelp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (ticketId) => this.allList = this.allList.map(ticket => ticket.id === ticketId
                ? Object.assign(Object.assign({}, ticket), { needOwnerHelp: true }) : ticket)
        });
        Object.defineProperty(this, "setFiles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticketId, files }) => this.groupedMessageFiles = Object.assign(Object.assign({}, this.groupedMessageFiles), { [ticketId]: (files !== null && files !== void 0 ? files : []).map(file => ({
                    remote: file,
                })) })
        });
        Object.defineProperty(this, "appendFiles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (ticketId, files) => this.groupedMessageFiles = Object.assign(Object.assign({}, this.groupedMessageFiles), { [ticketId]: [...(this.groupedMessageFiles[ticketId] || []), ...files] })
        });
        Object.defineProperty(this, "removeFileSync", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (ticketId, id) => this.groupedMessageFiles = Object.assign(Object.assign({}, this.groupedMessageFiles), { [ticketId]: (this.groupedMessageFiles[ticketId] || []).filter(({ remote }) => remote.id !== id) })
        });
        Object.defineProperty(this, "replaceFile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticketId, localId, remote }) => this.groupedMessageFiles = Object.assign(Object.assign({}, this.groupedMessageFiles), { [ticketId]: (this.groupedMessageFiles[ticketId] || []).map(file => {
                    var _a;
                    if (((_a = file.local) === null || _a === void 0 ? void 0 : _a.id) === localId) {
                        return {
                            remote,
                            local: file.local,
                        };
                    }
                    return file;
                }) })
        });
        Object.defineProperty(this, "setFileProgress", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticketId, localId, progress }) => (this.groupedMessageFiles[ticketId] || []).map(file => {
                var _a;
                if (((_a = file.local) === null || _a === void 0 ? void 0 : _a.id) === localId) {
                    file.local.progress = progress;
                    return file;
                }
                return file;
            })
        });
        Object.defineProperty(this, "setToUploading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (ticketId) => this.fileUploading = Object.assign(Object.assign({}, this.fileUploading), { [ticketId]: true })
        });
        Object.defineProperty(this, "removeFromUploading", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (ticketId) => {
                const newUploading = Object.assign({}, this.fileUploading);
                delete newUploading[ticketId];
                this.fileUploading = newUploading;
            }
        });
        Object.defineProperty(this, "resetFiles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (ticketId) => {
                this.removeFromUploading(ticketId);
                const messageFiles = Object.assign({}, this.groupedMessageFiles);
                delete messageFiles[ticketId];
                this.groupedMessageFiles = messageFiles;
            }
        });
        Object.defineProperty(this, "setImages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticketId, images }) => this.groupedImages = Object.assign(Object.assign({}, this.groupedImages), { [ticketId]: images })
        });
        Object.defineProperty(this, "appendImages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticketId, images }) => this.groupedImages = Object.assign(Object.assign({}, this.groupedImages), { [ticketId]: [...this.groupedImages[ticketId], ...images] })
        });
        Object.defineProperty(this, "subsribeSendTicketList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ tickets, files }) => {
                this.fetchAllSuccessed(tickets);
                this.setFiles({
                    files,
                    ticketId: CreateTicketId,
                });
            }
        });
        Object.defineProperty(this, "subsribeSendMessages", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticketId, files, images, messages, type, }) => {
                const user = this.store.authStore.data;
                if (messages[0].author.id !== user.id) {
                    this.markAsRead({
                        ticketId,
                        type,
                    });
                }
                this.fetchMessagesSuccessed({
                    ticketId,
                    messages,
                });
                this.setFiles({
                    files,
                    ticketId,
                });
                this.setImages({
                    ticketId,
                    images,
                });
            }
        });
        Object.defineProperty(this, "subsribeReceiveMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ type, ticket, message, isQueue }) => {
                var _a;
                const { search, pathname } = this.store.routingStore.location;
                const isTechSupportPage = pathname.includes('/profile/techsupport');
                const isOwnerPage = pathname.includes('/profile/owner');
                if (isTechSupportPage || isOwnerPage || pathname.includes('/profile/tickets')) {
                    const messages = this.groupedMessages[ticket.id];
                    const ticketSearch = getUrlParam(search, 'ticket') || '';
                    const activeTickets = ticketSearch.split(',');
                    this.updateLastSender({
                        ticketId: ticket.id,
                        lastSenderId: ticket.lastSenderId,
                    });
                    if (!activeTickets.includes(ticket.id)) {
                        this.incrementUnread({
                            ticketId: ticket.id,
                            type,
                        });
                        if (!isQueue) {
                            playNotification();
                        }
                    }
                    else {
                        if (!isQueue) {
                            WS.emit('read-ticket-messages', {
                                ticketId: ticket.id,
                            });
                        }
                    }
                    if (messages) {
                        this.fetchMessagesSuccessed({
                            ticketId: ticket.id,
                            messages: [message, ...messages],
                        });
                        if ([MessageType.Files, MessageType.TextWithFiles].includes(message.type)
                            && ((_a = message.files) === null || _a === void 0 ? void 0 : _a.length)) {
                            this.appendImages({
                                ticketId: ticket.id,
                                images: message.files,
                            });
                        }
                    }
                    return;
                }
                if (!isQueue) {
                    playNotification();
                    WS.emit('request-notifications');
                }
            }
        });
        Object.defineProperty(this, "createTicket", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (values) => {
                const files = this.fileIdsById(CreateTicketId);
                const body = Object.assign(Object.assign({}, values), (files.length
                    ? { files }
                    : {}));
                try {
                    register().catch(noop);
                    const { ticket } = await http.post('/tickets/create', body);
                    this.addNewTicket(ticket);
                    return true;
                }
                catch (error) {
                    return false;
                }
            }
        });
        Object.defineProperty(this, "subsribeNewTicket", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticket }) => {
                const user = this.store.authStore.data;
                const { pathname } = this.store.routingStore.location;
                const isTechSupportPage = removeUrlLang(pathname) === '/profile/techsupport';
                if (user.id === ticket.authorId)
                    return;
                if (isTechSupportPage) {
                    this.addNewTicket(ticket);
                }
                WS.emit('request-notifications');
                if (user.role !== 'owner') {
                    playNotification();
                }
            }
        });
        Object.defineProperty(this, "subsribeParticialUpdate", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (payload) => this.particialUpdate(payload)
        });
        Object.defineProperty(this, "subsribeRemoveTicket", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticket }) => {
                const { pathname } = this.store.routingStore.location;
                const user = this.store.authStore.data;
                const isTechSupportPage = removeUrlLang(pathname) === '/profile/techsupport';
                if (ticket.techsupportId !== user.id) {
                    if (isTechSupportPage) {
                        this.removeTicket(ticket);
                    }
                    WS.emit('request-notifications');
                }
            }
        });
        Object.defineProperty(this, "subsribeRemoveOwnerTicket", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticket }) => {
                const { pathname } = this.store.routingStore.location;
                const isOwnerPage = removeUrlLang(pathname) === '/profile/owner/my';
                if (isOwnerPage) {
                    this.removeTicket(ticket);
                }
                WS.emit('request-notifications');
            }
        });
        Object.defineProperty(this, "subsribeRemoveInWorkTicket", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticket }) => {
                this.removeTicket(ticket);
                WS.emit('request-notifications');
            }
        });
        Object.defineProperty(this, "subsribeTicketReaded", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticketId, type }) => {
                const { pathname } = this.store.routingStore.location;
                if (pathname.includes('/profile/tickets')
                    || pathname.includes('/profile/techsupport')
                    || pathname.includes('/profile/owner')) {
                    this.markAsRead({
                        ticketId,
                        type,
                    });
                }
            }
        });
        Object.defineProperty(this, "subsribeRedirectTo", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ to, page, ticket }) => {
                WS.emit('request-notifications');
                this.store.routingStore.push(`/profile/${page}/${to}?ticket=${ticket.id}`);
            }
        });
        Object.defineProperty(this, "subsribeNotifications", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (payload) => this.setNotifications(payload)
        });
        Object.defineProperty(this, "needOwnerHelp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (id, socket) => {
                socket.emit('request-ticket-help', {
                    ticketId: id,
                });
                this.updateNeedHelp(id);
            }
        });
        Object.defineProperty(this, "subsribeNewTicketToHelp", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ ticket }) => {
                const { pathname } = this.store.routingStore.location;
                const isOwnerPage = removeUrlLang(pathname) === '/profile/owner';
                if (isOwnerPage) {
                    this.addNewTicket(ticket);
                }
                WS.emit('request-notifications');
                playNotification();
            }
        });
        Object.defineProperty(this, "blockUserByTicket", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (ticketId, i18n) => {
                const body = {
                    ticketId,
                };
                try {
                    await this.store.modalStore.showAsyncModal({
                        type: 'danger',
                        title: i18n.t('BLOCK_TITLE'),
                        buttonCancel: i18n.t('CANCEL'),
                        buttonSubmit: i18n.t('TECHSUPPORT_BLOCK'),
                        description: i18n.t('BLOCK_DESCRIPTION'),
                    });
                    await http.post('/tickets/block', body);
                }
                catch (e) {
                    const error = catchError(e);
                    console.error(error);
                }
            }
        });
        Object.defineProperty(this, "addFiles", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (ticketId, files) => {
                var _a, _b, _c, _d;
                if (!files.length)
                    return;
                const messsageFiles = (this.groupedMessageFiles[ticketId] || []);
                const lastFile = messsageFiles[messsageFiles.length - 1];
                let lastOrder = lastFile
                    ? ((_d = (_b = (_a = lastFile === null || lastFile === void 0 ? void 0 : lastFile.remote) === null || _a === void 0 ? void 0 : _a.order) !== null && _b !== void 0 ? _b : (_c = lastFile === null || lastFile === void 0 ? void 0 : lastFile.local) === null || _c === void 0 ? void 0 : _c.order) !== null && _d !== void 0 ? _d : 0) + 1
                    : 0;
                const newFiles = files.map(file => {
                    const index = lastOrder;
                    lastOrder++;
                    return {
                        local: {
                            file,
                            id: Math.random().toString(36).substring(2, 15),
                            urlObj: URL.createObjectURL(file),
                            progress: 0,
                            order: index,
                        },
                    };
                });
                this.setToUploading(ticketId);
                this.appendFiles(ticketId, newFiles);
                await Promise.all(newFiles.map(async ({ local }) => {
                    const body = new FormData();
                    if (isUUID(ticketId))
                        body.append('ticketId', ticketId);
                    body.append('order', `${local.order}`);
                    body.append('file', local.file);
                    const { file } = await http.post('/tickets/upload', body, {
                        proggress: progress => this.setFileProgress({
                            localId: local.id,
                            ticketId,
                            progress,
                        }),
                    });
                    this.replaceFile({
                        ticketId,
                        remote: file,
                        localId: local.id,
                    });
                }));
                this.removeFromUploading(ticketId);
            }
        });
        Object.defineProperty(this, "removeFile", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (ticketId, id) => {
                this.removeFileSync(ticketId, id);
                await http.delete(`/tickets/upload/${id}`);
            }
        });
        this.store = store;
        makeObservable(this, {
            allList: observable,
            groupedMessages: observable,
            notifications: observable,
            groupedMessageFiles: observable,
            fileUploading: observable,
            groupedImages: observable,
            countNotification: computed,
            lastUpdatedList: computed,
            fetchAllSuccessed: action.bound,
            fetchMessagesSuccessed: action.bound,
            addNewTicket: action.bound,
            markAsRead: action.bound,
            incrementUnread: action.bound,
            removeTicket: action.bound,
            setNotifications: action.bound,
            particialUpdate: action.bound,
            updateLastSender: action.bound,
            updateNeedHelp: action.bound,
            setFiles: action.bound,
            appendFiles: action.bound,
            removeFileSync: action.bound,
            replaceFile: action.bound,
            setFileProgress: action.bound,
            setToUploading: action.bound,
            removeFromUploading: action.bound,
            resetFiles: action.bound,
            setImages: action.bound,
            appendImages: action.bound,
            subsribeSendTicketList: action,
            subsribeSendMessages: action,
            subsribeReceiveMessage: action,
            createTicket: action,
            subsribeNewTicket: action,
            subsribeParticialUpdate: action,
            subsribeRemoveTicket: action,
            subsribeRemoveOwnerTicket: action,
            subsribeRemoveInWorkTicket: action,
            subsribeTicketReaded: action,
            subsribeRedirectTo: action,
            subsribeNotifications: action,
            needOwnerHelp: action,
            subsribeNewTicketToHelp: action,
            blockUserByTicket: action,
            addFiles: action,
            removeFile: action,
        });
    }
    get countNotification() {
        return Object.values(this.notifications).reduce((acc, curr) => acc + curr, 0);
    }
    get lastUpdatedList() {
        return [...this.allList].sort((prev, next) => new Date(next.updatedAt).getTime() - new Date(prev.updatedAt).getTime());
    }
}
