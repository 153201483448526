import React, { useRef, useState, useCallback, } from 'react';
import cn from 'classnames';
import { useParams } from 'react-router-dom';
import { StatusSingletoneTooltipProvider } from 'providers';
import { isNull } from 'utils/selectors';
import { useForceUpdate, useLanguage, useResizeObserver, useUpdate, } from 'hooks';
import { scrollToTop } from 'utils/functions';
import { Recommendation } from 'ui/molecules';
import { colors, vars } from 'ui/theme';
import { Icon } from 'ui/atoms';
import style from './style.module.scss';
const controlHeight = 106;
const Recommendations = ({ recommendations }) => {
    const { i18n } = useLanguage();
    const forceUpdate = useForceUpdate();
    const { gameSlug, cheatSlug } = useParams();
    const contentWrapperRef = useRef(null);
    const contentListRef = useRef(null);
    const isNotMaxHeight = recommendations.length > 2;
    const [isPrevVisible, setIsPrevVisible] = useState(false);
    const [isNextVisible, setIsNextVisible] = useState(true);
    const transformOffset = useRef(0);
    const setTransformOffset = useCallback((val) => {
        if (val === transformOffset.current)
            return;
        transformOffset.current = val;
        forceUpdate();
    }, []);
    const calcNextVisible = useCallback(() => {
        if (isNull(contentListRef.current) || isNull(contentWrapperRef.current))
            return;
        if (contentWrapperRef.current.offsetHeight > contentListRef.current.offsetHeight) {
            setIsPrevVisible(false);
            setIsNextVisible(false);
            setTransformOffset(0);
            return;
        }
        const maxOffset = contentListRef.current.offsetHeight - contentWrapperRef.current.offsetHeight;
        if (maxOffset < transformOffset.current) {
            setTransformOffset(maxOffset);
        }
        setIsPrevVisible(transformOffset.current !== 0);
        setIsNextVisible(transformOffset.current < maxOffset);
    }, []);
    useResizeObserver(calcNextVisible, contentWrapperRef);
    useUpdate(calcNextVisible, [cheatSlug]);
    useUpdate(scrollToTop, [cheatSlug], true);
    const handlePrev = useCallback(() => {
        if (isNull(contentListRef.current) || isNull(contentWrapperRef.current))
            return;
        const offset = contentWrapperRef.current.offsetHeight - controlHeight;
        const newValue = transformOffset.current - offset;
        const minValue = 0;
        setIsNextVisible(true);
        if (minValue > newValue) {
            setIsPrevVisible(false);
            setTransformOffset(minValue);
            return;
        }
        setTransformOffset(newValue);
    }, []);
    const handleNext = useCallback(() => {
        if (isNull(contentListRef.current) || isNull(contentWrapperRef.current))
            return;
        const offset = contentWrapperRef.current.offsetHeight - controlHeight;
        const newValue = transformOffset.current + offset;
        const maxValue = contentListRef.current.offsetHeight - contentWrapperRef.current.offsetHeight;
        setIsPrevVisible(true);
        if (maxValue < newValue) {
            setIsNextVisible(false);
            setTransformOffset(maxValue);
            return;
        }
        setTransformOffset(newValue);
    }, []);
    if (!recommendations.length) {
        return null;
    }
    return (React.createElement("div", { className: style.wrapper, "data-max-height": !isNotMaxHeight },
        React.createElement("div", { className: style.title }, i18n.t('RECOMMENDATIONS')),
        React.createElement("div", { "data-visible": isNotMaxHeight && isPrevVisible, className: cn(style['control'], style['control__top']), onClick: handlePrev },
            React.createElement("div", { className: cn(style['control__arrow'], style['control__arrow_top']) },
                React.createElement(Icon, { name: "arrow-up", width: "17", height: "9", stroke: colors.primary }))),
        React.createElement(StatusSingletoneTooltipProvider, null,
            React.createElement("div", { ref: contentWrapperRef, className: isNotMaxHeight
                    ? style['content__wrapper']
                    : undefined },
                React.createElement("div", { ref: contentListRef, className: style.content, style: vars(['--transform', `-${transformOffset.current}px`]) }, recommendations.map(({ slug, name, status, previewJPEG, previewWebP, isPublished, features, minPrice, }) => (React.createElement(Recommendation, { key: slug, status: status, previewJPEG: previewJPEG, previewWebP: previewWebP, to: `/cheat/${gameSlug}/${slug}`, title: name, minPrice: minPrice, features: features, isPriceOutOfStock: !isPublished })))))),
        React.createElement("div", { "data-visible": isNotMaxHeight && isNextVisible, className: cn(style['control'], style['control__bottom']), onClick: handleNext },
            React.createElement("div", { className: cn(style['control__arrow'], style['control__arrow_bottom']) },
                React.createElement(Icon, { name: "arrow-down", width: "17", height: "9", stroke: colors.primary })))));
};
export default Recommendations;
