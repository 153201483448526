import React from 'react';
import { Button, Price } from 'ui/atoms';
import { useLanguage } from 'hooks';
import style from './style.module.scss';
export const OrderDetails = ({ price, onSubmit, disabled }) => {
    const { i18n } = useLanguage();
    return (React.createElement("div", { className: style.wrapper },
        React.createElement("div", { className: style.label }, i18n.t('ORDER_FORM_LABEL')),
        React.createElement("div", { className: style.price__details }, price && !disabled ? (React.createElement(React.Fragment, null,
            price.discountPrice && (React.createElement(Price, { currency: price.currency, amount: price.price, className: style.discount__price, type: "discount" })),
            React.createElement(Price, { currency: price.currency, amount: price.discountPrice || price.price, className: style.price }))) : (React.createElement(Price, null))),
        React.createElement(Button, { theme: "solid-gradient", className: style.btn, disabled: disabled, onClick: onSubmit }, i18n.t('ORDER_FORM_SUBMIT'))));
};
