import React, { useState, useCallback, useEffect, } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, LoadMoreButton, } from 'ui/atoms';
import { useLanguage, useMobile, useUpdate } from 'hooks';
import { Details, Carousel, Advantages, } from './components';
import style from './style.module.scss';
const stepSize = 3;
const handleScroll = () => {
    var _a;
    return (_a = document.querySelector('#games-catalog')) === null || _a === void 0 ? void 0 : _a.scrollIntoView({
        behavior: 'smooth',
    });
};
const getListByStep = (list, step) => list
    .slice(step * stepSize, step * stepSize + stepSize);
const BestGamesCarousel = ({ bestGamesList, settings }) => {
    const { isAboveTablet } = useMobile();
    const { i18n } = useLanguage();
    const [step, setStep] = useState(0);
    const [isDisabled, setDisabled] = useState(false);
    const [animation, setAnimation] = useState(null);
    const [animationDetailed, setAnimationDetailed] = useState(null);
    const [detailedItem, setDetailedItem] = useState(bestGamesList[0]);
    const [carouselList, setCarouselList] = useState(getListByStep(bestGamesList, step));
    const maxStep = Math.ceil(bestGamesList.length / stepSize);
    const maxStepIndex = maxStep - 1;
    useUpdate(() => {
        const list = getListByStep(bestGamesList, step);
        setDetailedItem(list[0]);
        setCarouselList(list);
    }, [bestGamesList], true);
    const handlePrevStep = useCallback((decrement) => {
        if (isDisabled)
            return;
        decrement = typeof decrement === 'number'
            ? decrement
            : 1;
        const prevStep = (step - decrement) < 0
            ? maxStepIndex
            : step - decrement;
        const newDetailed = bestGamesList[prevStep * stepSize];
        setDisabled(true);
        setAnimation('fadeOutDown');
        setAnimationDetailed('fadeOutDown');
        setTimeout(() => {
            setAnimationDetailed('fadeInDown');
            setDetailedItem(newDetailed);
            setStep(prevStep);
            setTimeout(() => {
                setAnimation('beforeInDown');
                setAnimationDetailed(null);
                setCarouselList(getListByStep(bestGamesList, prevStep));
                setTimeout(() => {
                    setAnimation('fadeInDown');
                    setTimeout(() => {
                        setAnimation(null);
                        setDisabled(false);
                    }, 580);
                }, 20);
            }, 400);
        }, 400);
    }, [step, isDisabled, bestGamesList]);
    const handleNextStep = useCallback((increment) => {
        if (isDisabled)
            return;
        increment = typeof increment === 'number'
            ? increment
            : 1;
        const nextStep = (step + increment) > maxStepIndex
            ? 0
            : step + increment;
        const newDetailed = bestGamesList[nextStep * stepSize];
        setDisabled(true);
        setAnimation('fadeOutUp');
        setAnimationDetailed('fadeOutUp');
        setTimeout(() => {
            setAnimationDetailed('fadeInUp');
            setDetailedItem(newDetailed);
            setStep(nextStep);
            setTimeout(() => {
                setAnimation('beforeInUp');
                setCarouselList(getListByStep(bestGamesList, nextStep));
                setAnimationDetailed(null);
                setTimeout(() => {
                    setAnimation('fadeInUp');
                    setTimeout(() => {
                        setAnimation(null);
                        setDisabled(false);
                    }, 580);
                }, 20);
            }, 400);
        }, 400);
    }, [step, isDisabled, bestGamesList]);
    useEffect(() => {
        const autoplayTimer = setInterval(handleNextStep, 8000);
        return () => clearInterval(autoplayTimer);
    }, [handleNextStep]);
    const changeStep = useCallback((nextStep) => nextStep > step
        ? handleNextStep(nextStep - step)
        : handlePrevStep(step - nextStep), [step, handlePrevStep, handleNextStep]);
    if (!bestGamesList.length) {
        return null;
    }
    return (React.createElement("div", { className: style.wrapper },
        React.createElement(Container, { className: style.grid },
            React.createElement(Details, { onNext: handleNextStep, onPrev: handlePrevStep, settings: settings, game: detailedItem.game, animation: animationDetailed }),
            React.createElement("div", { className: style.carousel },
                React.createElement("div", { className: style.carousel__title }, i18n.t('BEST_GAMES_CAROUSEL_TITLE')),
                React.createElement(Carousel, { step: step, maxStep: maxStep, list: isAboveTablet
                        ? carouselList
                        : bestGamesList, animation: animation, onNext: handleNextStep, onPrev: handlePrevStep, changeStep: changeStep })),
            React.createElement(Advantages, null),
            React.createElement(LoadMoreButton, { className: style['load-more'], onClick: handleScroll }, i18n.t('GAMES_CATALOG_TITLE')))));
};
export default observer(BestGamesCarousel);
