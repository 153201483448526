import React, { useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react-lite';
import { BestGamesCarousel, GamesCatalog } from 'ui/organisms';
import { useMobile, useStore } from 'hooks';
const Home = () => {
    const { bestGamesStore, gamesStore, commonStore } = useStore();
    const { isAboveTablet } = useMobile();
    const fetchGamesListAction = gamesStore.fetchGamesList;
    const mobileRedirectToTechSupportAction = commonStore.mobileRedirectToTechSupport;
    const bestGamesList = bestGamesStore.allI18nList;
    const gamesList = gamesStore.allI18nList;
    const pagination = gamesStore.pagination;
    const isLoading = gamesStore.pending;
    const settings = commonStore.settingsList;
    const loadMoreAction = useCallback(() => fetchGamesListAction(true), []);
    const { homeTitle: title, homeDescription: description, homeText: text, } = settings;
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            title && React.createElement("title", null, title),
            description && React.createElement("meta", { name: "description", content: description })),
        React.createElement(GamesCatalog, { games: gamesList, isLoading: isLoading, description: text, isShowLoadMore: !!pagination.nextPage, loadMoreAction: loadMoreAction },
            React.createElement(BestGamesCarousel, { bestGamesList: bestGamesList, settings: settings, redirectToTechSupportAction: isAboveTablet
                    ? mobileRedirectToTechSupportAction
                    : mobileRedirectToTechSupportAction }))));
};
export const preload = ({ store }) => Promise.all([
    store.bestGamesStore.fetchBestGamesList(),
    store.gamesStore.fetchGamesList(),
]);
export default observer(Home);
