import React, { Fragment, useMemo, } from 'react';
import cn from 'classnames';
import { Icon } from 'ui/atoms';
import { BestGameCard } from 'ui/molecules';
import { colors } from 'ui/theme';
import { useMobile } from 'hooks';
import { observer } from 'mobx-react-lite';
import style from './style.module.scss';
const getAnimationCN = (animation) => {
    switch (animation) {
        case 'beforeInUp':
            return style['carousel-item-before-in-up'];
        case 'fadeInUp':
            return style['carousel-item-fade-in-up'];
        case 'fadeOutUp':
            return style['carousel-item-fade-out-up'];
        case 'beforeInDown':
            return style['carousel-item-before-in-down'];
        case 'fadeInDown':
            return style['carousel-item-fade-in-down'];
        case 'fadeOutDown':
            return style['carousel-item-fade-out-down'];
    }
};
export const Carousel = observer(({ animation, maxStep, step, list, onNext, onPrev, changeStep, }) => {
    const { isAboveTablet } = useMobile();
    const steps = useMemo(() => new Array(maxStep)
        .fill(null)
        .map((_, index) => index), []);
    const OverflowWrapper = isAboveTablet
        ? Fragment
        : 'div';
    return (React.createElement("div", { className: style.carousel },
        isAboveTablet && (React.createElement("div", { className: style['carousel__control-wrapper'] },
            React.createElement(Icon, { name: "arrow-rounded-up", width: "16", height: "9", fill: colors.gray2, className: style.carousel__control, onClick: onPrev }),
            steps.map(stepItem => (React.createElement("div", { key: stepItem, className: cn(style['carousel__control-item'], step === stepItem && style['carousel__control-item-active']), onClick: () => changeStep(stepItem) }))),
            React.createElement(Icon, { name: "arrow-rounded-down", width: "16", height: "9", fill: colors.gray2, className: style.carousel__control, onClick: onNext }))),
        React.createElement(OverflowWrapper, Object.assign({}, (isAboveTablet
            ? {}
            : { className: style['carousel__overflow-wrapper'] })), list.map(({ id, game }) => (React.createElement(BestGameCard, { key: id, previewJPEG: game.previewVerticalJPEG, previewWebP: game.previewVerticalWebP, slug: game.slug, minPrice: game.minPrice, title: game.name, className: cn(style['carousel-item'], isAboveTablet && getAnimationCN(animation)) }))))));
});
