import React, { useMemo, useState, useCallback, useEffect, } from 'react';
import loadable from '@loadable/component';
import { useParams } from 'react-router-dom';
import { Icon, Image, ProgressStepBar, } from 'ui/atoms';
import { useLayoutSSR, useMobile, useUpdate } from 'hooks';
import { colors } from 'ui/theme';
import { findIndexFromIndex, findIndexFromIndexRevert, } from 'utils/array';
import { GradientBorder } from './components';
import style from './style.module.scss';
const ImageViewer = loadable(() => import('./components/ImageViewer'));
const IMAGES_TO_SHOW = 5;
const isImg = (item) => (item === null || item === void 0 ? void 0 : item.type) === 'image';
export const HighlightCarousel = ({ highlights }) => {
    const { isMobile, isTablet } = useMobile();
    const { cheatSlug } = useParams();
    const [activeThumb, setActiveThumb] = useState(0);
    const [isOpen, setOpen] = useState(false);
    useUpdate(() => {
        setActiveThumb(0);
    }, [cheatSlug], true);
    useLayoutSSR(() => {
        if (!highlights[activeThumb]) {
            setActiveThumb(0);
        }
    }, [highlights]);
    const handleClose = useCallback(() => setOpen(false), []);
    const handleOpen = useCallback(() => setOpen(true), []);
    const handlePrev = useCallback(() => setActiveThumb(old => old === 0
        ? highlights.length - 1
        : old - 1), [highlights.length, highlights]);
    const handleNext = useCallback(() => setActiveThumb(old => old === highlights.length - 1
        ? 0
        : old + 1), [highlights.length, highlights]);
    useEffect(() => {
        const timer = setTimeout(handleNext, 20000);
        return () => clearTimeout(timer);
    }, [activeThumb, handleNext]);
    const startIndex = Math.floor(activeThumb / IMAGES_TO_SHOW) * IMAGES_TO_SHOW;
    const noramlizedStart = startIndex + IMAGES_TO_SHOW > highlights.length
        ? highlights.length - IMAGES_TO_SHOW
        : startIndex;
    const endIndex = Math.ceil((activeThumb + 1) / IMAGES_TO_SHOW) * IMAGES_TO_SHOW;
    const noramlizedEnd = endIndex > highlights.length
        ? highlights.length
        : endIndex;
    const nextViewerIndex = useMemo(() => findIndexFromIndex(activeThumb === highlights.length - 1
        ? 0
        : activeThumb + 1, highlights, isImg), [activeThumb, highlights]);
    const prevViewerIndex = useMemo(() => findIndexFromIndexRevert(activeThumb === 0
        ? highlights.length - 1
        : activeThumb - 1, highlights, isImg), [activeThumb, highlights]);
    const handlePrevViewer = useCallback(() => setActiveThumb(prevViewerIndex), [prevViewerIndex]);
    const handleNextViewer = useCallback(() => setActiveThumb(nextViewerIndex), [nextViewerIndex]);
    const viewer = useMemo(() => ({
        active: highlights[activeThumb],
        next: highlights[prevViewerIndex],
        prev: highlights[nextViewerIndex],
    }), [activeThumb, nextViewerIndex, prevViewerIndex, highlights]);
    return (React.createElement("div", { className: style.wrapper },
        React.createElement("div", { className: style.thumbnails__wrapper },
            React.createElement("div", { className: style.thumbnails__progress },
                (isMobile || isTablet) && (React.createElement("div", { className: style.thumbnail__control, "data-position": "left", onClick: handlePrev },
                    React.createElement(Icon, { name: "arrow-left", width: "17", height: "17", stroke: colors.backgroundGrayLight }))),
                React.createElement(ProgressStepBar, { steps: highlights.length, currentStep: activeThumb, direction: isMobile || isTablet
                        ? 'horizontal'
                        : 'vertical' }),
                (isMobile || isTablet) && (React.createElement("div", { className: style.thumbnail__control, "data-position": "right", onClick: handleNext },
                    React.createElement(Icon, { name: "arrow-right", width: "17", height: "17", stroke: colors.backgroundGrayLight })))),
            React.createElement("div", { className: style.thumbnails },
                !isMobile && !isTablet && (React.createElement("div", { className: style.thumbnail__control, "data-position": "top", onClick: handlePrev },
                    React.createElement(Icon, { name: "arrow-up", width: "17", height: "17", stroke: colors.backgroundGrayLight }))),
                React.createElement("div", { className: style.thumbnails__images }, highlights.map((highlight, index) => {
                    const isHidden = noramlizedStart > index || noramlizedEnd <= index;
                    return (React.createElement("div", { key: highlight.id, className: style.thumbnail__wrapper, "data-hide": isHidden },
                        highlight.type === 'image'
                            ? (React.createElement(Image, { alt: "", webp: highlight.src.webp, jpeg: highlight.src.jpeg, className: style.thumbnail__image }))
                            : (React.createElement(React.Fragment, null,
                                React.createElement("div", { className: style.thumbnail__image, dangerouslySetInnerHTML: { __html: highlight.html } }),
                                !isHidden && (React.createElement(Icon, { name: "play-button", width: "18", height: "18", fill: colors.white })))),
                        React.createElement(GradientBorder, { idPrefix: highlight.id, active: activeThumb === index, onClick: () => setActiveThumb(index) })));
                })),
                !isMobile && !isTablet && (React.createElement("div", { className: style.thumbnail__control, "data-position": "bottom", onClick: handleNext },
                    React.createElement(Icon, { name: "arrow-down", width: "17", height: "17", stroke: colors.backgroundGrayLight }))))),
        React.createElement("div", { className: style.highlight__wrapper }, highlights.map((highlight, index) => (React.createElement("div", { className: style.highlight__position, key: highlight.id, "data-active": activeThumb === index }, highlight.type === 'image'
            ? (React.createElement(Image, { alt: "", webp: highlight.src.webp, jpeg: highlight.src.jpeg, onClick: handleOpen }))
            : (React.createElement("div", { "data-name": "html", dangerouslySetInnerHTML: { __html: highlight.html } })))))),
        (isOpen && isImg(viewer.active)) && (React.createElement(ImageViewer, { activeImage: viewer.active.src.jpeg, prevImage: viewer.prev.src.jpeg, nextImage: viewer.next.src.jpeg, onClose: handleClose, onPrev: handlePrevViewer, onNext: handleNextViewer }))));
};
