var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { Route, Redirect } from 'react-router-dom';
const RedirectRoute = ({ condition, redirect, routeProps, }) => {
    const { component: Component, render } = routeProps, restProps = __rest(routeProps, ["component", "render"]);
    const routeContent = render
        ? render
        : (props) => React.createElement(Component, Object.assign({}, props));
    return condition ? (React.createElement(Route, Object.assign({}, restProps, { render: routeContent }))) : (React.createElement(Redirect, { to: !routeProps.location ? redirect : {
            pathname: redirect,
            state: {
                from: routeProps.location,
            },
        } }));
};
export default RedirectRoute;
