import React, { useRef, useState, useCallback, createContext, } from 'react';
import { createPopper } from '@popperjs/core';
import { useMobile } from 'hooks';
import { StatusSingletoneTooltip } from 'ui/molecules';
export const StatusSingletoneTooltipContext = createContext({});
const StatusSingletoneTooltipProvider = ({ children }) => {
    const { isMobile } = useMobile();
    const [status, setStatus] = useState(0);
    const instanceRef = useRef(null);
    const tooltipRef = useRef(null);
    const unmoutingTimer = useRef(null);
    const [isUnmounting, setIsUnmounting] = useState(false);
    const currentIdRef = useRef('');
    const init = useCallback((id, status, div) => {
        if (!tooltipRef.current)
            return;
        if (unmoutingTimer.current) {
            clearTimeout(unmoutingTimer.current);
            setIsUnmounting(false);
        }
        setStatus(status);
        currentIdRef.current = id;
        tooltipRef.current.style.display = 'block';
        instanceRef.current = createPopper(div, tooltipRef.current, {
            placement: 'top',
            modifiers: [
                {
                    name: 'offset',
                    options: {
                        offset: [0, 15],
                    },
                },
                ...(isMobile
                    ? [{
                            name: 'preventOverflow',
                            options: {
                                padding: 15,
                            },
                        }]
                    : []),
            ],
        });
    }, [isMobile]);
    const destroy = useCallback(id => {
        if (!instanceRef.current || currentIdRef.current !== id)
            return;
        setIsUnmounting(true);
        unmoutingTimer.current = setTimeout(() => {
            if (!tooltipRef.current || !instanceRef.current)
                return;
            instanceRef.current.destroy();
            setIsUnmounting(false);
            tooltipRef.current.style.display = 'none';
        }, 300);
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(StatusSingletoneTooltip, { ref: tooltipRef, status: status, isUnmounting: isUnmounting }),
        React.createElement(StatusSingletoneTooltipContext.Provider, { value: {
                destroy,
                init,
            } }, children)));
};
export default StatusSingletoneTooltipProvider;
