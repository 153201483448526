import React, { useCallback, useRef, useState, } from 'react';
import cn from 'classnames';
import { useLayoutSSR, useListener } from 'hooks';
import { getWindowRef } from 'utils/selectors';
import style from './style.module.scss';
export const ProgressStepBar = ({ steps, currentStep, direction = 'horizontal', className, }) => {
    const [progressWidth, setProgressWidth] = useState(0);
    const progressBarRef = useRef(null);
    const calcProgressWidth = useCallback(() => {
        if (progressBarRef.current) {
            setProgressWidth(direction === 'horizontal'
                ? progressBarRef.current.clientWidth / steps
                : progressBarRef.current.clientHeight / steps);
        }
    }, [direction, steps]);
    useListener('resize', getWindowRef(), calcProgressWidth);
    useLayoutSSR(() => {
        calcProgressWidth();
    }, [calcProgressWidth]);
    return (React.createElement("div", { className: cn(style.wrapper, className), "data-direction": direction },
        React.createElement("div", { ref: progressBarRef, className: style.progress__bar },
            React.createElement("div", { className: style.progress, style: Object.assign(Object.assign({}, (direction === 'horizontal'
                    ? { width: `${progressWidth}px` }
                    : { height: `${progressWidth}px` })), { transform: direction === 'horizontal'
                        ? `translateX(${currentStep * progressWidth}px)`
                        : `translateY(${currentStep * progressWidth}px)` }) }))));
};
