import React from 'react';
import { Switch } from 'react-router-dom';
import { renderRoutes } from 'libs/router';
import { TabLink } from 'ui/atoms';
import { useLanguage } from 'hooks';
import style from '../TechSupportTemplate/style.module.scss';
const OwnerTemplate = ({ routes, notifications }) => {
    const { ownerMy, ownerNeedHelp } = notifications;
    const { i18n } = useLanguage();
    return (React.createElement("div", null,
        React.createElement("div", { className: style.tabs },
            React.createElement(TabLink, { to: "/profile/owner", exact: true },
                i18n.t('TICKET_STATUS_REVIEW'),
                ownerNeedHelp !== 0 && (React.createElement("span", { className: style.notification }, ownerNeedHelp))),
            React.createElement(TabLink, { to: '/profile/owner/my' },
                i18n.t('TECHSUPPORT_MY'),
                ownerMy !== 0 && (React.createElement("span", { className: style.notification }, ownerMy))),
            React.createElement(TabLink, { to: '/profile/owner/all' }, i18n.t('TECHSUPPORT_ALL'))),
        React.createElement(Switch, null, renderRoutes(routes))));
};
export default OwnerTemplate;
