import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { useInput, useLanguage, useMobile, useUpdate, } from 'hooks';
import { Container, SearchInput, StatusBox } from 'ui/atoms';
import { getUrlParam } from 'utils/selectors';
import { debounce } from 'utils/functions';
import { getStatus } from 'ui/theme';
import style from './style.module.scss';
const statuses = [0, 1, 2, 3];
const SimpleStatusesSearchForm = () => {
    const { isMobile, isTablet } = useMobile();
    const { i18n } = useLanguage();
    const history = useHistory();
    const { search } = useLocation();
    const searchParam = useMemo(() => getUrlParam(search, 'search'), [search]);
    const { props: input } = useInput(searchParam || '');
    const handleRedirect = useCallback((value) => history.push({
        pathname: '/statuses',
        search: value
            ? `?search=${value}`
            : undefined,
    }), []);
    const handleDebouncedRedirect = useCallback(debounce(handleRedirect, 300), []);
    const handleSearchClick = useCallback(() => handleRedirect(input.value), [input.value]);
    useUpdate(() => {
        handleDebouncedRedirect(input.value);
    }, [input.value]);
    return (React.createElement(Container, null,
        React.createElement("h1", { className: style.title }, i18n.t(searchParam
            ? 'SEARCH_RESULT_LOWER_TITLE'
            : 'STATUSES_TITLE').replace('#search#', searchParam)),
        !isMobile && !isTablet && (React.createElement(React.Fragment, null,
            React.createElement("div", { className: style.header },
                React.createElement("div", null,
                    React.createElement(SearchInput, Object.assign({}, input, { placeholder: i18n.t('SEARCH_PLACEHOLDER'), onSearchClick: handleSearchClick }))),
                React.createElement("p", { className: style.description }, i18n.t('STATUSES_DESCRIPTION'))),
            React.createElement("div", { className: style.status__wrapper }, statuses.map(status => (React.createElement("div", { key: status, className: style.status },
                React.createElement(StatusBox, { status: status }),
                React.createElement("span", { className: style.status__text }, i18n.t(getStatus(status).hint))))))))));
};
export default SimpleStatusesSearchForm;
