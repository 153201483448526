import React from 'react';
import { useMobile } from 'hooks';
import { ProductHeader, TechSupportFooter } from 'ui/molecules';
import { Container, CrosshairsDecore, HTMLPreviewer } from 'ui/atoms';
import style from './style.module.scss';
const ProblemTemplate = ({ title, description, redirectToTechSupportAction }) => {
    const { isAboveTablet, isMobile } = useMobile();
    return (React.createElement(React.Fragment, null,
        !isMobile && (React.createElement(ProductHeader, { title: title, titleClassName: style.title__header, webp: "/public/images/backgrounds/RadialOrangeGradient.webp", jpeg: "/public/images/backgrounds/RadialOrangeGradient.jpg", alt: "RadialOrangeGradient" }, isAboveTablet && React.createElement(CrosshairsDecore, null))),
        React.createElement(Container, null,
            isMobile && (React.createElement("h1", { className: style.title }, title)),
            React.createElement(HTMLPreviewer, { html: description, className: style.body })),
        React.createElement("div", { className: style.spacer }),
        React.createElement(TechSupportFooter, { redirectToTechSupportAction: redirectToTechSupportAction })));
};
export default ProblemTemplate;
