import React, { createContext, useState, useCallback, } from 'react';
import { noop, computeIsMobile, computeIsTablet, computeIsDesktop, throttle, } from 'utils/functions';
import { useListener } from 'hooks';
import { getWindowRef } from 'utils/selectors';
export const AdaptiveContext = createContext({
    isMobile: true,
    isTablet: false,
    isAboveTablet: false,
    isDesktop: false,
    addResizeListener: noop,
    removeResizeListener: noop,
    addScrollListener: noop,
    removeScrollListener: noop,
});
export const AdaptiveConsumer = AdaptiveContext.Consumer;
const resizeListeners = [];
const scrollListeners = [];
const AdaptiveProvider = ({ ssrIsMobile, ssrIsTablet, ssrIsDesktop, children, }) => {
    const [isMobile, setIsMobile] = useState(ssrIsMobile || computeIsMobile());
    const [isTablet, setIsTablet] = useState(ssrIsTablet || computeIsTablet());
    const [isDesktop, setIsDesktop] = useState(ssrIsDesktop || computeIsDesktop());
    const setValues = useCallback(event => {
        setIsMobile(computeIsMobile());
        setIsTablet(computeIsTablet());
        setIsDesktop(computeIsDesktop());
        resizeListeners.forEach(listener => typeof listener === 'function' && listener(event));
    }, []);
    const addResizeListener = useCallback(listener => {
        resizeListeners.push(listener);
    }, []);
    const removeResizeListener = useCallback(listener => {
        const index = resizeListeners.indexOf(listener);
        index !== -1 && resizeListeners.splice(index, 1);
    }, []);
    useListener('resize', getWindowRef(), setValues);
    const addScrollListener = useCallback(listener => {
        scrollListeners.push(listener);
    }, []);
    const removeScrollListener = useCallback(listener => {
        const index = scrollListeners.indexOf(listener);
        index !== -1 && scrollListeners.splice(index, 1);
    }, []);
    const notifyScrollListeners = useCallback(throttle(event => scrollListeners.forEach(listener => typeof listener === 'function' && listener(event)), 22), []);
    useListener('scroll', getWindowRef(), notifyScrollListeners, {
        passive: true,
    });
    return (React.createElement(AdaptiveContext.Provider, { value: {
            isMobile,
            isTablet,
            isAboveTablet: !isTablet && !isMobile,
            isDesktop,
            addResizeListener,
            removeResizeListener,
            addScrollListener,
            removeScrollListener,
        } }, children));
};
AdaptiveProvider.defaultProps = {
    ssrIsMobile: undefined,
    ssrIsTablet: undefined,
    ssrIsDesktop: undefined,
};
export default AdaptiveProvider;
