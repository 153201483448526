import React, { memo, useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { useLanguage, useMobile, useHookForm } from 'hooks';
import { FieldText } from 'ui/molecules';
import { Button, Icon } from 'ui/atoms';
import { noop } from 'utils/functions';
import { colors } from 'ui/theme';
import { errorMsg, validateEmail, validateRequired, } from 'utils/validate';
import style from './style.module.scss';
const INITIAL_VALUES = {
    email: '',
};
const ChangeEmailForm = ({ changeEmailAction }) => {
    const { isAboveTablet } = useMobile();
    const { i18n, language } = useLanguage();
    const form = useHookForm({
        defaultValues: INITIAL_VALUES,
        resolver: values => {
            const errors = {};
            if (validateEmail(values.email)) {
                errors.email = errorMsg(i18n.t('VALIDATE_EMAIL'));
            }
            if (validateRequired(values.email)) {
                errors.email = errorMsg(i18n.t('VALIDATE_REQUIRED'));
            }
            return {
                values,
                errors,
            };
        },
    });
    const handleSubmit = useCallback(async (values) => {
        const success = await changeEmailAction(values);
        if (success) {
            return form.reset();
        }
        return form.setError('email', errorMsg(i18n.t('PERSONAL_CHNAGE_EMAIL_ALREADY_EXISTS')));
    }, [language]);
    const onSubmit = form.handleSubmit(handleSubmit);
    return (React.createElement("div", { className: style.wrapper },
        React.createElement("div", { className: style.wrapper__title }, i18n.t('PERSONAL_CHNAGE_EMAIL_TITLE')),
        React.createElement(FormProvider, Object.assign({}, form),
            React.createElement("form", { className: style.wrapper__group, onSubmit: onSubmit },
                React.createElement(FieldText, { name: "email", autoComplete: "off", className: style.wrapper__input, placeholder: i18n.t('PERSONAL_CHNAGE_EMAIL_PLACEHOLDER') }),
                isAboveTablet
                    ? (React.createElement("div", { className: style.wrapper__btn, onClick: form.formState.isSubmitting
                            ? noop
                            : onSubmit },
                        React.createElement(Icon, { fill: colors.backgroundGrayLight, width: "23", height: "23", name: "diskette" })))
                    : (React.createElement(Button, { theme: "solid-gradient-gray", onClick: onSubmit, className: style['wrapper__btn-mobile'], disabled: form.formState.isSubmitting },
                        React.createElement(Icon, { name: "diskette", width: "12", height: "12", fill: colors.white }),
                        i18n.t('PERSONAL_CHANGE_PASSWORD_SUBMIT')))))));
};
export default memo(ChangeEmailForm);
