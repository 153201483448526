import http from 'utils/http';
import { getNotificationRegisted, setNotificationRegisted } from 'utils/selectors';
const urlBase64ToUint8Array = (base64String) => {
    const padding = '='.repeat((4 - base64String.length % 4) % 4);
    const base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');
    const rawData = atob(base64);
    const outputArray = new Uint8Array(rawData.length);
    for (let i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
    }
    return outputArray;
};
export const register = async () => {
    if ('serviceWorker' in navigator
        && 'Notification' in window
        && !getNotificationRegisted()) {
        const permission = await Notification.requestPermission();
        if (permission === 'granted') {
            const register = await navigator.serviceWorker.register('/serviceWorker.js', {
                scope: '/',
            });
            await navigator.serviceWorker.ready;
            const subscription = await register.pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: urlBase64ToUint8Array(process.env.RAZZLE_APP_PUBLIC_VAPID_KEY),
            });
            await http.post('/users/subscribe/notification', {
                subscription: JSON.stringify(subscription),
            });
            setNotificationRegisted('true');
        }
        else if (permission === 'denied') {
            setNotificationRegisted('false');
        }
    }
    else {
        console.error('Service workers are not supported in this browser');
    }
};
export const unregister = async () => {
    if ('serviceWorker' in navigator) {
        const registration = await navigator.serviceWorker.ready;
        registration.unregister();
    }
};
