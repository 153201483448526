import * as Statuses from './Statuses';
import * as Cheat from './Cheat';
import Functions from './Cheat/Tabs/Functions';
import * as Requirements from './Cheat/Tabs/Requirements';
export const routes = [
    {
        path: '/statuses',
        component: Statuses.default,
        preload: Statuses.preload,
    },
    {
        path: '/cheat/:gameSlug/:cheatSlug',
        component: Cheat.default,
        preload: Cheat.preload,
        routes: [
            {
                path: '',
                exact: true,
                component: Functions,
            },
            {
                path: '/requirements',
                component: Requirements.default,
                preload: Requirements.preload,
            },
        ],
    },
];
