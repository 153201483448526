import React, { useMemo, useCallback } from 'react';
import { Helmet } from 'react-helmet-async';
import { getUrlParam } from 'utils/selectors';
import { useLanguage, useStore } from 'hooks';
import { GamesCatalog as GamesCatalogList } from 'ui/organisms';
import { observer } from 'mobx-react-lite';
const GamesCatalog = ({ location }) => {
    const { i18n } = useLanguage();
    const { gamesStore, commonStore } = useStore();
    const fetchGamesListAction = gamesStore.fetchGamesList;
    const searchGamesListAction = gamesStore.searchGamesList;
    const search = useMemo(() => getUrlParam(location.search, 'search'), [location.search]);
    const gamesList = gamesStore.allI18nList;
    const pagination = gamesStore.pagination;
    const gamesSearchList = gamesStore.searchI18nList;
    const searchPagination = gamesStore.searchPagination;
    const isLoading = gamesStore.pending;
    const { homeText, homeDescription } = commonStore.settingsList;
    const loadMoreAction = useCallback(() => search
        ? searchGamesListAction(true)
        : fetchGamesListAction(true), [search]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, i18n.t(search
                ? 'SEARCH_RESULT_LOWER_TITLE'
                : 'GAMES_CATALOG_TITLE').replace('#search#', search)),
            homeDescription && React.createElement("meta", { name: "description", content: homeDescription })),
        React.createElement(GamesCatalogList, { games: search
                ? gamesSearchList
                : gamesList, search: search, isLoading: isLoading, description: homeText, isShowLoadMore: search
                ? !!searchPagination.nextPage
                : !!pagination.nextPage, loadMoreAction: loadMoreAction })));
};
export const preload = ({ store, location }) => location.search
    ? store.gamesStore.searchGamesList()
    : store.gamesStore.fetchGamesList();
export default observer(GamesCatalog);
