import React, { useMemo, useRef } from 'react';
import { matchPath, useLocation, } from 'react-router-dom';
import { useLayoutSSR } from 'hooks';
import { languageMask } from 'utils/constants';
export const DataLoader = ({ path, exact, children, }) => {
    const location = useLocation();
    const loadedPathRef = useRef();
    const normalizedPath = useMemo(() => {
        const pathWithoutLangPrefix = location.pathname.replace(languageMask, '');
        return pathWithoutLangPrefix === ''
            ? '/'
            : pathWithoutLangPrefix;
    }, [location.pathname]);
    useLayoutSSR(() => {
        if (loadedPathRef.current !== normalizedPath && path) {
            const match = matchPath(normalizedPath, { path, exact });
            if (match) {
                loadedPathRef.current = normalizedPath;
            }
        }
    }, [normalizedPath, location.search]);
    return React.createElement(React.Fragment, null, children);
};
