import React, { useCallback, useState, } from 'react';
import { observer } from 'mobx-react-lite';
import { FormProvider } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { FieldsMessager } from 'ui/molecules';
import { useHookForm, useLanguage, useSocket, useStore, } from 'hooks';
import { removeUrlLang } from 'utils/selectors';
import { errorMsg, validateMaxLength, validateRequired, } from 'utils/validate';
import { Button } from 'ui/atoms';
import style from './style.module.scss';
const INITIAL_VALUES = {
    message: '',
};
const TicketMessagerForm = ({ ticketId, messagerStatus = 'default', needHelpActionVisible = false, needOwnerHelpAction, blockUserByTicketAction, }) => {
    const { socket } = useSocket();
    const { i18n, language } = useLanguage();
    const { pathname } = useLocation();
    const [isControlsVisible, setIsControlsVisible] = useState(false);
    const { ticketsStore } = useStore();
    const form = useHookForm({
        defaultValues: INITIAL_VALUES,
        resolver: values => {
            const errors = {};
            if (ticketsStore.filesById(ticketId).length === 0) {
                if (validateMaxLength(values.message, 500)) {
                    errors.message = errorMsg(i18n.t('VALIDATE_LENGTH_MIN_MAX')
                        .replace('#min#', '0')
                        .replace('#max#', '500'));
                }
                if (validateRequired(values.message)) {
                    errors.message = errorMsg(i18n.t('VALIDATE_REQUIRED'));
                }
            }
            return {
                values,
                errors,
            };
        },
    });
    const files = ticketsStore.filesById(ticketId);
    const isUploadingInProgress = ticketsStore.isUploadingById(ticketId);
    const toggleControls = useCallback(() => setIsControlsVisible(old => !old), []);
    const handleSubmit = useCallback(values => {
        if (socket && !ticketsStore.isUploadingById(ticketId)) {
            const files = ticketsStore.fileIdsById(ticketId);
            socket.emit('send-message', Object.assign(Object.assign({ ticketId }, (files.length
                ? { files }
                : {})), (values.message
                ? { message: values.message }
                : {})));
            form.reset();
            ticketsStore.resetFiles(ticketId);
        }
    }, [socket]);
    const handleTakeInWork = useCallback(() => removeUrlLang(pathname) === '/profile/techsupport'
        ? socket === null || socket === void 0 ? void 0 : socket.emit('take-in-work', { ticketId })
        : socket === null || socket === void 0 ? void 0 : socket.emit('take-need-help-in-work', { ticketId }), [socket]);
    const handleClose = useCallback(() => socket === null || socket === void 0 ? void 0 : socket.emit('close-ticket', {
        ticketId,
    }), [socket]);
    const handleBlock = useCallback(() => blockUserByTicketAction(ticketId, i18n), [language]);
    const handleHelp = useCallback(() => needOwnerHelpAction(ticketId, socket), [socket]);
    if (messagerStatus === 'take-in-work') {
        return (React.createElement(Button, { theme: "solid-gradient", className: style['take-to-work'], onClick: handleTakeInWork }, i18n.t('TECHSUPPORT_TAKE_TO_WORK')));
    }
    const onSubmit = form.handleSubmit(handleSubmit);
    const isFormDisabled = messagerStatus === 'disabled';
    return (React.createElement(FormProvider, Object.assign({}, form),
        React.createElement("form", { onSubmit: onSubmit, encType: "multipart/form-data", className: style.form },
            React.createElement(FieldsMessager, { id: ticketId, files: files, textareaName: "message", filesName: "files", placeholder: i18n.t('TICKET_CREATE_SUBMIT'), onClick: onSubmit, textareaDisabled: isFormDisabled, controlsDisabled: isUploadingInProgress || form.formState.isSubmitting || isFormDisabled, dropZoneClassName: style.dropzone, underTextarea: messagerStatus === 'close'
                    ? (isControlsVisible
                        ? (React.createElement("div", { className: style.btn__group },
                            needHelpActionVisible && (React.createElement("div", { onClick: handleHelp, className: style.btn__action }, i18n.t('TECHSUPPORT_HELP'))),
                            React.createElement("div", { onClick: handleBlock, className: style.btn__action }, i18n.t('TECHSUPPORT_BLOCK')),
                            React.createElement("div", { onClick: handleClose, className: style.btn__action }, i18n.t('TECHSUPPORT_CLOSE'))))
                        : (React.createElement("div", { className: style.btn__group },
                            React.createElement("div", { onClick: toggleControls, className: style.btn__actions }, i18n.t('TECHSUPPORT_ACTIONS')))))
                    : null }))));
};
export default observer(TicketMessagerForm);
