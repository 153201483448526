import React, { useEffect } from 'react';
import { useSocket, useStore } from 'hooks';
import { TechSupportTicketsTemplate } from 'ui/templates';
import { observer } from 'mobx-react-lite';
const MyTickets = () => {
    const { socket, useAfterConnect } = useSocket();
    const { ticketsStore } = useStore();
    const subsribeSendTicketListAction = ticketsStore.subsribeSendTicketList;
    const needOwnerHelpAction = ticketsStore.needOwnerHelp;
    const blockUserByTicketAction = ticketsStore.blockUserByTicket;
    const tickets = ticketsStore.lastUpdatedList;
    const groupedMessages = ticketsStore.groupedMessages;
    useEffect(() => {
        if (socket) {
            socket.on('send-techsupport-my-tickets', subsribeSendTicketListAction);
            return () => {
                socket.off('send-techsupport-my-tickets');
            };
        }
    }, [socket]);
    useAfterConnect(ws => ws.emit('request-techsupport-my-tickets'));
    return (React.createElement(TechSupportTicketsTemplate, { tickets: tickets, groupedMessages: groupedMessages, messagerStatus: "close", needHelpActionVisible: true, needOwnerHelpAction: needOwnerHelpAction, blockUserByTicketAction: blockUserByTicketAction }));
};
export default observer(MyTickets);
