import React, { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { FieldCheckbox, FieldTermOfUse, FieldText } from 'ui/molecules';
import { Button } from 'ui/atoms';
import { useLanguage, useMobile, useHookForm } from 'hooks';
import { errorMsg, validateEmail, validateRequired, validateMinLength, } from 'utils/validate';
import style from './style.module.scss';
const INITIAL_VALUES = {
    login: '',
    email: '',
    password: '',
    passwordRepeat: '',
    termOfUse: true,
    personalData: true,
    isReceiveNews: true,
};
const SignInForm = ({ isLoading, registerAction }) => {
    const { isAboveTablet } = useMobile();
    const { i18n, language } = useLanguage();
    const form = useHookForm({
        defaultValues: INITIAL_VALUES,
        resolver: values => {
            const errors = {};
            if (validateRequired(values.login)) {
                errors.login = errorMsg(i18n.t('VALIDATE_REQUIRED'));
            }
            if (validateEmail(values.email)) {
                errors.email = errorMsg(i18n.t('VALIDATE_EMAIL'));
            }
            if (validateRequired(values.email)) {
                errors.email = errorMsg(i18n.t('VALIDATE_REQUIRED'));
            }
            if (validateMinLength(values.password, 4)) {
                errors.password = errorMsg(i18n.t('VALIDATE_LENGTH_MIN').replace('#min#', '4'));
            }
            if (validateRequired(values.password)) {
                errors.password = errorMsg(i18n.t('VALIDATE_REQUIRED'));
            }
            if (values.password && values.passwordRepeat
                && values.password !== values.passwordRepeat) {
                errors.passwordRepeat = errorMsg(i18n.t('VALIDATE_PASSWORD_MATCH'));
            }
            if (validateRequired(values.passwordRepeat)) {
                errors.passwordRepeat = errorMsg(i18n.t('VALIDATE_REQUIRED'));
            }
            if (validateRequired(values.termOfUse)) {
                errors.termOfUse = errorMsg('true');
            }
            if (validateRequired(values.personalData)) {
                errors.personalData = errorMsg('true');
            }
            return {
                values,
                errors,
            };
        },
    });
    const handleSubmit = useCallback(async (values) => {
        const success = await registerAction(values);
        if (!success) {
            form.setError('passwordRepeat', errorMsg(i18n.t('SIGN_UP_USER_ALREADY_EXISTS')));
        }
    }, [language]);
    const onSubmit = form.handleSubmit(handleSubmit);
    return (React.createElement(FormProvider, Object.assign({}, form),
        React.createElement("form", { className: style.wrapper, onSubmit: onSubmit },
            React.createElement(FieldText, { size: "medium", name: "login", autoComplete: "off", placeholder: i18n.t('SIGN_IN_LOGIN_PLACEHOLDER') }),
            React.createElement(FieldText, { size: "medium", type: "email", name: "email", placeholder: i18n.t('SIGN_UP_EMAIL_PLACEHOLDER') }),
            React.createElement(FieldText, { size: "medium", type: "password", name: "password", placeholder: i18n.t('SIGN_IN_PASSWORD_PLACEHOLDER') }),
            React.createElement(FieldText, { size: "medium", type: "password", name: "passwordRepeat", placeholder: i18n.t('SIGN_UP_PASSWORD_REPEAT_PLACEHOLDER') }),
            isAboveTablet && (React.createElement(React.Fragment, null,
                React.createElement(FieldTermOfUse, null),
                React.createElement(FieldCheckbox, { name: "isReceiveNews", className: style['is-receive-news'] }, i18n.t('SIGN_UP_IS_RECEIVE_NEWS')))),
            React.createElement(Button, { onClick: onSubmit, theme: "outline-gradient", className: style.btn, disabled: isLoading }, i18n.t('SIGN_UP_SUBMIT'))),
        !isAboveTablet && (React.createElement("div", { className: style['mobile-checkbox-group'] },
            React.createElement(FieldTermOfUse, null),
            React.createElement(FieldCheckbox, { name: "isReceiveNews", className: style['is-receive-news'] }, i18n.t('SIGN_UP_IS_RECEIVE_NEWS'))))));
};
export default SignInForm;
