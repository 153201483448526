import React, { useCallback, useEffect, useMemo, useRef, useState, } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { Switch, useLocation } from 'react-router-dom';
import { renderRoutes } from 'libs/router';
import { Container, Tab, TabLink } from 'ui/atoms';
import { languageMask } from 'utils/constants';
import { vars } from 'ui/theme';
import { useLanguage, useLayoutSSR, useMobile, useRole, } from 'hooks';
import style from './style.module.scss';
const ProfileTemplate = ({ routes, notifications, }) => {
    const { addResizeListener, removeResizeListener } = useMobile();
    const { i18n, makeUrl } = useLanguage();
    const { isTechSupport, isOwner, logout } = useRole();
    const { pathname } = useLocation();
    const containerRef = useRef(null);
    const [sidebarWidth, setSidebarWidth] = useState(0);
    const tabs = useMemo(() => ([
        { title: 'PROFILE_TAB_PROFILE', link: '/profile' },
        { title: 'PROFILE_TAB_PERSONAL', link: '/profile/personal' },
        { title: 'PROFILE_TAB_TICKETS', link: '/profile/tickets', notification: notifications.tickets },
        ...(isTechSupport || isOwner
            ? [
                {
                    title: 'PROFILE_TAB_TECHSUPPORT',
                    link: '/profile/techsupport',
                    notification: notifications.techSupport + notifications.techSupportMy,
                    full: true,
                },
                { title: 'PROFILE_TAB_TECHSUPPORT', link: '/profile/techsupport/my', hide: true },
                { title: 'PROFILE_TAB_TECHSUPPORT', link: '/profile/techsupport/closed', hide: true },
            ]
            : []),
        ...(isOwner
            ? [
                {
                    title: 'PROFILE_TAB_OWNER',
                    link: '/profile/owner',
                    notification: notifications.ownerNeedHelp + notifications.ownerMy,
                    full: true,
                },
                { title: 'PROFILE_TAB_OWNER', link: '/profile/owner/my', hide: true },
                { title: 'PROFILE_TAB_OWNER', link: '/profile/owner/all', hide: true },
            ]
            : []),
    ]), [notifications]);
    const pageTitle = useMemo(() => {
        var _a;
        return (_a = [...tabs].reverse()
            .find(({ link }) => pathname.replace(languageMask, '') === link)) === null || _a === void 0 ? void 0 : _a.title;
    }, [pathname]);
    const calcSidebarWidth = useCallback(() => containerRef.current
        && setSidebarWidth(parseFloat(getComputedStyle(containerRef.current).marginLeft) + 270), []);
    useEffect(() => {
        addResizeListener(calcSidebarWidth);
        return () => removeResizeListener(calcSidebarWidth);
    }, []);
    useLayoutSSR(() => {
        calcSidebarWidth();
    }, []);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null, pageTitle && (React.createElement("title", null, i18n.t(pageTitle || '')))),
        React.createElement("div", { className: style.wrapper },
            React.createElement("div", { className: style.tab__bg, style: vars(['--width', `${sidebarWidth}px`]) }),
            React.createElement(Container, { ref: containerRef, className: style.container },
                React.createElement("div", { className: style.tab__wrapper },
                    tabs.map(({ title, link, notification = 0, hide, full }) => (React.createElement("div", { key: link, className: cn(style.tab, full && style.tab__full), hidden: hide },
                        React.createElement(TabLink, { to: makeUrl(link), exact: true },
                            i18n.t(title),
                            notification !== 0 && (React.createElement("div", { className: style.notification }, notification)))))),
                    React.createElement("div", { className: style.tab },
                        React.createElement(Tab, { onClick: logout, disabled: true }, i18n.t('PROFILE_TAB_LOGOUT')))),
                React.createElement("div", { className: style.content },
                    React.createElement("div", { className: style.content__title }, i18n.t(pageTitle || '')),
                    React.createElement(Switch, null, renderRoutes(routes)))))));
};
export default observer(ProfileTemplate);
