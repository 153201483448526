import React from 'react';
import { useParams } from 'react-router-dom';
import { PendingSwitch, renderRoutes } from 'libs/router';
import { preventScroll } from 'ui/_nav';
import { useLanguage } from 'hooks';
import { TabLink } from 'ui/atoms';
import style from './style.module.scss';
const CheatCardTabs = ({ routes, settings }) => {
    const { i18n } = useLanguage();
    const { gameSlug, cheatSlug } = useParams();
    const baseUrl = `/cheat/${gameSlug}/${cheatSlug}`;
    return (React.createElement("div", null,
        React.createElement("div", { className: style.tabs, "data-fixed": !settings.reviewsLink },
            React.createElement(TabLink, { to: baseUrl, exact: true, className: style.tab }, i18n.t('CHEAT_TAB_FUNCTIONS')),
            React.createElement(TabLink, { to: `${baseUrl}/requirements`, className: style.tab }, i18n.t('CHEAT_TAB_REQUIREMENTS')),
            settings.reviewsLink && (React.createElement(TabLink, { to: settings.reviewsLink, className: style.tab }, i18n.t('CHEAT_TAB_REVIEWS')))),
        React.createElement(PendingSwitch, { routes: routes, preventScroll: preventScroll }, renderRoutes(routes))));
};
export default CheatCardTabs;
