import React, { useCallback } from 'react';
import { FormProvider } from 'react-hook-form';
import { FieldText } from 'ui/molecules';
import { Button, Icon } from 'ui/atoms';
import { useHookForm, useLanguage } from 'hooks';
import { colors } from 'ui/theme';
import { SOCIAL_ICONS } from 'utils/constants';
import { errorMsg, validateRequired } from 'utils/validate';
import style from './style.module.scss';
const INITIAL_VALUES = {
    login: '',
    password: '',
};
const SignInForm = ({ isLoading, loginAction }) => {
    const { i18n, language } = useLanguage();
    const form = useHookForm({
        defaultValues: INITIAL_VALUES,
        resolver: values => {
            const { login, password } = values;
            const errors = {};
            if (validateRequired(login)) {
                errors.login = errorMsg(i18n.t('VALIDATE_REQUIRED'));
            }
            if (validateRequired(password)) {
                errors.password = errorMsg(i18n.t('VALIDATE_REQUIRED'));
            }
            return {
                values,
                errors,
            };
        },
    });
    const handleSubmit = useCallback(async (values) => {
        const error = await loginAction(values);
        if (error) {
            form.setError('password', errorMsg(i18n.t(error === 'invalid-password'
                ? 'SIGN_IN_INVALID_CREDENTIALS'
                : 'SIGN_IN_TOO_MANY_REQUESTS')));
        }
    }, [language]);
    const onSubmit = form.handleSubmit(handleSubmit);
    return (React.createElement(FormProvider, Object.assign({}, form),
        React.createElement("form", { className: style.wrapper, onSubmit: onSubmit },
            React.createElement(FieldText, { size: "medium", name: "login", autoComplete: "off", placeholder: i18n.t('SIGN_IN_LOGIN_PLACEHOLDER') }),
            React.createElement(FieldText, { size: "medium", type: "password", name: "password", placeholder: i18n.t('SIGN_IN_PASSWORD_PLACEHOLDER') }),
            React.createElement("div", { className: style.social__wrapper }, SOCIAL_ICONS.map(({ icon, link }) => (React.createElement("a", { key: icon, href: link, className: style.social },
                React.createElement(Icon, { name: icon, width: "28", height: "28", fill: colors.backgroundGrayLight }))))),
            React.createElement(Button, { onClick: onSubmit, theme: "outline-gradient", className: style.btn, disabled: isLoading }, i18n.t('SIGN_IN_SUBMIT')))));
};
export default SignInForm;
