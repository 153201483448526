export const DRIVERS = [
    {
        image: {
            jpeg: '/public/images/faq/MicrosoftVisualC.jpg',
            webp: '/public/images/faq/MicrosoftVisualC.webp',
        },
        category: 'FAQ_ITEM_COMPONENTS_TITLE',
        name: 'Microsoft Visual\nC++ 2005-2019',
        link: 'https://www.techpowerup.com/download/visual-c-redistributable-runtime-package-all-in-one',
    },
    {
        image: {
            jpeg: '/public/images/faq/DirectX.jpg',
            webp: '/public/images/faq/DirectX.webp',
        },
        category: 'FAQ_ITEM_COMPONENTS_TITLE',
        name: 'Directx 12',
        link: 'https://download.microsoft.com/download/1/7/1/1718CCC4-6315-4D8E-9543-8E28A4E18C4C/dxwebsetup.exe',
    },
    {
        image: {
            jpeg: '/public/images/faq/MicrosoftNetFramework.jpg',
            webp: '/public/images/faq/MicrosoftNetFramework.webp',
        },
        category: 'FAQ_ITEM_COMPONENTS_TITLE',
        name: 'Microsoft Net\nFramework 4.7.1',
        link: 'https://www.microsoft.com/ru-ru/download/details.aspx?id=56480',
    },
    {
        image: {
            jpeg: '/public/images/faq/NVIDIA.jpg',
            webp: '/public/images/faq/NVIDIA.webp',
        },
        category: 'FAQ_ITEM_VIDEODRIVER_TITLE',
        name: 'NVIDIA',
        link: 'https://www.nvidia.ru/Download/index.aspx?lang=ru',
    },
    {
        image: {
            jpeg: '/public/images/faq/AMD.jpg',
            webp: '/public/images/faq/AMD.webp',
        },
        category: 'FAQ_ITEM_VIDEODRIVER_TITLE',
        name: 'AMD',
        link: 'https://www.amd.com/ru/support',
    },
    {
        image: {
            jpeg: '/public/images/faq/DriverPack.jpg',
            webp: '/public/images/faq/DriverPack.webp',
        },
        category: 'FAQ_ITEM_ALLDRIVERS_TITLE',
        name: 'DriverPack',
        link: 'http://dl.drp.su/17-online/DriverPack-17-Online.exe',
    },
];
export const DISABLE_SECURITY = [
    {
        image: {
            jpeg: '/public/images/faq/SmartScreen.jpg',
            webp: '/public/images/faq/SmartScreen.webp',
        },
        name: 'FAQ_ITEM_SMARTSCREEN_TITLE',
        link: 'https://www.youtube.com/watch?v=HxlxS1Mdyp0',
    },
    {
        image: {
            jpeg: '/public/images/faq/DefenderControl.jpg',
            webp: '/public/images/faq/DefenderControl.webp',
        },
        name: 'FAQ_ITEM_DEFENDER_CONTROL_TITLE',
        link: 'https://www.youtube.com/watch?v=qrq_gu_zwHE',
    },
    {
        image: {
            jpeg: '/public/images/faq/UAC.jpg',
            webp: '/public/images/faq/UAC.webp',
        },
        name: 'FAQ_ITEM_UAC_TITLE',
        link: 'https://www.youtube.com/watch?v=Pb_ELJ_0ESY',
    },
    {
        image: {
            jpeg: '/public/images/faq/SecureBoot.jpg',
            webp: '/public/images/faq/SecureBoot.webp',
        },
        name: 'FAQ_ITEM_SECURE_BOOT_TITLE',
        link: 'https://www.youtube.com/watch?v=RjwT85icNHg',
    },
];
export const OTHER = [
    {
        image: {
            jpeg: '/public/images/faq/WinRar.jpg',
            webp: '/public/images/faq/WinRar.webp',
        },
        name: 'FAQ_ITEM_WINRAR_TITLE',
        link: 'https://winrar-fansite.com/go/?https://rarlab.com/rar/winrar-x64-570ru.exe',
    },
    {
        image: {
            jpeg: '/public/images/faq/LightShot.jpg',
            webp: '/public/images/faq/LightShot.webp',
        },
        name: 'FAQ_ITEM_SCREENS_TITLE',
        link: 'https://app.prntscr.com/build/setup-lightshot.exe',
    },
    {
        image: {
            jpeg: '/public/images/faq/Teamviewer.jpg',
            webp: '/public/images/faq/Teamviewer.webp',
        },
        name: 'FAQ_ITEM_TEAMVIEWER_TITLE',
        link: 'https://www.teamviewer.com',
    },
    {
        image: {
            jpeg: '/public/images/faq/AnyDesk.jpg',
            webp: '/public/images/faq/AnyDesk.webp',
        },
        name: 'FAQ_ITEM_ANYDESK_TITLE',
        link: 'https://anydesk.com/ru',
    },
];
