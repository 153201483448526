var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { useForm, } from 'react-hook-form';
import { useLanguage } from './useLanguage';
import { useLayoutSSR } from './useLayoutSSR';
import { useUpdate } from './useUpdate';
export const useHookForm = (_a = {}) => {
    var { mode = 'all' } = _a, rest = __rest(_a, ["mode"]);
    const form = useForm(Object.assign({ mode }, rest));
    const { language } = useLanguage();
    useUpdate(() => {
        for (const name of Object.keys(form.formState.errors)) {
            form.trigger([name]);
        }
    }, [language], true);
    useLayoutSSR(() => {
        if (rest.defaultValues) {
            form.reset(rest.defaultValues);
        }
    }, [rest.defaultValues]);
    return form;
};
