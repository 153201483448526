import React, { useEffect } from 'react';
import { observer } from 'mobx-react-lite';
import { TicketsTemplate } from 'ui/templates';
import { useSocket, useStore } from 'hooks';
const Tickets = () => {
    const { socket, useAfterConnect } = useSocket();
    const { ticketsStore } = useStore();
    const createTicketAction = ticketsStore.createTicket;
    const subsribeSendTicketListAction = ticketsStore.subsribeSendTicketList;
    const subsribeSendMessagesAction = ticketsStore.subsribeSendMessages;
    const subsribeParticialUpdateAction = ticketsStore.subsribeParticialUpdate;
    const tickets = ticketsStore.allList;
    const groupedMessages = ticketsStore.groupedMessages;
    useEffect(() => {
        if (socket) {
            socket.on('send-ticket-list', subsribeSendTicketListAction);
            socket.on('send-ticket-messages', subsribeSendMessagesAction);
            socket.on('partial-update-ticket', subsribeParticialUpdateAction);
            return () => {
                socket.off('send-ticket-list');
                socket.off('send-ticket-messages');
                socket.off('partial-update-ticket');
            };
        }
    }, [socket]);
    useAfterConnect(ws => ws.emit('request-ticket-list'));
    return (React.createElement(TicketsTemplate, { tickets: tickets, groupedMessages: groupedMessages, createTicketAction: createTicketAction }));
};
export default observer(Tickets);
