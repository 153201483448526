var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useCallback } from 'react';
import { useFormContext } from 'react-hook-form';
import { Select } from 'ui/atoms';
export const FieldSelect = (_a) => {
    var _b;
    var { name } = _a, rest = __rest(_a, ["name"]);
    const { setValue, trigger, watch, formState } = useFormContext();
    const value = watch(name);
    const error = (_b = formState.errors[name]) === null || _b === void 0 ? void 0 : _b.message;
    const onChange = useCallback((value) => {
        setValue(name, value);
        trigger(name);
    }, []);
    return (React.createElement(Select, Object.assign({ value: value, error: error, onChange: onChange }, rest)));
};
