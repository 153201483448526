import React from 'react';
import { Icon, Price, ButtonLink, DiscountPercent, GradientBorder, } from 'ui/atoms';
import { useLanguage, useMobile } from 'hooks';
import { colors } from 'ui/theme';
import { observer } from 'mobx-react-lite';
import style from './style.module.scss';
const SOCIAL_NETWORKS = [
    {
        icon: 'telegram',
        setting: 'telegramFooter',
    },
    {
        icon: 'discord',
        setting: 'discordFooter',
    },
    {
        icon: 'vk',
        setting: 'vkFooter',
    },
    {
        icon: 'forum',
        setting: 'customerChat',
    },
];
export const Details = observer(({ game, animation, settings, onNext, onPrev, }) => {
    var _a, _b, _c, _d, _e;
    const { i18n, makeUrl } = useLanguage();
    const { isAboveTablet } = useMobile();
    return (React.createElement("div", { className: style.details__wrapper },
        !isAboveTablet && (React.createElement(React.Fragment, null,
            React.createElement(Icon, { name: "arrow-left", width: "17", height: "17", stroke: colors.backgroundGrayLight, className: style['details__control-left'], onClick: onPrev }),
            React.createElement(Icon, { name: "arrow-right", width: "17", height: "17", stroke: colors.backgroundGrayLight, className: style['details__control-right'], onClick: onNext }))),
        React.createElement(Icon, { "data-icon": "crosshair1", name: "crosshair", width: "46", height: "46", strokeWidth: "2", stroke: colors.primary }),
        React.createElement(Icon, { "data-icon": "crosshair2", name: "crosshair", width: "76", height: "76", stroke: colors.primary }),
        React.createElement("div", { className: style['social-networks'] }, SOCIAL_NETWORKS.map(({ icon, setting }) => (React.createElement("a", { key: setting, href: settings[setting], target: "_blank", rel: "noreferrer", className: style['social-network'] },
            React.createElement(GradientBorder, { size: "extra-small", idPrefix: icon, viewBox: "0 0 42 42", rx: "5.5" }),
            React.createElement(Icon, { name: icon, fill: colors.primary, width: "26", height: "26" }))))),
        React.createElement("div", { className: style.game__details, "data-animation": animation },
            React.createElement("div", { className: style.game__title }, game.name),
            React.createElement("div", { className: style.game__description },
                React.createElement("p", null, i18n.t('HOME_SLIDER_SUBTITLE')),
                React.createElement("div", { className: style['game__price-wrapper'] },
                    React.createElement(Price, { from: !((_a = game.minPrice) === null || _a === void 0 ? void 0 : _a.discountPrice), amount: ((_b = game.minPrice) === null || _b === void 0 ? void 0 : _b.discountPrice) || ((_c = game.minPrice) === null || _c === void 0 ? void 0 : _c.price), currency: (_d = game.minPrice) === null || _d === void 0 ? void 0 : _d.currency, className: style.game__price }),
                    ((_e = game.minPrice) === null || _e === void 0 ? void 0 : _e.discountPrice) && (React.createElement(React.Fragment, null,
                        !isAboveTablet && (React.createElement(DiscountPercent, { discountPrice: game.minPrice.discountPrice, originalPrice: game.minPrice.price, className: style['game__price-percent'] })),
                        React.createElement(Price, { amount: game.minPrice.price, currency: game.minPrice.currency, type: "discount" }))))),
            React.createElement(ButtonLink, { to: makeUrl(`/game/${game.slug}`), theme: "solid-gradient" }, i18n.t('BUY')))));
});
