import { useCallback, useState } from 'react';
export const useInput = (initilaValue = '') => {
    const [value, setValue] = useState(initilaValue);
    const onChange = useCallback(event => setValue(typeof event === 'object' && event !== null && 'target' in event
        ? event.target.value
        : event), []);
    return {
        props: {
            value,
            onChange,
        },
    };
};
