import React, { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react-lite';
import { StatusesTemplate } from 'ui/templates';
import { getUrlParam } from 'utils/selectors';
import { useLanguage, useStore } from 'hooks';
const Statuses = ({ location }) => {
    const { i18n } = useLanguage();
    const { cheatsStore } = useStore();
    const fetchStatusesListAction = cheatsStore.fetchStatusesList;
    const searchStatusesListAction = cheatsStore.searchStatusesList;
    const search = useMemo(() => getUrlParam(location.search, 'search'), [location.search]);
    const statuses = cheatsStore.statusesList;
    const statusesPagination = cheatsStore.statusesPagination;
    const searchList = cheatsStore.searchList;
    const searchPagination = cheatsStore.searchPagination;
    const isLoading = cheatsStore.pending;
    const loadMoreAction = useCallback(() => search
        ? searchStatusesListAction(true)
        : fetchStatusesListAction(true), [search]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, i18n.t(search
                ? 'SEARCH_RESULT_LOWER_TITLE'
                : 'STATUSES_TITLE').replace('#search#', search))),
        React.createElement(StatusesTemplate, { search: search, statuses: search
                ? searchList
                : statuses, isLoading: isLoading, isShowLoadMore: search
                ? !!searchPagination.nextPage
                : !!statusesPagination.nextPage, loadMoreAction: loadMoreAction })));
};
export const preload = ({ store, location }) => location.search
    ? store.cheatsStore.searchStatusesList()
    : store.cheatsStore.fetchStatusesList();
export default observer(Statuses);
