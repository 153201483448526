var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import { useStore } from 'hooks';
import RedirectRoute from './RedirectRoute';
const RoleRoute = (_a) => {
    var { roles } = _a, props = __rest(_a, ["roles"]);
    const { authStore } = useStore();
    const user = authStore.data;
    return (React.createElement(RedirectRoute, { routeProps: props, redirect: "/", condition: roles.includes((user || {}).role) }));
};
export default RoleRoute;
