import React from 'react';
import { useController } from 'react-hook-form';
import { isHasError } from 'utils/validate';
import { Checkbox } from 'ui/atoms';
export const FieldCheckbox = ({ name, className, children }) => {
    const { field, fieldState, formState } = useController({
        name,
    });
    const error = isHasError(fieldState, formState);
    return (React.createElement(Checkbox, Object.assign({}, field, { checked: field.value, onClick: () => field.onChange(!field.value), error: !!error, className: className }), children));
};
