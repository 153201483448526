import React, { useRef, useState, useCallback, } from 'react';
import cn from 'classnames';
import { observer } from 'mobx-react-lite';
import { useLocation } from 'react-router-dom';
import { useLanguage, useListener, useMobile, useStore, useUpdate, } from 'hooks';
import { getWindowRef } from 'utils/selectors';
import SignInForm from 'forms/SignInForm';
import SignUpForm from 'forms/SignUpForm';
import style from './style.module.scss';
const AuthModal = ({ isVisible, toggleVisible }) => {
    const { authStore } = useStore();
    const { isAboveTablet } = useMobile();
    const { i18n } = useLanguage();
    const { pathname } = useLocation();
    const wrapperRef = useRef(null);
    const mountTimer = useRef(null);
    const [isMounted, setIsMounted] = useState(isAboveTablet
        ? false
        : isVisible);
    const [activeTab, setActiveTab] = useState('signIn');
    const isLoading = authStore.pending;
    const loginAction = authStore.login;
    const registerAction = authStore.register;
    const handleMissClick = useCallback((event) => isAboveTablet
        && isVisible
        && wrapperRef.current
        && !wrapperRef.current.contains(event.target)
        && toggleVisible(), [isVisible, isAboveTablet]);
    useListener('click', getWindowRef(), handleMissClick);
    useUpdate(() => {
        if (isVisible) {
            toggleVisible();
        }
    }, [pathname]);
    const openSignInTab = useCallback(() => setActiveTab('signIn'), []);
    const openSignUpTab = useCallback(() => setActiveTab('signUp'), []);
    useUpdate(() => {
        if (isVisible) {
            mountTimer.current && clearTimeout(mountTimer.current);
            setIsMounted(true);
        }
        else {
            mountTimer.current = setTimeout(() => setIsMounted(false), 600);
        }
    }, [isVisible]);
    if (!isMounted) {
        return null;
    }
    return (React.createElement("div", { ref: wrapperRef, className: cn(style.wrapper, {
            [style.wrapper_open]: isVisible,
        }) },
        React.createElement("div", { className: style.tab__wrapper },
            React.createElement("div", { className: style.tab, "data-active": activeTab === 'signUp', onClick: openSignUpTab }, i18n.t('AUTH_MODAL_SIGN_UP')),
            React.createElement("div", { className: style.tab, "data-active": activeTab === 'signIn', onClick: openSignInTab }, i18n.t('AUTH_MODAL_SIGN_IN'))),
        activeTab === 'signIn'
            ? (React.createElement(SignInForm, { loginAction: loginAction, isLoading: isLoading }))
            : (React.createElement(SignUpForm, { registerAction: registerAction, isLoading: isLoading }))));
};
export default observer(AuthModal);
