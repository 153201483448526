import Warranty from './Warranty';
import FAQ from './FAQ';
import AboutUs from './AboutUs';
export const routes = [
    {
        path: '/warranty',
        component: Warranty,
    },
    {
        path: '/faq',
        component: FAQ,
    },
    {
        path: '/:type(privacy-policy|term-of-use|payment-and-delivery|contacts|personal|offer-agreement)',
        component: AboutUs,
    },
];
