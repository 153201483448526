import React from 'react';
import { useLanguage } from 'hooks';
import { Container } from 'ui/atoms';
import { TechSupportFooter } from 'ui/molecules';
import { dirtionaries } from './dictionaries';
import style from './style.module.scss';
const AboutUsTemplate = ({ type, redirectToTechSupportAction }) => {
    const { language } = useLanguage();
    const isContactPage = type === 'contacts';
    const isPaymentAndDelivery = type === 'payment-and-delivery';
    const prefix = type.toUpperCase().replace(/-/g, '_');
    const texts = dirtionaries[language];
    return (React.createElement("div", { className: style.wrapper, "data-type": type },
        React.createElement(Container, { className: style.container },
            React.createElement("h1", { className: style.title }, texts[`${prefix}_TITLE`]),
            React.createElement("div", { className: style.content, "data-type": type, dangerouslySetInnerHTML: {
                    __html: texts[`${prefix}_CONTENT`],
                } }),
            isPaymentAndDelivery && (React.createElement("div", { className: style['payment-systems'] },
                React.createElement("a", { href: "https://b2pay.io", target: "_blank", rel: "noreferrer" },
                    React.createElement("img", { src: "/public/images/payment-systems/b2pay.png", alt: "B2Pay", draggable: "false" })),
                React.createElement("img", { src: "/public/images/payment-systems/visa.png", alt: "Visa", draggable: "false" }),
                React.createElement("img", { src: "/public/images/payment-systems/mastercard.png", alt: "MasterCard", draggable: "false" }),
                React.createElement("img", { src: "/public/images/payment-systems/mir.png", alt: "MIR", draggable: "false" }))),
            isContactPage && (React.createElement(TechSupportFooter, { redirectToTechSupportAction: redirectToTechSupportAction })))));
};
export default AboutUsTemplate;
