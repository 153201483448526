import { matchPath, Router } from 'react-router';
const matchRoute = (routes, pathname) => {
    for (const route of routes) {
        const match = route.path
            ? matchPath(pathname, route)
            : Router.computeRootMatch(pathname);
        if (match) {
            return {
                match,
                route,
            };
        }
    }
};
export default matchRoute;
