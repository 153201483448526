import React, { useRef, useState, useCallback, } from 'react';
import cn from 'classnames';
import { useListener, useLanguage } from 'hooks';
import { getDocumentRef, containFiles } from 'utils/selectors';
import style from './style.module.scss';
export const DropZone = ({ className, onDrop }) => {
    const { i18n } = useLanguage();
    const dragTimerRef = useRef(null);
    const [isDragVisible, setDragVisible] = useState(false);
    const [isDragOver, setDragOver] = useState(false);
    const handleWindowDragOver = useCallback((e) => {
        e.preventDefault();
        if (containFiles(e)) {
            dragTimerRef.current && clearTimeout(dragTimerRef.current);
            setDragVisible(true);
        }
    }, []);
    const handleWindowDragLeave = useCallback((e) => {
        e.preventDefault();
        if (containFiles(e)) {
            dragTimerRef.current = setTimeout(() => setDragVisible(false), 50);
        }
    }, []);
    const handleWindowDropPrevent = useCallback((event) => {
        event.preventDefault();
        setDragVisible(false);
    }, []);
    useListener('dragover', getDocumentRef(), handleWindowDragOver);
    useListener('dragleave', getDocumentRef(), handleWindowDragLeave);
    useListener('drop', getDocumentRef(), handleWindowDropPrevent);
    const handleDragEnter = useCallback(() => setDragOver(true), []);
    const handleDragLeave = useCallback(() => setDragOver(false), []);
    const handleDrop = useCallback((event) => {
        event.preventDefault();
        onDrop([...event.dataTransfer.files]);
    }, []);
    return isDragVisible
        ? (React.createElement("div", { className: cn(style.dropzone, isDragOver && style['dropzone-over'], className), onDragEnter: handleDragEnter, onDragLeave: handleDragLeave, onDrop: handleDrop }, i18n.t('TICKET_DROP_ZONE_TITLE')))
        : null;
};
