import { action, computed, makeObservable, observable, } from 'mobx';
import http, { createSearch } from 'utils/http';
import { catchError } from 'utils/catchError';
export class AccountsStore {
    constructor(store) {
        Object.defineProperty(this, "store", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "data", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "allList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "pagination", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: {
                nextPage: null,
            }
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "fetchPending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => this.pending = true
        });
        Object.defineProperty(this, "fetchAllSuccessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: ({ list, nextPage }) => {
                this.pending = false;
                this.allList = list;
                this.pagination = {
                    nextPage,
                };
            }
        });
        Object.defineProperty(this, "fetchFailed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (error) => {
                this.pending = false;
                this.error = error || null;
                this.pagination = {
                    nextPage: null,
                };
            }
        });
        Object.defineProperty(this, "fetchSuccessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (account) => {
                this.pending = false;
                this.data = account;
            }
        });
        Object.defineProperty(this, "fetchAccountsList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (isLoadMore = false) => {
                const { allList } = this;
                const { nextPage } = this.pagination;
                if (allList.length && (!isLoadMore || nextPage === null))
                    return;
                this.fetchPending();
                try {
                    const search = createSearch({
                        page: nextPage && isLoadMore ? nextPage : 1,
                    });
                    const res = await http.get(`/accounts/home${search}`);
                    this.fetchAllSuccessed({
                        list: isLoadMore
                            ? [...allList, ...res.list]
                            : res.list,
                        nextPage: res.nextPage,
                    });
                }
                catch (e) {
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                }
            }
        });
        Object.defineProperty(this, "fetchAccount", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (slug) => {
                var _a;
                if (((_a = this.data) === null || _a === void 0 ? void 0 : _a.slug) === slug)
                    return;
                this.fetchPending();
                try {
                    const { account } = await http.get(`/accounts/slug/${slug}`);
                    this.fetchSuccessed(account);
                }
                catch (e) {
                    if (e.status === 404) {
                        this.store.setStatus(404);
                    }
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                }
            }
        });
        this.store = store;
        makeObservable(this, {
            pending: observable,
            data: observable,
            allList: observable,
            pagination: observable,
            error: observable,
            allI18nList: computed,
            dataI18n: computed,
            fetchPending: action.bound,
            fetchAllSuccessed: action.bound,
            fetchFailed: action.bound,
            fetchSuccessed: action.bound,
            fetchAccountsList: action,
            fetchAccount: action,
        });
    }
    get allI18nList() {
        return this.allList.map(account => (Object.assign(Object.assign({}, account), { minPrice: account.minPrice[this.store.commonStore.language], localization: undefined })));
    }
    get dataI18n() {
        if (!this.data)
            return this.data;
        return Object.assign(Object.assign({}, this.data), { localization: this.data.localization[this.store.commonStore.language], minPrice: undefined });
    }
}
