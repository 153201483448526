import React, { useCallback, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useLanguage } from 'hooks';
import { ProductHeader } from 'ui/molecules';
import { CheatCardDetail, InstructionModal } from 'ui/organisms';
const CheatTemplate = ({ cheat, routes, settings, recommendations }) => {
    const { pathname } = useLocation();
    const { i18n } = useLanguage();
    const [isInstructionVisible, setIsInstructionVisible] = useState(false);
    const isRequirementsPage = pathname.endsWith('/requirements');
    const handleToggleInstruction = useCallback(() => setIsInstructionVisible(old => !old), []);
    return (React.createElement(React.Fragment, null,
        isInstructionVisible && (React.createElement(InstructionModal, { onClose: handleToggleInstruction, video: settings.buyingInstructionVideo })),
        React.createElement(ProductHeader, { alt: cheat.name, jpeg: cheat.backgroundJPEG, webp: cheat.backgroundWebP, title: i18n.t(isRequirementsPage
                ? 'CHEAT_REQUIREMENTS_TITLE'
                : 'CHEAT_TITLE').replace('#cheat#', cheat.name), subTitle: i18n.t('CHEAT_SUBTITLE').replace('#game#', cheat.game.name) }),
        React.createElement(CheatCardDetail, { cheat: cheat, routes: routes, settings: settings, recommendations: recommendations, toggleInstructionModal: handleToggleInstruction })));
};
export default CheatTemplate;
