import React, { useMemo, useState, useCallback, useRef, } from 'react';
import cn from 'classnames';
import { useListener } from 'hooks';
import { colors } from 'ui/theme';
import { getWindowRef } from 'utils/selectors';
import { Icon } from '../Icon';
import style from './style.module.scss';
export const Select = ({ placeholder, className, options, value, error, onChange, }) => {
    const wrapperRef = useRef(null);
    const [isOpen, setIsOpen] = useState(false);
    const toggleIsOpen = useCallback(() => setIsOpen(old => !old), []);
    const option = useMemo(() => options.find(option => option.value === value), [options, value]);
    const handleMissClick = useCallback((event) => isOpen
        && wrapperRef.current
        && !wrapperRef.current.contains(event.target)
        && setIsOpen(false), [isOpen]);
    useListener('click', getWindowRef(), handleMissClick);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: cn(style.wrapper, className), ref: wrapperRef, onClick: toggleIsOpen, "data-open": isOpen, "data-error": !!error },
            React.createElement("div", { className: style.select },
                React.createElement("div", { className: style.select__active }, (option === null || option === void 0 ? void 0 : option.label) || placeholder),
                React.createElement("div", { className: style.select__icon, "data-open": isOpen },
                    React.createElement(Icon, { name: "arrow-light-down", stroke: colors.yellow, width: "17", height: "9" }))),
            isOpen && (React.createElement("div", { className: style.dropdown }, options.map(option => (React.createElement("div", { key: option.value, className: style.dropdown__option, onClick: () => onChange === null || onChange === void 0 ? void 0 : onChange(option.value) },
                React.createElement("span", { className: style['dropdown__option-text'] }, option.label))))))),
        error && (React.createElement("div", { className: style.error }, error))));
};
