var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef, } from 'react';
import { useMobile } from 'hooks';
import { SendButton, Controls } from './components';
import style from './style.module.scss';
export const MessagerTextarea = forwardRef((_a, ref) => {
    var { id, placeholder, maxLetters = 500, underTextarea, value, files, error, textareaDisabled, controlsDisabled, onFilesChange, pasteFiles, removeFile, onClick } = _a, rest = __rest(_a, ["id", "placeholder", "maxLetters", "underTextarea", "value", "files", "error", "textareaDisabled", "controlsDisabled", "onFilesChange", "pasteFiles", "removeFile", "onClick"]);
    const { isMobile } = useMobile();
    return (React.createElement("div", { className: style.wrapper },
        isMobile && (React.createElement(Controls, { maxLetters: maxLetters, minLetters: value.length, underTextarea: underTextarea, error: error, files: files, removeFile: removeFile })),
        React.createElement("div", { className: style.textarea__wrapper },
            React.createElement("textarea", Object.assign({ className: style.textarea, placeholder: placeholder, disabled: textareaDisabled, defaultValue: value }, rest, { ref: ref, onPaste: pasteFiles })),
            !isMobile
                ? (React.createElement(Controls, { maxLetters: maxLetters, minLetters: value.length, underTextarea: underTextarea, error: error, files: files, removeFile: removeFile }))
                : (React.createElement(SendButton, { id: id, disabled: controlsDisabled, onFilesChange: onFilesChange, onClick: onClick }))),
        !isMobile && (React.createElement(SendButton, { id: id, disabled: controlsDisabled, onFilesChange: onFilesChange, onClick: onClick }))));
});
MessagerTextarea.displayName = 'MessagerTextarea';
