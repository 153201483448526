import React, { Fragment, memo, } from 'react';
import { useLanguage, useMobile } from 'hooks';
import { Icon } from 'ui/atoms';
import { colors } from 'ui/theme';
import style from './style.module.scss';
const ADVANTAGES_LIST = [
    {
        text: 'ADVANTAGES_MONEY_OFF',
        icon: 'money-off',
    },
    {
        text: 'ADVANTAGES_SUPPORT',
        icon: 'forum',
    },
    {
        text: 'ADVANTAGES_PAY_SYSTEM',
        icon: 'shopping-basket',
    },
    {
        text: 'ADVANTAGES_COMMUNITY',
        icon: 'supervisor-account',
    },
    {
        text: 'ADVANTAGES_BIG_CHOICE',
        icon: 'contacts-book',
    },
];
const AdvantagesComponent = () => {
    const { isAboveTablet } = useMobile();
    const { i18n } = useLanguage();
    const OverflowWrapper = isAboveTablet
        ? Fragment
        : 'div';
    return (React.createElement(React.Fragment, null,
        React.createElement("svg", { className: "invisible-block" },
            React.createElement("linearGradient", { id: "primary-linear-gradient", x1: "32.55", x2: "32.55", y1: "8.13", y2: "56.88", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: colors.primary }),
                React.createElement("stop", { offset: "1", stopColor: colors.orange2 }))),
        React.createElement("svg", { id: "advantages-gradient-border", className: style['original-svg'], viewBox: "0 0 170 189", fill: "none", xmlns: "http://www.w3.org/2000/svg", preserveAspectRatio: "none" },
            React.createElement("rect", { x: "1", y: "1", rx: "19", stroke: "url(#advantages-gradient-light)", strokeWidth: "2" }),
            React.createElement("defs", null,
                React.createElement("linearGradient", { id: "advantages-gradient-light", x1: "176", y1: "193.5", x2: "-5.49999", y2: "-6.50001", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: colors.primaryLighten2 }),
                    React.createElement("stop", { offset: "1", stopColor: colors.orange, stopOpacity: "0" })))),
        React.createElement("div", { className: style.advantages__wrapper },
            React.createElement(OverflowWrapper, Object.assign({}, (isAboveTablet
                ? {}
                : { className: style['advantages__overflow-wrapper'] })), ADVANTAGES_LIST.map(({ text, icon }) => (React.createElement("div", { key: icon, className: style.advantage__item },
                React.createElement("svg", { className: style.advantage__svg },
                    React.createElement("use", { xlinkHref: "#advantages-gradient-border" })),
                React.createElement(Icon, { name: icon, fill: "url(#primary-linear-gradient)", width: isAboveTablet ? '65' : '50', height: isAboveTablet ? '65' : '50' }),
                React.createElement("div", { "data-name": "description" }, i18n.t(text)))))))));
};
export const Advantages = memo(AdvantagesComponent);
