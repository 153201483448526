import React, { useCallback, useRef, useEffect, } from 'react';
import { observer } from 'mobx-react-lite';
import { Container, LoadMoreButton, PageDescription, } from 'ui/atoms';
import { ProductCard } from 'ui/molecules';
import SimpleSearchForm from 'forms/SimpleSearchForm';
import { useLanguage, useMobile, } from 'hooks';
import style from './style.module.scss';
const BOTTOM_OFFSET = 200;
const BOTTOM_PADDING = 140;
const GamesCatalog = ({ search = null, games, isLoading, description, isShowLoadMore, loadMoreAction, children, }) => {
    const frameRef = useRef(0);
    const indicatorRef = useRef(null);
    const wrapperRef = useRef(null);
    const footerRef = useRef(null);
    const { isDesktop, isMobile } = useMobile();
    const { i18n } = useLanguage();
    const handleScrollIndicator = useCallback(() => {
        const { current: wrapper } = wrapperRef;
        const { current: footer } = footerRef;
        const { current: indicator } = indicatorRef;
        if (wrapper && footer && isDesktop && indicator) {
            const offsetTop = wrapper.getBoundingClientRect().top + window.scrollY - BOTTOM_OFFSET;
            const scrollTop = window.scrollY < offsetTop
                ? 0
                : window.scrollY - offsetTop;
            const footerHeight = footer.clientHeight + BOTTOM_PADDING;
            const scrollProgress = (scrollTop / (wrapper.clientHeight - footerHeight));
            const progress = scrollProgress > 1
                ? 1
                : scrollProgress;
            const percentOffset = progress * footerHeight;
            const height = scrollTop + percentOffset;
            indicator.style.setProperty('--after-height', `${wrapper.clientHeight < height
                ? wrapper.clientHeight
                : height}px`);
        }
        frameRef.current = requestAnimationFrame(handleScrollIndicator);
    }, []);
    useEffect(() => {
        frameRef.current = requestAnimationFrame(handleScrollIndicator);
        return () => cancelAnimationFrame(frameRef.current);
    }, []);
    return (React.createElement("div", { id: "games-catalog", className: style.background },
        children,
        React.createElement(Container, { className: style.container },
            isDesktop && (React.createElement(SimpleSearchForm, null)),
            React.createElement("div", { ref: wrapperRef, className: style.grid__wrapper },
                React.createElement("div", { ref: indicatorRef, className: style.grid__scroll }),
                (search && !games.length) && (React.createElement("div", { className: style['not-found'] }, i18n.t('SEARCH_NOT_FOUND'))),
                React.createElement("div", { className: style.grid }, games.map(game => (React.createElement(ProductCard, { key: game.id, previewJPEG: isMobile
                        ? game.previewVerticalJPEG
                        : game.previewHorizontalJPEG, previewWebP: isMobile
                        ? game.previewVerticalWebP
                        : game.previewHorizontalWebP, title: game.name, to: `/game/${game.slug}`, minPrice: game.minPrice, type: "game" }))))),
            React.createElement("div", { ref: footerRef },
                isShowLoadMore && games.length && (React.createElement(LoadMoreButton, { className: style['load-more'], onClick: loadMoreAction, disabled: isLoading }, i18n.t('SHOW_MORE'))),
                React.createElement(PageDescription, { html: description })))));
};
export default observer(GamesCatalog);
