import React, { useCallback, useRef, useState, useEffect, } from 'react';
import cn from 'classnames';
import { useLayoutSSR, useResizeObserver, } from 'hooks';
import { positiveOrZero } from 'utils/selectors';
import ScrollBar from './ScrollBar';
import style from './style.module.scss';
export const ScrollArea = ({ containerClassName, containerHeight, direction = 'normal', visible = true, children, }) => {
    const frameRef = useRef(0);
    const scrollBarRef = useRef(null);
    const containerRef = useRef(null);
    const contentRef = useRef(null);
    const [isVisible, setVisible] = useState(true);
    const containerStyle = visible
        ? {
            maxHeight: containerHeight,
        }
        : {};
    const handleScroll = useCallback(() => {
        if (contentRef.current && containerRef.current && visible) {
            const realHeightUnit = contentRef.current.scrollHeight / 100;
            const realContainerUnit = containerRef.current.scrollHeight / 100;
            const scrolled = Math.abs(contentRef.current.scrollTop);
            const newScrollBarOffset = (scrolled / realHeightUnit) * realContainerUnit;
            if (scrollBarRef.current) {
                const offsetPrefix = direction === 'normal' ? '' : '-';
                scrollBarRef.current.style.transform = `translateY(${offsetPrefix}${positiveOrZero(newScrollBarOffset)}px)`;
            }
        }
        frameRef.current = requestAnimationFrame(handleScroll);
    }, [visible, direction]);
    const computeSizes = useCallback(() => {
        if (contentRef.current && containerRef.current) {
            setVisible(visible
                ? containerHeight < contentRef.current.scrollHeight
                : false);
            const realHeightUnit = contentRef.current.scrollHeight / 100;
            const realContainerHeight = containerRef.current.scrollHeight;
            const realContainerUnit = containerRef.current.scrollHeight / 100;
            const newScrollBarHeight = (realContainerHeight / realHeightUnit) * realContainerUnit;
            if (scrollBarRef.current) {
                scrollBarRef.current.style.height = `${positiveOrZero(newScrollBarHeight - 24)}px`;
            }
        }
    }, [containerHeight, visible]);
    useLayoutSSR(computeSizes, []);
    useResizeObserver(computeSizes, containerRef);
    useEffect(() => {
        frameRef.current = requestAnimationFrame(handleScroll);
        return () => cancelAnimationFrame(frameRef.current);
    }, [handleScroll]);
    useEffect(() => setVisible(visible), [visible]);
    return (React.createElement("div", { ref: containerRef, className: cn('scrollbar', style.wrapper, containerClassName) },
        React.createElement("div", { ref: contentRef, className: style.content__wrapper, style: containerStyle, "data-direction": direction }, children),
        isVisible && (React.createElement(ScrollBar, { ref: scrollBarRef, direction: direction }))));
};
