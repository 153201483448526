import React, { useMemo } from 'react';
import format from 'date-fns/format';
import parseISO from 'date-fns/parseISO';
import { useLanguage, useRole } from 'hooks';
import { colors, ticketStatus, ticketSubject } from 'ui/theme';
import { Icon } from 'ui/atoms';
import style from './style.module.scss';
export const TicketHeader = ({ createdAt, subject, isOpen, closed, unread, authorId, lastSenderId, needOwnerHelp, toggleIsOpen, }) => {
    const { user } = useRole();
    const { locale, i18n } = useLanguage();
    const { date, subjectText } = useMemo(() => ({
        date: format(parseISO(createdAt), 'dd MMMM yyyy - HH:mm', { locale }),
        subjectText: i18n.t(ticketSubject.find(({ value }) => subject === value).label),
    }), [locale]);
    const status = useMemo(() => ticketStatus({
        closed,
        unread,
        lastSenderId,
        needOwnerHelp: needOwnerHelp && user.id !== authorId,
        userId: user.id,
    }), [closed, unread, lastSenderId, needOwnerHelp]);
    return (React.createElement("div", { className: style.wrapper, onClick: toggleIsOpen, "data-open": isOpen },
        React.createElement("div", { className: style['wrapper__col-1'] }, date),
        React.createElement("div", { className: style['wrapper__col-2'], "data-subject": subject }, subjectText),
        React.createElement("div", { className: style['wrapper__col-3'], "data-status": status.status },
            i18n.t(status.label),
            unread !== 0 && (React.createElement("div", { className: style['wrapper__unread'] }, unread)),
            React.createElement("div", { className: style.wrapper__arrow, "data-open": isOpen },
                React.createElement(Icon, { name: "arrow-rounded-down", width: "20", height: "9", fill: colors.yellow })))));
};
