import React, { createContext, useCallback, useState, } from 'react';
import ru from 'date-fns/locale/ru';
import en from 'date-fns/locale/en-US';
import { Language } from 'utils/constants';
import { useStore } from 'hooks';
import { observer } from 'mobx-react-lite';
export const LanguageContext = createContext({});
const locales = {
    ru,
    en,
};
const LanguageProvider = ({ initialI18n, children, }) => {
    const { commonStore } = useStore();
    const [state, setState] = useState({ i18n: initialI18n });
    const changeLanguageAction = commonStore.changeLanguage;
    const language = commonStore.language;
    const changeLanguage = useCallback(async (newLang) => {
        await state.i18n.changeLanguage(newLang);
        setState(Object.assign({}, state));
        changeLanguageAction(newLang);
    }, []);
    const makeUrl = useCallback(url => {
        const langPrefix = language === Language.RU
            ? ''
            : `/${Language.EN}`;
        return `${langPrefix}${url === '/'
            ? ''
            : url}`;
    }, [language]);
    return (React.createElement(LanguageContext.Provider, { value: {
            i18n: state.i18n,
            locale: locales[language],
            language,
            makeUrl,
            changeLanguage,
        } }, children));
};
export default observer(LanguageProvider);
