import React, { useMemo } from 'react';
import cn from 'classnames';
import style from './style.module.scss';
export const DiscountPercent = ({ originalPrice, discountPrice, className, }) => {
    const percent = useMemo(() => {
        const pricePercentValue = originalPrice / 100;
        return Math.round((discountPrice / pricePercentValue) - 100).toFixed(0);
    }, [originalPrice, discountPrice]);
    return (React.createElement("div", { className: cn(style.wrapper, className) },
        percent,
        "%"));
};
