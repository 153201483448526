import React, { useEffect } from 'react';
import { useSocket, useStore } from 'hooks';
import { OwnerTicketsTemplate } from 'ui/templates';
import { observer } from 'mobx-react-lite';
const OwnerMy = () => {
    const { socket, useAfterConnect } = useSocket();
    const { ticketsStore } = useStore();
    const subsribeSendTicketListAction = ticketsStore.subsribeSendTicketList;
    const blockUserByTicketAction = ticketsStore.blockUserByTicket;
    const tickets = ticketsStore.lastUpdatedList;
    const groupedMessages = ticketsStore.groupedMessages;
    useEffect(() => {
        if (socket) {
            socket.on('send-owner-my-tickets', subsribeSendTicketListAction);
            return () => {
                socket.off('send-owner-my-tickets');
            };
        }
    }, [socket]);
    useAfterConnect(ws => ws.emit('request-owner-my-tickets'));
    return (React.createElement(OwnerTicketsTemplate, { tickets: tickets, groupedMessages: groupedMessages, messagerStatus: "close", blockUserByTicketAction: blockUserByTicketAction }));
};
export default observer(OwnerMy);
