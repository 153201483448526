import React from 'react';
import { useLanguage } from 'hooks';
import { ButtonLink, Container, GradientBorder, Image, } from 'ui/atoms';
import { TechSupportFooter } from 'ui/molecules';
import { observer } from 'mobx-react-lite';
import { DRIVERS, DISABLE_SECURITY, OTHER, } from './data';
import style from './style.module.scss';
const viewBox = '0 0 170 245';
const FAQTemplate = ({ redirectToTechSupportAction }) => {
    const { i18n } = useLanguage();
    return (React.createElement("div", { className: style.wrapper },
        React.createElement(Container, null,
            React.createElement("h1", { className: style.title }, i18n.t('FAQ_TITLE')),
            React.createElement("h4", { className: style.subtitle }, i18n.t('FAQ_SUBTITLE_1')),
            React.createElement("div", { className: style.grid }, DRIVERS.map(({ name, category, image, link }) => (React.createElement("div", { key: link, className: style.card },
                React.createElement(Image, { jpeg: image.jpeg, webp: image.webp, alt: name, className: style.card__image }),
                React.createElement("div", { className: style.card__subtitle }, i18n.t(category)),
                React.createElement("div", { className: style.card__title }, name),
                React.createElement(ButtonLink, { to: link, target: "_blank", theme: "solid-gradient", className: style.card__btn }, i18n.t('FAQ_DOWNLOAD')),
                React.createElement(GradientBorder, { size: "small", viewBox: viewBox, idPrefix: image.jpeg }))))),
            React.createElement("h4", { className: style.subtitle }, i18n.t('FAQ_SUBTITLE_2')),
            React.createElement("div", { className: style.grid, "data-security": "true" },
                DISABLE_SECURITY.map(({ name, image, link }) => (React.createElement("div", { key: link, className: style.card },
                    React.createElement(Image, { jpeg: image.jpeg, webp: image.webp, alt: name, className: style.card__image }),
                    React.createElement("div", { className: style.card__title }, i18n.t(name)),
                    React.createElement(ButtonLink, { to: link, target: "_blank", theme: "outline", className: style.card__btn, "data-secure": true }, i18n.t('FAQ_VIEW')),
                    React.createElement(GradientBorder, { size: "small", viewBox: viewBox, idPrefix: image.jpeg })))),
                React.createElement("div", { className: style.description }, i18n.t('FAQ_DESCRIPTION'))),
            React.createElement("h4", { className: style.subtitle }, i18n.t('FAQ_SUBTITLE_3')),
            React.createElement("div", { className: style.grid }, OTHER.map(({ name, image, link }) => (React.createElement("div", { key: link, className: style.card },
                React.createElement(Image, { jpeg: image.jpeg, webp: image.webp, alt: name, className: style.card__image }),
                React.createElement("div", { className: style.card__title }, i18n.t(name)),
                React.createElement(ButtonLink, { to: link, target: "_blank", theme: "solid-gradient", className: style.card__btn }, i18n.t('FAQ_DOWNLOAD')),
                React.createElement(GradientBorder, { size: "small", viewBox: viewBox, idPrefix: image.jpeg }))))),
            React.createElement(TechSupportFooter, { redirectToTechSupportAction: redirectToTechSupportAction }))));
};
export default observer(FAQTemplate);
