import React from 'react';
import { ProfileTabTemplate } from 'ui/templates';
import { useStore } from 'hooks';
import { observer } from 'mobx-react-lite';
const GeneralInformation = () => {
    const { authStore } = useStore();
    const user = authStore.data;
    return (React.createElement(ProfileTabTemplate, { user: user }));
};
export default observer(GeneralInformation);
