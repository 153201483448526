import React from 'react';
import cn from 'classnames';
import { SOCIAL_ICONS } from 'utils/constants';
import { Avatar, Icon } from 'ui/atoms';
import { useLanguage } from 'hooks';
import { trim } from 'utils/string';
import { colors } from 'ui/theme';
import { observer } from 'mobx-react-lite';
import style from './style.module.scss';
const ProfileTabTemplate = ({ user }) => {
    const { i18n } = useLanguage();
    return (React.createElement("div", { className: style.wrapper },
        React.createElement("div", { className: style.avatar },
            React.createElement(Avatar, { alt: user.login, size: "medium", jpeg: user.avatarJPEG, webp: user.avatarWebP, idPrefix: trim(user.login), width: "169", height: "169" })),
        React.createElement("div", { className: style.content },
            React.createElement("div", { className: style.content__login }, user.login),
            React.createElement("div", { className: style.content__linked }, i18n.t('PROFILE_LINKED_ACCOUNTS')),
            React.createElement("div", { className: style.content__socials }, SOCIAL_ICONS.map(({ icon, link }) => (React.createElement("a", { key: icon, href: link, className: cn(style.content__social, {
                    [style['content__social-clickable']]: !user[icon],
                }) },
                React.createElement(Icon, { name: icon, width: "28", height: "28", fill: colors.backgroundGrayLight }))))))));
};
export default observer(ProfileTabTemplate);
