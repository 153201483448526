import React, { useCallback, useMemo } from 'react';
import { FormProvider } from 'react-hook-form';
import { Language } from 'utils/constants';
import { FieldRadioPrices, FieldTermOfUse, OrderDetails, } from 'ui/molecules';
import { useLanguage, useHookForm } from 'hooks';
import { errorMsg } from 'utils/validate';
import { DiscountPercent } from 'ui/atoms';
import style from './style.module.scss';
const AccountOrderForm = ({ name, globalPrices, prices, isPublished, }) => {
    const { language } = useLanguage();
    const initialValues = useMemo(() => ({
        termOfUse: true,
        personalData: true,
        price: language === Language.RU
            ? prices[0]
            : globalPrices[0],
    }), [language]);
    const form = useHookForm({
        defaultValues: initialValues,
        resolver: values => {
            const errors = {};
            if (!values.termOfUse) {
                errors.termOfUse = errorMsg('true');
            }
            if (!values.personalData) {
                errors.personalData = errorMsg('true');
            }
            return {
                values,
                errors,
            };
        },
    });
    const handleSubmit = useCallback((values) => {
        if (values.price) {
            window.location.href = values.price.redirect;
        }
    }, []);
    const onSubmit = form.handleSubmit(handleSubmit);
    const price = form.watch('price');
    return (React.createElement(FormProvider, Object.assign({}, form),
        React.createElement("div", { className: style.wrapper },
            React.createElement("h5", { className: style.title }, name),
            React.createElement(FieldRadioPrices, { name: "price", options: language === Language.RU
                    ? prices
                    : globalPrices, disabled: !isPublished }),
            React.createElement(OrderDetails, { price: price, disabled: !price || !isPublished, onSubmit: onSubmit }),
            React.createElement(FieldTermOfUse, null),
            (price === null || price === void 0 ? void 0 : price.discountPrice) && (React.createElement(DiscountPercent, { discountPrice: price.discountPrice, originalPrice: price.price, className: style.discount })))));
};
export default AccountOrderForm;
