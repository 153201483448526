import React, { memo } from 'react';
import { useParams } from 'react-router-dom';
import { StatusSingletoneTooltipProvider } from 'providers';
import { useLanguage, useUpdate } from 'hooks';
import { scrollToTop } from 'utils/functions';
import { ProductCard } from 'ui/molecules';
import style from './style.module.scss';
const MobileRecommendations = ({ recommendations }) => {
    const { gameSlug, cheatSlug } = useParams();
    const { i18n } = useLanguage();
    useUpdate(() => {
        scrollToTop();
    }, [cheatSlug], true);
    if (!recommendations.length) {
        return null;
    }
    return (React.createElement("div", { className: style.wrapper, "data-max-height": true },
        React.createElement("div", { className: style.title }, i18n.t('RECOMMENDATIONS')),
        React.createElement(StatusSingletoneTooltipProvider, null,
            React.createElement("div", { className: style.grid }, recommendations.map(({ id, name, slug, status, previewJPEG, previewWebP, minPrice, features, isPublished, }) => (React.createElement(ProductCard, { key: id, status: status, previewJPEG: previewJPEG, previewWebP: previewWebP, title: name, to: `/cheat/${gameSlug}/${slug}`, minPrice: minPrice, features: features, isPriceOutOfStock: !isPublished, type: "cheat" })))))));
};
export default memo(MobileRecommendations);
