import React from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { CheatTemplate, NotFoundTemplate } from 'ui/templates';
import { useStore } from 'hooks';
const Cheat = ({ routes }) => {
    const { store, cheatsStore, commonStore } = useStore();
    const setStatusAction = store.setStatus;
    const error = cheatsStore.error;
    const cheat = cheatsStore.selectData;
    const recommendations = cheatsStore.selectRecommendations;
    const settings = commonStore.settingsList;
    if (error) {
        return (React.createElement(NotFoundTemplate, { setStatusAction: setStatusAction }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, cheat.localization.pageTitle),
            React.createElement("meta", { name: "description", content: cheat.localization.pageDescription })),
        React.createElement(CheatTemplate, { cheat: cheat, routes: routes, settings: settings, recommendations: recommendations })));
};
export const preload = ({ store, match }) => {
    const { gameSlug, cheatSlug } = match.params;
    return Promise.all([
        store.cheatsStore.fetchCheat(gameSlug, cheatSlug),
        store.cheatsStore.fetchRecommendations(gameSlug),
    ]);
};
export default observer(Cheat);
