import React, { useCallback, useRef } from 'react';
import { useLanguage, useMount } from 'hooks';
import { observer } from 'mobx-react-lite';
import style from './style.module.scss';
const InstructionModal = ({ onClose, video }) => {
    const { i18n } = useLanguage();
    const wrapperRef = useRef(null);
    const onModalClose = useCallback(event => wrapperRef.current
        && !wrapperRef.current.contains(event.target)
        && onClose(), []);
    useMount(() => {
        document.body.classList.add('disable-scroll');
        return () => document.body.classList.remove('disable-scroll');
    });
    return (React.createElement("div", { onClick: onModalClose, className: style.blur },
        React.createElement("div", { className: style.dimmer },
            React.createElement("div", null,
                React.createElement("div", { ref: wrapperRef, className: style.wrapper },
                    React.createElement("div", { className: style.close, onClick: onClose }),
                    React.createElement("div", { className: style.title }, i18n.t('CHEAT_BUYING_ISTRUCTION')),
                    React.createElement("div", { className: style.description, dangerouslySetInnerHTML: {
                            __html: i18n.t('CHEAT_BUYING_DESCRIPTION'),
                        } }),
                    video && (React.createElement("div", { className: style.video, dangerouslySetInnerHTML: {
                            __html: video,
                        } })))))));
};
export default observer(InstructionModal);
