var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
export const defineRoutes = (config, prefix = '') => config.map((_a) => {
    var { path } = _a, route = __rest(_a, ["path"]);
    const item = Object.assign({ path: `${prefix}${path || ''}` }, route);
    if (item.routes) {
        item.routes = defineRoutes(item.routes, item.path);
    }
    return item;
});
