import { languageMask } from './constants';
export const isBrowser = typeof window !== 'undefined';
export const isDevelopment = process.env.NODE_ENV === 'development';
export const NOTIFICATION_REGISTED = 'notification-registed';
export const getNotificationRegisted = () => localStorage.getItem(NOTIFICATION_REGISTED);
export const setNotificationRegisted = (bool) => localStorage.setItem(NOTIFICATION_REGISTED, bool);
export const removeNotificationRegisted = () => localStorage.removeItem(NOTIFICATION_REGISTED);
export const AI_CHAT_SESSION = 'ai-chat-session';
export const getAIChatSession = () => localStorage.getItem(AI_CHAT_SESSION);
export const setAIChatSession = (session) => localStorage.setItem(AI_CHAT_SESSION, session);
export const removeAIChatSession = () => localStorage.removeItem(AI_CHAT_SESSION);
export const getWindow = () => isBrowser
    ? window
    : null;
export const getWindowRef = () => ({
    current: getWindow(),
});
export const getDocument = () => isBrowser
    ? document
    : null;
export const getDocumentRef = () => ({
    current: getDocument(),
});
export const setUrlParam = (params, param, value) => !value
    ? params.delete(param)
    : params.set(param, value);
export const getUrlParam = (search, param) => {
    const params = new URLSearchParams(search);
    return params.get(param);
};
export const removeUrlLang = (pathname) => pathname.replace(languageMask, '');
export const isNumber = (value) => typeof value === 'number';
export const isString = (value) => typeof value === 'string';
export const positiveOrZero = (value) => value < 0 ? 0 : value;
export const isNotNull = (value) => value !== null && typeof value !== 'undefined';
export const isNull = (value) => value === null;
export const isUUID = (value) => /^[0-9a-f]{8}-[0-9a-f]{4}-[0-5][0-9a-f]{3}-[089ab][0-9a-f]{3}-[0-9a-f]{12}$/i.test(value);
export const containFiles = (event) => {
    if (event.dataTransfer.types) {
        for (let i = 0; i < event.dataTransfer.types.length; i++) {
            if (event.dataTransfer.types[i] == 'Files') {
                return true;
            }
        }
    }
    return false;
};
