import React, { useMemo } from 'react';
import CheatOrderForm from 'forms/CheatOrderForm';
import { Container, Image } from 'ui/atoms';
import { HighlightCarousel, } from 'ui/molecules';
import { useMobile } from 'hooks';
import { Recommendations, MobileRecommendations, } from '../Recommendations';
import CheatCardTabs from '../CheatCardTabs';
import style from './style.module.scss';
const CheatCardDetail = ({ cheat, routes, settings, recommendations, toggleInstructionModal, }) => {
    var _a;
    const { isAboveTablet } = useMobile();
    const highlights = useMemo(() => [
        {
            id: 'default',
            type: 'image',
            src: {
                webp: cheat.backgroundWebP,
                jpeg: cheat.backgroundJPEG,
            },
        },
        ...cheat.videos.map(({ id, body }) => ({
            id,
            type: 'video',
            html: body,
        })),
        ...cheat.screenshots.map(({ id, previewJPEG, previewWebP }) => ({
            id,
            type: 'image',
            src: {
                webp: previewWebP,
                jpeg: previewJPEG,
            },
        })),
    ], [cheat]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement("div", { className: style.wrapper },
                React.createElement(HighlightCarousel, { highlights: highlights }),
                React.createElement(CheatOrderForm, { name: cheat.name, description: ((_a = cheat.localization) === null || _a === void 0 ? void 0 : _a.text) || '', isPublished: cheat.isPublished, prices: cheat.prices, globalPrices: cheat.globalPrices, status: cheat.status, toggleInstructionModal: toggleInstructionModal }, isAboveTablet && React.createElement(Recommendations, { recommendations: recommendations })),
                React.createElement(CheatCardTabs, { routes: routes, settings: settings }),
                !isAboveTablet && React.createElement(MobileRecommendations, { recommendations: recommendations }))),
        React.createElement("div", { className: style.spacer },
            React.createElement(Image, { alt: "CheatDesktopBackground", jpeg: "/public/images/backgrounds/CheatDesktopBackground.jpg", webp: "/public/images/backgrounds/CheatDesktopBackground.webp" }))));
};
export default CheatCardDetail;
