import React, { createContext, useEffect, useState, useCallback, } from 'react';
import { io } from 'socket.io-client';
import { useRole, useStore } from 'hooks';
export let WS;
export const SocketContext = createContext({});
const SocketProvider = ({ children }) => {
    const { ticketsStore } = useStore();
    const { isAuth, isTechSupport, isOwner } = useRole();
    const [socket, setSocket] = useState();
    const setupGlobalSubscriptions = useCallback((connection) => {
        connection.on('receive-message', ticketsStore.subsribeReceiveMessage);
        connection.on('send-notifications', ticketsStore.subsribeNotifications);
        connection.on('ticket-messages-readed', ticketsStore.subsribeTicketReaded);
        if (isTechSupport || isOwner) {
            connection.on('new-ticket', ticketsStore.subsribeNewTicket);
            connection.on('remove-ticket', ticketsStore.subsribeRemoveTicket);
            connection.on('redirect-to', ticketsStore.subsribeRedirectTo);
            connection.on('remove-in-work-ticket', ticketsStore.subsribeRemoveInWorkTicket);
        }
        if (isOwner) {
            connection.on('new-ticket-to-help', ticketsStore.subsribeNewTicketToHelp);
            connection.on('remove-owner-my-ticket', ticketsStore.subsribeRemoveOwnerTicket);
        }
    }, [isTechSupport, isOwner]);
    const destroyGlobalSubscriptions = useCallback((connection) => {
        connection.off('receive-message');
        connection.off('send-notifications');
        connection.off('ticket-messages-readed');
        if (isTechSupport || isOwner) {
            connection.off('new-ticket');
            connection.off('remove-ticket');
            connection.off('redirect-to');
            connection.off('remove-in-work-ticket');
        }
        if (isOwner) {
            connection.off('new-ticket-to-help');
            connection.off('remove-owner-my-ticket');
        }
    }, [isTechSupport, isOwner]);
    useEffect(() => {
        if (isAuth) {
            setSocket(old => {
                if (old === null || old === void 0 ? void 0 : old.connected) {
                    old.disconnect();
                }
                const connection = io({
                    path: '/api/tickets/socket.io',
                    reconnectionDelay: 300,
                    reconnectionDelayMax: 1000,
                });
                setupGlobalSubscriptions(connection);
                connection.on('connect', () => connection.emit('request-notifications'));
                WS = connection;
                return connection;
            });
        }
        else {
            if (socket) {
                destroyGlobalSubscriptions(socket);
                socket.off('connect');
                socket.disconnect();
            }
        }
    }, [isAuth]);
    return (React.createElement(SocketContext.Provider, { value: {
            socket,
        } }, children));
};
export default SocketProvider;
