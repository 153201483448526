var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React from 'react';
import cn from 'classnames';
import style from './style.module.scss';
export const btnClasses = (theme, className) => cn(style[`btn--${theme}`], style.btn, className);
export const Button = (_a) => {
    var { theme, className, children } = _a, rest = __rest(_a, ["theme", "className", "children"]);
    const classes = btnClasses(theme, className);
    return (React.createElement("button", Object.assign({ className: classes }, rest),
        children,
        theme === 'outline-gradient' && (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", className: style['btn__gradient-border'] },
            React.createElement("rect", { x: "1", y: "1", width: "calc(100% - 2px)", height: "calc(100% - 2px)", rx: "9", stroke: "url(#btn-outline-gradeint)", strokeWidth: "2", fill: "none" }),
            React.createElement("defs", null,
                React.createElement("linearGradient", { id: "btn-outline-gradeint", x1: "115.5", y1: "0", x2: "257", y2: "47", gradientUnits: "userSpaceOnUse" },
                    React.createElement("stop", { stopColor: "#FF7549" }),
                    React.createElement("stop", { offset: "1", stopColor: "#FF7549", stopOpacity: "0" })))))));
};
