import React from 'react';
import { useInput, useLanguage } from 'hooks';
import { TelegramGradientIcon } from 'ui/atoms/Icon/components/TelegramGradientIcon';
import style from './AIChatForm.module.scss';
const AIChatForm = ({ onSubmit, disabled }) => {
    const { i18n } = useLanguage();
    const { props: input } = useInput('');
    const handleSubmit = async (e) => {
        e.preventDefault();
        input.onChange('');
        await onSubmit(input.value);
    };
    return (React.createElement("form", { className: style.form, onSubmit: handleSubmit },
        React.createElement("input", Object.assign({}, input, { className: style.input, placeholder: i18n.t('AI_CHAT_INPUT_PLACEHOLDER') })),
        React.createElement("button", { type: "submit", className: style.btn, disabled: disabled },
            React.createElement(TelegramGradientIcon, { width: "11.5", height: "11" }))));
};
export default AIChatForm;
