import React, { useMemo, useRef, } from 'react';
import NProgress from 'nprogress';
import { Switch, useLocation, matchPath } from 'react-router-dom';
import { matchRoute } from 'libs/router';
import { languageMask } from 'utils/constants';
import { useForceUpdate, useLayoutSSR, usePrevious, useStore, } from 'hooks';
import { scrollToTop } from 'utils/functions';
export const PendingSwitch = ({ routes, preventScroll, children }) => {
    const location = useLocation();
    const [oldLocation, oldLocationRef] = usePrevious(location);
    const { store } = useStore();
    const forceUpdate = useForceUpdate();
    const normalizedPath = useMemo(() => {
        const pathWithoutLangPrefix = location.pathname.replace(languageMask, '');
        return pathWithoutLangPrefix === ''
            ? '/'
            : pathWithoutLangPrefix;
    }, [location.pathname]);
    const loadedPathRef = useRef(normalizedPath);
    useLayoutSSR(() => {
        var _a, _b;
        if ((loadedPathRef.current !== normalizedPath
            || ((_a = oldLocationRef.current) === null || _a === void 0 ? void 0 : _a.search) !== location.search)
            && routes) {
            const currentRoute = matchRoute(routes, location.pathname);
            const prevPath = loadedPathRef.current;
            const scroll = () => {
                if (!Array.isArray(preventScroll)) {
                    return scrollToTop();
                }
                for (const paths of preventScroll) {
                    const isMatchPrev = paths.map(path => !!matchPath(prevPath, { path })).includes(true);
                    const isMatchNext = paths.map(path => !!matchPath(normalizedPath, { path })).includes(true);
                    if (isMatchPrev && isMatchNext) {
                        return;
                    }
                }
                scrollToTop();
            };
            if (currentRoute === null || currentRoute === void 0 ? void 0 : currentRoute.route.preload) {
                loadedPathRef.current = normalizedPath;
                if (window.HYDRATED === true) {
                    NProgress.start();
                    (_b = currentRoute.route) === null || _b === void 0 ? void 0 : _b.preload({
                        match: currentRoute.match,
                        store,
                        location,
                    }).then(() => {
                        NProgress.done();
                        forceUpdate();
                        scroll();
                    }).catch(() => {
                        NProgress.done();
                        forceUpdate();
                        scroll();
                    });
                }
            }
            else {
                loadedPathRef.current = normalizedPath;
                forceUpdate();
                scroll();
            }
        }
    }, [normalizedPath, location.search]);
    return (React.createElement(Switch, { location: oldLocation || location }, children));
};
