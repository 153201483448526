import React from 'react';
import { Link } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import cn from 'classnames';
import { DiscountPercent, GradientBorder, Button, Image, Price, } from 'ui/atoms';
import { useLanguage, useMobile } from 'hooks';
import style from './style.module.scss';
const BestGameCardComponent = ({ slug, title, minPrice, className, previewWebP, previewJPEG, }) => {
    const { isMobile } = useMobile();
    const { makeUrl, i18n } = useLanguage();
    return (React.createElement(Link, { to: makeUrl(`/game/${slug}`), className: cn(style.wrapper, className), draggable: "false" },
        React.createElement("div", { className: style.image__wrapper },
            React.createElement("div", { className: style.image__position },
                React.createElement(Image, { webp: previewWebP, jpeg: previewJPEG, alt: title })),
            React.createElement(Button, { theme: "outline", className: style.image__btn }, i18n.t('MORE_DETAILS')),
            isMobile && (minPrice === null || minPrice === void 0 ? void 0 : minPrice.price) && (minPrice === null || minPrice === void 0 ? void 0 : minPrice.discountPrice)
                ? (React.createElement(DiscountPercent, { originalPrice: minPrice.price, discountPrice: minPrice.discountPrice, className: style['discount-price'] }))
                : null),
        React.createElement("div", { className: style.title }, title),
        React.createElement("div", { className: style.price },
            React.createElement(Price, { from: isMobile
                    ? !(minPrice === null || minPrice === void 0 ? void 0 : minPrice.discountPrice)
                    : true, amount: (minPrice === null || minPrice === void 0 ? void 0 : minPrice.discountPrice) || (minPrice === null || minPrice === void 0 ? void 0 : minPrice.price), currency: minPrice === null || minPrice === void 0 ? void 0 : minPrice.currency, className: style.price__min }),
            (minPrice === null || minPrice === void 0 ? void 0 : minPrice.discountPrice) && (React.createElement("div", { className: style['price__not-min'] },
                React.createElement(Price, { amount: minPrice === null || minPrice === void 0 ? void 0 : minPrice.price, currency: minPrice === null || minPrice === void 0 ? void 0 : minPrice.currency, type: "discount" }))),
            !isMobile && (minPrice === null || minPrice === void 0 ? void 0 : minPrice.price) && (minPrice === null || minPrice === void 0 ? void 0 : minPrice.discountPrice)
                ? (React.createElement(DiscountPercent, { originalPrice: minPrice.price, discountPrice: minPrice.discountPrice, className: style.price__percent }))
                : null),
        React.createElement(GradientBorder, { idPrefix: `best-${slug}`, size: "medium" })));
};
export const BestGameCard = observer(BestGameCardComponent);
