import React from 'react';
import { observer } from 'mobx-react-lite';
import { Container, LoadMoreButton } from 'ui/atoms';
import { ProductCard } from 'ui/molecules';
import { useLanguage } from 'hooks';
import style from './style.module.scss';
const AccountsCatalog = observer(({ accounts, isLoading, isShowLoadMore, loadMoreAction, }) => {
    const { i18n } = useLanguage();
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, { className: style.grid }, accounts.map(({ id, name, slug, previewVerticalJPEG, previewVerticalWebP, minPrice, isPublished, }) => (React.createElement(ProductCard, { key: id, previewJPEG: previewVerticalJPEG, previewWebP: previewVerticalWebP, title: name, to: `/account/${slug}`, minPrice: minPrice, isPriceOutOfStock: !isPublished, type: "account" })))),
        React.createElement(Container, { className: isShowLoadMore ? undefined : style.spacer }, isShowLoadMore && (React.createElement(LoadMoreButton, { onClick: loadMoreAction, disabled: isLoading }, i18n.t('SHOW_MORE'))))));
});
export default AccountsCatalog;
