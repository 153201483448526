import { makeObservable, action, computed, observable, } from 'mobx';
import http from 'utils/http';
import { catchError } from 'utils/catchError';
export class BestGamesStore {
    constructor(store) {
        Object.defineProperty(this, "store", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "allList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "fetchPending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => this.pending = true
        });
        Object.defineProperty(this, "fetchSuccessed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (list) => {
                this.pending = false;
                this.allList = list;
            }
        });
        Object.defineProperty(this, "fetchFailed", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (error) => {
                this.pending = false;
                this.error = error || null;
            }
        });
        Object.defineProperty(this, "fetchBestGamesList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                if (this.allList.length)
                    return;
                this.fetchPending();
                try {
                    const { data } = await http.get('/best-games/home');
                    this.fetchSuccessed(data.bestGames);
                }
                catch (e) {
                    const error = await catchError(e);
                    this.fetchFailed(error === null || error === void 0 ? void 0 : error.message);
                }
            }
        });
        this.store = store;
        makeObservable(this, {
            pending: observable,
            allList: observable,
            error: observable,
            allI18nList: computed,
            fetchPending: action.bound,
            fetchSuccessed: action.bound,
            fetchFailed: action.bound,
            fetchBestGamesList: action,
        });
    }
    get allI18nList() {
        return this.allList.map(bestGame => (Object.assign(Object.assign({}, bestGame), { game: Object.assign(Object.assign({}, bestGame.game), { minPrice: bestGame.game.minPrice[this.store.commonStore.language], localization: undefined, cheats: undefined }) })));
    }
}
