import { Subject } from 'utils/constants';
import { isNull } from 'utils/selectors';
export const colors = {
    primary: '#FF6B38',
    primaryOpacity27: 'rgba(255, 107, 56, .27)',
    primaryLighten: '#F5F5F5',
    primaryLighten2: '#FDA38A',
    orange: '#FF7549',
    orangeOpacity0: 'rgba(255, 117, 73, 0)',
    orange2: '#E77A0A',
    white: '#fff',
    gray: '#C4C4C4',
    gray2: '#B6B6B6',
    gray3: '#3A3A3A',
    yellow: '#FBB03B',
    backgroundGrayDisabled: '#4E4E4E',
    backgroundGrayLight: '#969696',
};
export const vars = (...args) => {
    const style = {};
    for (const [name, value] of args) {
        style[name] = value;
    }
    return style;
};
export const getStatus = (status) => {
    switch (status) {
        case 0:
            return {
                hint: 'STATUS_UNDETECTED_STAR',
            };
        case 1:
            return {
                hint: 'STATUS_UNDETECTED',
            };
        case 2:
            return {
                hint: 'STATUS_USE_AT_OWN_RISK',
            };
        case 3:
            return {
                hint: 'STATUS_UPDATING',
            };
        case 4:
            return {
                hint: 'STATUS_DETECTED',
            };
    }
};
export const ticketSubject = [
    {
        label: 'TICKET_SUBJECT_1',
        value: Subject.CheatProblem,
    },
    {
        label: 'TICKET_SUBJECT_2',
        value: Subject.PaymentProblem,
    },
    {
        label: 'TICKET_SUBJECT_3',
        value: Subject.AnotherQuestion,
    },
];
export const ticketStatus = ({ closed, unread, needOwnerHelp, lastSenderId, userId, }) => {
    if (!isNull(closed)) {
        return {
            label: 'TICKET_STATUS_CLOSED',
            status: 'close',
        };
    }
    if (needOwnerHelp) {
        return {
            label: 'TICKET_STATUS_NEED_HELP',
            status: 'help',
        };
    }
    if (unread !== 0) {
        return {
            label: 'TICKET_STATUS_NEW_MESSAGE',
            status: 'unread',
        };
    }
    if (unread !== 0) {
        return {
            label: 'TICKET_STATUS_NEW_MESSAGE',
            status: 'unread',
        };
    }
    if (lastSenderId !== userId) {
        return {
            label: 'TICKET_STATUS_WAIT',
            status: 'wait',
        };
    }
    return {
        label: 'TICKET_STATUS_REVIEW',
        status: 'review',
    };
};
export const playNotification = () => new Audio('/public/notification.mp3').play()
    .catch(() => console.error('reject notify sound'));
