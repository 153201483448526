import React from 'react';
import { Link } from 'react-router-dom';
import { StatusSingletoneTooltipProvider } from 'providers';
import { useLanguage } from 'hooks';
import { Button, Container, GradientBorder, Image, LoadMoreButton, } from 'ui/atoms';
import { CheatStatus } from './components';
import style from './style.module.scss';
const StatusesList = ({ search, statuses, isLoading, isShowLoadMore, loadMoreAction, }) => {
    const { i18n } = useLanguage();
    return (React.createElement("div", { className: style.wrapper },
        React.createElement(StatusSingletoneTooltipProvider, null,
            React.createElement(Container, null,
                search && statuses.length === 0
                    ? (React.createElement("div", { className: style['not-found'] }, i18n.t('SEARCH_NOT_FOUND')))
                    : (React.createElement("div", { className: style.grid }, statuses.map(({ id, name, slug, cheats, previewVerticalJPEG, previewVerticalWebP, }) => (React.createElement("div", { key: name, className: style.card },
                        React.createElement("div", { className: style['card__left-column'] },
                            React.createElement(Link, { to: `/game/${slug}`, className: style.image },
                                React.createElement(Image, { alt: name, jpeg: previewVerticalJPEG, webp: previewVerticalWebP }),
                                React.createElement(Button, { theme: "outline", className: style.image__btn }, i18n.t('MORE_DETAILS'))),
                            React.createElement("div", { className: style.card__title }, name)),
                        React.createElement("div", { className: style['card__right-column'] }, (cheats || []).map(cheat => (React.createElement(CheatStatus, { key: cheat.id, status: cheat.status, name: cheat.name, url: `/cheat/${slug}/${cheat.slug}` })))),
                        React.createElement(GradientBorder, { size: "large", idPrefix: id, viewBox: "0 0 570 275" })))))),
                isShowLoadMore && statuses.length && (React.createElement(LoadMoreButton, { className: style['load-more'], onClick: loadMoreAction, disabled: isLoading }, i18n.t('SHOW_MORE'))))),
        React.createElement("div", { className: style.footer },
            React.createElement(Image, { jpeg: "/public/images/backgrounds/StatusesDesktopBackground.jpg", webp: "/public/images/backgrounds/StatusesDesktopBackground.webp", alt: "footer" }))));
};
export default StatusesList;
