import React, { memo } from 'react';
const AILogo = () => (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "45px", height: "45px", fill: "none", viewBox: "0 0 45 45" },
    React.createElement("path", { fill: "url(#a)", d: "M24.97 7.191s.77 4.962 3.158 8.549c2.388 3.586 8.732 5.22 8.732 5.22L24.97 7.19Z" }),
    React.createElement("path", { fill: "url(#b)", d: "M24.01 6c.017.218-1.591 33.987-1.591 33.987l9.915-13.065s-3.954 3.073-5.76 1.585C24.765 27.017 24.01 6 24.01 6Z" }),
    React.createElement("path", { fill: "url(#c)", d: "M26.633 28.293s5.03.876 10.228-7.332c0 0-6.233-2.439-8.732-5.22l3.867 5.819-5.363 6.733Z" }),
    React.createElement("path", { fill: "url(#d)", d: "M22.419 39.987s2.74-5.608 2.552-9.91c-.205-4.672-.94-23.562-.94-23.562l-1.612 33.472Z" }),
    React.createElement("path", { fill: "url(#e)", d: "m31.996 21.56 4.865-.6s-5.247-1.818-8.732-5.22l3.867 5.82Z" }),
    React.createElement("path", { fill: "url(#f)", d: "M19.89 7.205s-.77 4.962-3.158 8.548C14.344 19.34 8 20.973 8 20.973L19.89 7.206Z" }),
    React.createElement("path", { fill: "url(#g)", d: "M20.848 6.013C20.83 6.23 22.44 40 22.44 40l-9.916-13.065s3.954 3.073 5.76 1.585c1.808-1.49 2.564-22.507 2.564-22.507Z" }),
    React.createElement("path", { fill: "url(#h)", d: "M18.228 28.306s-5.03.876-10.228-7.333c0 0 6.233-2.438 8.732-5.22l-3.867 5.82 5.363 6.733Z" }),
    React.createElement("path", { fill: "url(#i)", d: "M22.44 40s-2.74-5.608-2.552-9.91c.204-4.672.94-23.562.94-23.562L22.44 40Z" }),
    React.createElement("path", { fill: "url(#j)", d: "M12.865 21.573 8 20.973s5.248-1.818 8.732-5.22l-3.867 5.82Z" }),
    React.createElement("rect", { width: "44", height: "44", x: ".5", y: ".5", stroke: "url(#k)", rx: "22" }),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "a", x1: "24.971", x2: "36.86", y1: "14.075", y2: "14.075", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FCEE21" }),
            React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
        React.createElement("linearGradient", { id: "b", x1: "23.953", x2: "28.723", y1: "4.203", y2: "44.405", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#F7931E" }),
            React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
        React.createElement("linearGradient", { id: "c", x1: "26.704", x2: "34.226", y1: "30.124", y2: "15.038", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { offset: ".036", stopColor: "#F15A24" }),
            React.createElement("stop", { offset: ".494", stopColor: "#FBB03B" }),
            React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
        React.createElement("linearGradient", { id: "d", x1: "1057.33", x2: "1545.7", y1: "24278", y2: "24273.3", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#F7931E" }),
            React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
        React.createElement("linearGradient", { id: "e", x1: "35.812", x2: "32.242", y1: "26.084", y2: "17.874", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", null),
            React.createElement("stop", { offset: "1", stopColor: "#F15A24", stopOpacity: "0" })),
        React.createElement("linearGradient", { id: "f", x1: "-23836.6", x2: "-24819.1", y1: "9040.77", y2: "9040.77", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#FCEE21" }),
            React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
        React.createElement("linearGradient", { id: "g", x1: "-19771.2", x2: "-23093", y1: "19988.2", y2: "28212.9", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#F7931E" }),
            React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
        React.createElement("linearGradient", { id: "h", x1: "-20591.9", x2: "-21318.8", y1: "9663.28", y2: "8470.01", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { offset: ".036", stopColor: "#F15A24" }),
            React.createElement("stop", { offset: ".494", stopColor: "#FBB03B" }),
            React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
        React.createElement("linearGradient", { id: "i", x1: "-4794.16", x2: "-5282.52", y1: "24281.1", y2: "24276.4", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#F7931E" }),
            React.createElement("stop", { offset: "1", stopColor: "#F15A24" })),
        React.createElement("linearGradient", { id: "j", x1: "-18095.3", x2: "-17991.1", y1: "4316.46", y2: "3954.22", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", null),
            React.createElement("stop", { offset: "1", stopColor: "#F15A24", stopOpacity: "0" })),
        React.createElement("linearGradient", { id: "k", x1: "22.5", x2: "22.5", y1: "0", y2: "45", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#F7931E" }),
            React.createElement("stop", { offset: "1", stopOpacity: "0" })))));
export default memo(AILogo);
