var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { memo, useCallback, useRef, useState, } from 'react';
import cn from 'classnames';
import { Icon } from 'ui/atoms';
import { colors } from 'ui/theme';
import { useInput, useLanguage } from 'hooks';
import { SOCIAL_ICONS } from 'utils/constants';
import style from './style.module.scss';
const SimpleChangeLoginForm = (_a) => {
    var { login, changeLoginAction } = _a, rest = __rest(_a, ["login", "changeLoginAction"]);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const { i18n, language } = useLanguage();
    const inputRef = useRef(null);
    const { props: input } = useInput(login);
    const handleBlur = useCallback(async () => {
        if (isLoading)
            return;
        if (input.value !== login) {
            setError(null);
            setIsLoading(true);
            const success = await changeLoginAction(input.value);
            setIsLoading(false);
            if (!success) {
                setError(i18n.t('PERSONAL_CHANGE_LOGIN_ALREADY_EXISTS'));
            }
        }
    }, [login, input.value, isLoading, language]);
    const handleFocus = useCallback(() => { var _a; return (_a = inputRef.current) === null || _a === void 0 ? void 0 : _a.focus(); }, []);
    return (React.createElement("div", { className: style.wrapper },
        React.createElement("div", { className: style.input__wrapper },
            React.createElement("input", Object.assign({ ref: inputRef, className: style.input, onBlur: handleBlur, autoComplete: "off" }, input)),
            React.createElement("div", { className: style.input__icon, onClick: handleFocus },
                React.createElement(Icon, { name: "pencil", width: "13", height: "13", fill: colors.gray3 })),
            error && (React.createElement("div", { className: style.input__error }, error))),
        React.createElement("div", { className: style.social__wrapper },
            React.createElement("div", { className: style.social__title }, i18n.t('PROFILE_LINKED_ACCOUNTS')),
            React.createElement("div", { className: style.social__list }, SOCIAL_ICONS.map(({ icon, link }) => {
                const isLinked = rest[icon];
                return (React.createElement("a", { key: icon, href: link, onClick: event => isLinked && event.preventDefault(), className: cn(style.social__item, {
                        [style['social__item-active']]: isLinked,
                    }) },
                    React.createElement(Icon, { name: icon, width: "28", height: "28", fill: isLinked
                            ? colors.backgroundGrayLight
                            : colors.backgroundGrayDisabled })));
            })))));
};
export default memo(SimpleChangeLoginForm);
