import React from 'react';
import { Switch } from 'react-router-dom';
import { renderRoutes } from 'libs/router';
import { TabLink } from 'ui/atoms';
import { useLanguage } from 'hooks';
import style from './style.module.scss';
const TechSupportTemplate = ({ routes, notifications }) => {
    const { techSupportMy, techSupport } = notifications;
    const { i18n } = useLanguage();
    return (React.createElement("div", null,
        React.createElement("div", { className: style.tabs },
            React.createElement(TabLink, { to: "/profile/techsupport", exact: true },
                i18n.t('TICKET_STATUS_REVIEW'),
                techSupport !== 0 && (React.createElement("span", { className: style.notification }, techSupport))),
            React.createElement(TabLink, { to: '/profile/techsupport/my' },
                i18n.t('TECHSUPPORT_MY'),
                techSupportMy !== 0 && (React.createElement("span", { className: style.notification }, techSupportMy))),
            React.createElement(TabLink, { to: '/profile/techsupport/closed' }, i18n.t('TECHSUPPORT_ARHIVE'))),
        React.createElement(Switch, null, renderRoutes(routes))));
};
export default TechSupportTemplate;
