import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import { GradientBorder, Button, } from 'ui/atoms';
import { useLanguage } from 'hooks';
import style from './style.module.scss';
const ProblemCardComponent = ({ to, title, description, }) => {
    const { makeUrl, i18n } = useLanguage();
    return (React.createElement(Link, { to: makeUrl(to), className: style.wrapper, draggable: "false" },
        React.createElement("div", { className: style.image__wrapper },
            React.createElement(Button, { theme: "outline", className: style.image__btn }, i18n.t('MORE_DETAILS'))),
        React.createElement("div", { className: style.title }, title),
        React.createElement("div", { className: style.description }, description),
        React.createElement(GradientBorder, { idPrefix: to, viewBox: '' })));
};
export const ProblemCard = observer(ProblemCardComponent);
