import React from 'react';
import { observer } from 'mobx-react-lite';
import { useMobile } from 'hooks';
import style from '../style.module.scss';
import { FilesToUpload } from './FilesToUpload';
const ControlsComponent = ({ underTextarea, error, maxLetters, minLetters, value, files, removeFile, }) => {
    var _a, _b;
    const { isMobile } = useMobile();
    return (React.createElement("div", { className: style.textarea__counter }, isMobile
        ? (React.createElement(React.Fragment, null,
            underTextarea,
            React.createElement("div", { className: style['textarea__counter-group'] },
                React.createElement("div", { className: style.error }, error),
                React.createElement("div", null, (_a = value === null || value === void 0 ? void 0 : value.length) !== null && _a !== void 0 ? _a : (minLetters !== null && minLetters !== void 0 ? minLetters : 0),
                    "/",
                    maxLetters)),
            React.createElement(FilesToUpload, { files: files, removeFile: removeFile })))
        : (React.createElement(React.Fragment, null,
            React.createElement("div", { className: style.textarea__left },
                React.createElement("div", { className: style.error }, error),
                React.createElement(FilesToUpload, { files: files, removeFile: removeFile })),
            underTextarea,
            React.createElement("div", null, (_b = value === null || value === void 0 ? void 0 : value.length) !== null && _b !== void 0 ? _b : (minLetters !== null && minLetters !== void 0 ? minLetters : 0),
                "/",
                maxLetters)))));
};
export const Controls = observer(ControlsComponent);
