import { action, observable, runInAction, makeObservable, } from 'mobx';
import nProgress from 'nprogress';
import { getAIChatSession, removeAIChatSession, setAIChatSession } from 'utils/selectors';
import http from 'utils/http';
export class AIChatStore {
    constructor(store) {
        Object.defineProperty(this, "store", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "pending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: true
        });
        Object.defineProperty(this, "error", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "sending", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: false
        });
        Object.defineProperty(this, "allList", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: []
        });
        Object.defineProperty(this, "fetchAIChat", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async () => {
                nProgress.start();
                this.pending = true;
                const session = getAIChatSession();
                try {
                    const { list, sessionId } = await http.get(`/ai-chat/start${session ? `?sessionId=${session}` : ''}`);
                    setAIChatSession(sessionId);
                    runInAction(() => {
                        this.allList = list;
                        this.pending = false;
                    });
                }
                catch (_a) {
                    runInAction(() => {
                        this.pending = false;
                        removeAIChatSession();
                    });
                }
                finally {
                    nProgress.done();
                }
            }
        });
        Object.defineProperty(this, "sendMessage", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: async (text) => {
                this.error = false;
                this.sending = true;
                const sessionId = getAIChatSession();
                this.allList.unshift({
                    id: `id${Math.random().toString(16).slice(2)}`,
                    isBot: false,
                    text, sessionId,
                    createdAt: new Date().toISOString(),
                });
                try {
                    const { received } = await http.post('/ai-chat/send-message', { sessionId, text });
                    runInAction(() => {
                        this.sending = false;
                        this.allList.unshift(received);
                    });
                }
                catch (_a) {
                    runInAction(() => {
                        this.sending = false;
                        this.error = true;
                    });
                }
            }
        });
        this.store = store;
        makeObservable(this, {
            pending: observable,
            sending: observable,
            allList: observable,
            fetchAIChat: action,
            sendMessage: action,
        });
    }
    get hasMessages() {
        return this.allList.length !== 0;
    }
}
