var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { forwardRef } from 'react';
import cn from 'classnames';
import style from './style.module.scss';
export const Input = forwardRef((_a, ref) => {
    var { error, size = 'small', className } = _a, rest = __rest(_a, ["error", "size", "className"]);
    const isSmall = size === 'small';
    return (React.createElement("div", { className: cn(style.wrapper, className) },
        React.createElement("input", Object.assign({ ref: ref, className: cn(style.input, isSmall && style.input__small) }, rest)),
        error && (React.createElement("div", { className: cn(style.error, isSmall && style.error__small) }, error))));
});
Input.displayName = 'Input';
