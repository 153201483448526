import React from 'react';
import { observer } from 'mobx-react-lite';
import { Link } from 'react-router-dom';
import cn from 'classnames';
import { DiscountPercent, GradientBorder, Button, Image, Price, } from 'ui/atoms';
import { StatusLazyTooltip } from 'ui/molecules';
import { useLanguage, useMobile } from 'hooks';
import { isNumber } from 'utils/selectors';
import style from './style.module.scss';
const ProductCardComponent = ({ to, type, title, status, features, minPrice, className, previewWebP, previewJPEG, isPriceOutOfStock = false, }) => {
    const { isMobile } = useMobile();
    const { makeUrl, i18n } = useLanguage();
    return (React.createElement(Link, { to: makeUrl(to), className: cn(style.wrapper, className), draggable: "false", "data-type": type },
        React.createElement("div", { className: style.image__wrapper },
            React.createElement("div", { className: style.image__position },
                React.createElement(Image, { webp: previewWebP, jpeg: previewJPEG, alt: title })),
            React.createElement(Button, { theme: "outline", className: style.image__btn }, i18n.t('MORE_DETAILS')),
            isMobile && (minPrice === null || minPrice === void 0 ? void 0 : minPrice.price) && (minPrice === null || minPrice === void 0 ? void 0 : minPrice.discountPrice)
                ? (React.createElement(DiscountPercent, { originalPrice: minPrice.price, discountPrice: minPrice.discountPrice, className: style['discount-price'] }))
                : null),
        React.createElement("div", { className: style.title },
            title,
            isNumber(status) && (React.createElement(StatusLazyTooltip, { id: to, status: status }))),
        React.createElement("div", { className: style.price }, isPriceOutOfStock
            ? (React.createElement(Price, { className: style.price__min })) : (React.createElement(React.Fragment, null,
            React.createElement(Price, { from: isMobile
                    ? !(minPrice === null || minPrice === void 0 ? void 0 : minPrice.discountPrice)
                    : true, amount: (minPrice === null || minPrice === void 0 ? void 0 : minPrice.discountPrice) || (minPrice === null || minPrice === void 0 ? void 0 : minPrice.price), currency: minPrice === null || minPrice === void 0 ? void 0 : minPrice.currency, className: style.price__min }),
            (minPrice === null || minPrice === void 0 ? void 0 : minPrice.discountPrice) && (React.createElement(Price, { amount: minPrice === null || minPrice === void 0 ? void 0 : minPrice.price, currency: minPrice === null || minPrice === void 0 ? void 0 : minPrice.currency, className: style['price__not-min'], type: "discount" })),
            !isMobile && (minPrice === null || minPrice === void 0 ? void 0 : minPrice.price) && (minPrice === null || minPrice === void 0 ? void 0 : minPrice.discountPrice)
                ? (React.createElement(DiscountPercent, { originalPrice: minPrice.price, discountPrice: minPrice.discountPrice }))
                : null))),
        !isMobile && features && (React.createElement("div", { className: style.feature__wrapper }, features.map(feature => (React.createElement("div", { className: style.feature, key: feature }, feature))))),
        React.createElement(GradientBorder, { idPrefix: to, size: "medium" })));
};
export const ProductCard = observer(ProductCardComponent);
