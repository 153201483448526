import { useStore } from 'hooks';
import { observer } from 'mobx-react-lite';
import React from 'react';
import { FunctionsTemplate } from 'ui/templates';
const Functions = () => {
    var _a;
    const { cheatsStore } = useStore();
    const data = cheatsStore.selectData;
    return (React.createElement(FunctionsTemplate, { functions: ((_a = data.localization) === null || _a === void 0 ? void 0 : _a.functions) || '' }));
};
export default observer(Functions);
