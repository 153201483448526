import React from 'react';
import { colors } from 'ui/theme';
export const ImageGradientIcon = ({ width, height, idPrefix = '', disabled, className, }) => {
    const id = `messager-image-btn-${idPrefix}`;
    const id2 = `messager-image-btn-2-${idPrefix}`;
    return (React.createElement("svg", { width: width, height: height, xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 20 20", className: className },
        React.createElement("g", { clipPath: `url(#${id})` },
            React.createElement("path", { fill: `url(#${id2})`, d: "M18.56 2.22H1.26C.56 2.22 0 2.8 0 3.5v13.02c0 .7.56 1.27 1.26 1.27h17.3c.69 0 1.25-.57 1.25-1.27V3.49c0-.7-.56-1.27-1.25-1.27Zm0 1.27v9.24l-2.48-2.28a.94.94 0 0 0-1.3.03l-2.51 2.54-4.95-5.97a.94.94 0 0 0-1.44 0l-4.62 5.41V3.5h17.3ZM13.2 6.83c0-.97.77-1.75 1.73-1.75.95 0 1.73.78 1.73 1.75 0 .96-.78 1.74-1.73 1.74-.96 0-1.73-.78-1.73-1.74Z" })),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: id2, x1: "9.91", x2: "9.91", y1: "2.22", y2: "17.78", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: disabled
                        ? colors.gray3
                        : colors.orange }),
                React.createElement("stop", { offset: "1", stopColor: "#FBB03B" })),
            React.createElement("clipPath", { id: id },
                React.createElement("path", { fill: "#fff", d: "M0 0h19.81v20H0z" })))));
};
