import * as AccountsCatalog from './AccountsCatalog';
import * as Account from './Account';
export const routes = [
    {
        path: '/accounts',
        component: AccountsCatalog.default,
        preload: AccountsCatalog.preload,
    },
    {
        path: '/account/:slug',
        component: Account.default,
        preload: Account.preload,
    },
];
