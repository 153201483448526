import React from 'react';
import { Helmet } from 'react-helmet-async';
import { useLanguage } from 'hooks';
import { WarrantyTemplate } from 'ui/templates';
const Warranty = () => {
    const { i18n } = useLanguage();
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, i18n.t('WARRANTY_TITLE'))),
        React.createElement(WarrantyTemplate, null)));
};
export default Warranty;
