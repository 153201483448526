import { useLanguage } from 'hooks';
import React from 'react';
import { TicketsTable } from 'ui/organisms';
import style from './style.module.scss';
const OwnerTicketsTemplate = ({ tickets, groupedMessages, messagerStatus, allListHint, blockUserByTicketAction, }) => {
    const { i18n } = useLanguage();
    return (React.createElement(React.Fragment, null,
        allListHint && (React.createElement("div", { className: style.hint }, i18n.t('TECHSUPPORT_HINT_ALL_LIST'))),
        React.createElement("div", { className: style.wrapper },
            React.createElement(TicketsTable, { groupedMessages: groupedMessages, tickets: tickets, messagerStatus: messagerStatus, blockUserByTicketAction: blockUserByTicketAction }))));
};
export default OwnerTicketsTemplate;
