import React from 'react';
import { colors } from 'ui/theme';
export const TelegramGradientIcon = ({ width, height, idPrefix = '', disabled, className, }) => {
    const id = `messager-send-btn-${idPrefix}`;
    const id2 = `messager-send-btn-2-${idPrefix}`;
    return (React.createElement("svg", { width: width, height: height, xmlns: "http://www.w3.org/2000/svg", viewBox: "0 0 25 24", className: className },
        React.createElement("path", { fill: `url(#${id})`, d: "M24.75.4V.38a.98.98 0 00-1.09-.34C23.57.08 24.96-.52.65 9.9c-.36.16-.6.5-.65.92-.02.41.17.8.5 1.03l6.8 4.34 6.68-2.98-1.91 6.04 7.18 4.59c.3.2.67.2.96.07.31-.14.54-.44.6-.79L24.98 1.3c.06-.33-.04-.66-.23-.9z" }),
        React.createElement("path", { fill: `url(#${id2})`, d: "M7.3 16.19v6.72c0 .46.29.9.7 1.02a1 1 0 001.17-.37L24.75.4 7.3 16.19z" }),
        React.createElement("defs", null,
            React.createElement("linearGradient", { id: id, x1: "12.5", x2: "12.5", y1: "24", y2: "0", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: disabled
                        ? colors.gray3
                        : colors.orange }),
                React.createElement("stop", { offset: "1", stopColor: "#FBB03B" })),
            React.createElement("linearGradient", { id: id2, x1: "7.1", x2: "7.1", y1: "24", y2: "-13.24", gradientUnits: "userSpaceOnUse" },
                React.createElement("stop", { stopColor: "#FBB03B" }),
                React.createElement("stop", { offset: ".5", stopColor: "#EAF6FF" }),
                React.createElement("stop", { offset: "1", stopColor: "#EAF6FF" })))));
};
