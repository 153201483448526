import React from 'react';
import { Helmet } from 'react-helmet-async';
import { ButtonLink, Container } from 'ui/atoms';
import { useLanguage } from 'hooks';
import style from './style.module.scss';
const NotFoundTemplate = ({ status, setStatusAction }) => {
    const { i18n, makeUrl } = useLanguage();
    setStatusAction(status || 404);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, i18n.t('NOT_FOUND_TITLE'))),
        React.createElement(Container, { className: style.wrapper },
            React.createElement("div", { className: style.title }, "#404"),
            React.createElement("div", { className: style.description }, i18n.t('NOT_FOUND_TITLE')),
            React.createElement(ButtonLink, { to: makeUrl('/'), theme: "solid-gradient", className: style.btn }, i18n.t('NOT_FOUND_GO_HOME')))));
};
export default NotFoundTemplate;
