import { useEffect, useRef, } from 'react';
import { useLayoutSSR } from './useLayoutSSR';
export const useUpdate = (fn, deps = [], layout = false) => {
    const isMount = useRef(true);
    const effect = layout ? useLayoutSSR : useEffect;
    effect(() => {
        if (isMount.current) {
            isMount.current = false;
        }
        else {
            fn();
        }
    }, deps);
};
