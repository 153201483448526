import React from 'react';
import { Route } from 'react-router';
import { DataLoader } from './DataLoader';
import PrivateRoute from './PrivateRoute';
import RoleRoute from './RoleRoute';
export const renderRoutes = (routes, extraProps = {}) => routes
    ? routes.map((route, i) => {
        const renderPage = (props) => (React.createElement(DataLoader, { path: route.path, exact: route.exact }, route.render
            ? route.render(Object.assign(Object.assign(Object.assign({}, props), extraProps), { routes: route.routes }))
            : React.createElement(route.component, Object.assign({}, props, extraProps, { routes: route.routes }))));
        if (route.private) {
            return (React.createElement(PrivateRoute, { key: route.key || i, path: route.path, exact: route.exact, strict: route.strict, render: renderPage }));
        }
        if (Array.isArray(route.roles)) {
            return (React.createElement(RoleRoute, { key: route.key || i, path: route.path, exact: route.exact, roles: route.roles, strict: route.strict, render: renderPage }));
        }
        return (React.createElement(Route, { key: route.key || i, path: route.path, exact: route.exact, strict: route.strict, render: renderPage }));
    })
    : null;
