import React, { useCallback, useState } from 'react';
import { observer } from 'mobx-react-lite';
import loadable from '@loadable/component';
import cn from 'classnames';
import { useStore } from 'hooks';
import parentStyle from '../style.module.scss';
import style from './style.module.scss';
const ImageViewer = loadable(() => import('../../HighlightCarousel/components/ImageViewer'));
const FilesComponent = ({ text, files, ticketId, nickname, createdAt, isMyMessage, }) => {
    var _a;
    const { ticketsStore } = useStore();
    const [isOpen, setOpen] = useState(false);
    const [activeSlide, setActiveSlide] = useState(0);
    const images = ticketsStore.imagesById(ticketId);
    const handleOpen = useCallback((path) => {
        setActiveSlide(images.findIndex(image => image.path === path));
        setOpen(true);
    }, [images]);
    const handleClose = useCallback(() => setOpen(false), []);
    const isSingleImage = images.length === 1;
    const incrementedIndex = activeSlide + 1;
    const decrementedIndex = activeSlide - 1;
    const nextIndex = images.length > incrementedIndex
        ? incrementedIndex
        : 0;
    const prevIndex = 0 <= decrementedIndex
        ? decrementedIndex
        : images.length - 1;
    const handleNext = useCallback(() => setActiveSlide(nextIndex), [nextIndex]);
    const handlePrev = useCallback(() => setActiveSlide(prevIndex), [prevIndex]);
    const firstFile = (_a = files[0]) !== null && _a !== void 0 ? _a : {};
    return (React.createElement("div", { className: parentStyle.wrapper, "data-my-message": isMyMessage },
        React.createElement("div", { className: cn(style.file, text && style['file__with-text']), "data-my-message": isMyMessage },
            React.createElement("div", { className: style.nickname, "data-my-message": isMyMessage }, nickname),
            files.length === 1
                ? (React.createElement("img", { src: firstFile.path, width: firstFile.width, height: firstFile.height, onClick: () => handleOpen(firstFile.path) }))
                : (files.length === 2
                    ? (React.createElement("div", { className: cn(style.file__columns, style.file__two) }, files.map(({ path, width, height }) => (React.createElement("img", { src: path, key: path, width: width, height: height, onClick: () => handleOpen(path) })))))
                    : (React.createElement("div", { className: cn(style.file__grid, text
                            ? style['file__grid-rounded']
                            : style['file__grid-without-text']) },
                        React.createElement("img", { src: firstFile.path, width: firstFile.width, height: firstFile.height, onClick: () => handleOpen(firstFile.path) }),
                        React.createElement("div", { className: cn(style.file__columns, style.file__two) }, [...files].splice(1).map(({ path, width, height }) => (React.createElement("img", { src: path, key: path, width: width, height: height, onClick: () => handleOpen(path) }))))))),
            React.createElement("div", { className: cn(parentStyle.message__date, style.file__date, !text && style['file__date-bg']) }, createdAt),
            text && (React.createElement("div", { className: style.text }, text))),
        isOpen && (React.createElement(ImageViewer, { prevImage: isSingleImage
                ? undefined
                : images[prevIndex].path, activeImage: images[activeSlide].path, nextImage: isSingleImage
                ? undefined
                : images[nextIndex].path, onClose: handleClose, onNext: handleNext, onPrev: handlePrev }))));
};
export const Files = observer(FilesComponent);
