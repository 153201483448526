import React from 'react';
import { useMobile } from 'hooks';
import { TelegramGradientIcon } from 'ui/atoms';
import { CreateTicketForm } from 'forms/Tickets';
import { TicketsTable } from 'ui/organisms';
import { observer } from 'mobx-react-lite';
import style from './style.module.scss';
const TicketsTemplate = ({ tickets, groupedMessages, createTicketAction, }) => {
    const { isAboveTablet } = useMobile();
    return (React.createElement("div", { className: style.wrapper, "data-bg": !!tickets.length },
        isAboveTablet && (React.createElement(React.Fragment, null,
            React.createElement(TelegramGradientIcon, { width: "34", height: "39", idPrefix: "top-right", className: style['icon__top-right'] }),
            React.createElement(TelegramGradientIcon, { width: "112", height: "105", idPrefix: "bottom-right", className: style['icon__bottom-right'] }),
            React.createElement(TelegramGradientIcon, { width: "78", height: "78", idPrefix: "bottom-left", className: style['icon__bottom-left'] }))),
        React.createElement(CreateTicketForm, { createTicketAction: createTicketAction }),
        tickets.length !== 0 && (React.createElement(TicketsTable, { tickets: tickets, groupedMessages: groupedMessages }))));
};
export default observer(TicketsTemplate);
