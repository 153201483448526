import React from 'react';
import { useLanguage } from 'hooks';
import { Container } from 'ui/atoms';
import { getIcon } from './icons';
import style from './style.module.scss';
const ITEMS = [
    {
        title: 'WARRANTY_ITEM_TITLE_1',
        description: 'WARRANTY_ITEM_DESCRIPTION_1',
    },
    {
        title: 'WARRANTY_ITEM_TITLE_2',
        description: 'WARRANTY_ITEM_DESCRIPTION_2',
    },
    {
        title: 'WARRANTY_ITEM_TITLE_3',
        description: 'WARRANTY_ITEM_DESCRIPTION_3',
    },
    {
        title: 'WARRANTY_ITEM_TITLE_4',
        description: 'WARRANTY_ITEM_DESCRIPTION_4',
    },
    {
        title: 'WARRANTY_ITEM_TITLE_5',
        description: 'WARRANTY_ITEM_DESCRIPTION_5',
    },
    {
        title: 'WARRANTY_ITEM_TITLE_6',
        description: 'WARRANTY_ITEM_DESCRIPTION_6',
    },
    {
        title: 'WARRANTY_ITEM_TITLE_7',
        description: 'WARRANTY_ITEM_DESCRIPTION_7',
    },
];
const WarrantyTemplate = () => {
    const { i18n } = useLanguage();
    return (React.createElement("div", { className: style.wrapper },
        React.createElement(Container, null,
            React.createElement("h1", { className: style.title }, i18n.t('WARRANTY_TITLE')),
            React.createElement("div", { className: style.grid }, ITEMS.map(({ title, description }, index) => (React.createElement("div", { className: style.card, key: title },
                React.createElement("h5", { className: style.subtitle }, i18n.t(title)),
                React.createElement("div", { className: style.icon }, getIcon(index)),
                description === 'WARRANTY_ITEM_DESCRIPTION_7'
                    ? (React.createElement("p", { className: style.description, dangerouslySetInnerHTML: {
                            __html: i18n.t(description),
                        } }))
                    : (React.createElement("p", { className: style.description }, i18n.t(description))))))))));
};
export default WarrantyTemplate;
