import React, { useMemo } from 'react';
import { useLocation } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useLanguage, useRole, useStore } from 'hooks';
import { LanguageSwitcher, Container, Logo, Icon, ProfileShortcut, } from 'ui/atoms';
import { colors } from 'ui/theme';
import { navbarLinks } from 'ui/_nav';
import AuthModal from '../AuthModal';
import { NavbarItem, TechSupportHint, } from './components';
import style from './style.module.scss';
const Header = () => {
    const { commonStore, ticketsStore } = useStore();
    const { pathname } = useLocation();
    const { i18n } = useLanguage();
    const { isAuth, user } = useRole();
    const notifications = ticketsStore.countNotification;
    const isAuthVisible = commonStore.authModalVisible;
    const redirectToTechSupportAction = commonStore.redirectToTechSupport;
    const toggleAuthModalAction = commonStore.toggleAuthModal;
    const isTechHintVisible = useMemo(() => !pathname.includes('/profile'), [pathname]);
    return (React.createElement("div", { className: style.background },
        React.createElement(Container, { className: style.container },
            React.createElement(Logo, null),
            React.createElement("div", { className: style.navbar }, navbarLinks.map(({ text, to, subLinks }) => (React.createElement(NavbarItem, { key: text, to: to, text: text, subLinks: subLinks })))),
            React.createElement("div", { className: style['language-switcher'] },
                React.createElement(LanguageSwitcher, null)),
            isAuth
                ? (React.createElement(ProfileShortcut, { user: user, notifications: notifications }))
                : (React.createElement("div", { className: style['sign-in__btn'], onClick: toggleAuthModalAction },
                    React.createElement(Icon, { name: "sign-in", width: "17", height: "17", fill: colors.white }),
                    i18n.t('HEADER_LOGIN_BTN')))),
        isTechHintVisible && (React.createElement(TechSupportHint, { isAuth: isAuth, onClick: redirectToTechSupportAction })),
        React.createElement(AuthModal, { isVisible: isAuthVisible, toggleVisible: toggleAuthModalAction })));
};
export default observer(Header);
