import * as ProblemsCatalog from './ProblemsCatalog';
import * as Problem from './Problem';
export const routes = [
    {
        path: '/problems',
        component: ProblemsCatalog.default,
        preload: ProblemsCatalog.preload,
    },
    {
        path: '/problem/:slug',
        component: Problem.default,
        preload: Problem.preload,
    },
];
