import React from 'react';
import { Helmet } from 'react-helmet-async';
import { AccountTemplate, NotFoundTemplate, } from 'ui/templates';
import { useStore } from 'hooks';
import { observer } from 'mobx-react-lite';
const Account = () => {
    const { accountsStore, store } = useStore();
    const setStatusAction = store.setStatus;
    const error = accountsStore.error;
    const account = accountsStore.dataI18n;
    if (error) {
        return (React.createElement(NotFoundTemplate, { setStatusAction: setStatusAction }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, account.localization.headTitle),
            React.createElement("meta", { name: "description", content: account.localization.headDescription })),
        React.createElement(AccountTemplate, { account: account })));
};
export const preload = ({ store, match }) => store
    .accountsStore.fetchAccount(match.params.slug);
export default observer(Account);
