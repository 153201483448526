import React, { useCallback } from 'react';
import cn from 'classnames';
import { useLanguage } from 'hooks';
import { colors } from 'ui/theme';
import { ButtonLink, Icon } from 'ui/atoms';
import style from './style.module.scss';
export const TechSupportFooter = ({ className, redirectToTechSupportAction }) => {
    const { i18n } = useLanguage();
    const handleClick = useCallback((event) => {
        event.preventDefault();
        redirectToTechSupportAction();
    }, []);
    return (React.createElement("div", { className: cn(style.wrapper, className) },
        React.createElement("div", { className: style.tech__title }, i18n.t('FAQ_TECHSUPPORT_TITLE')),
        React.createElement(ButtonLink, { to: "/", onClick: handleClick, theme: "solid-gradient", className: style.tech__btn },
            React.createElement(Icon, { name: "headphones", width: "27", height: "27", fill: colors.white }),
            i18n.t('FAQ_TECHSUPPORT_BTN'))));
};
