import React from 'react';
import { observer } from 'mobx-react-lite';
import { ProfileTemplate } from 'ui/templates';
import { useStore } from 'hooks';
const Profile = ({ routes }) => {
    const { ticketsStore } = useStore();
    const notifications = ticketsStore.notifications;
    return (React.createElement(ProfileTemplate, { routes: routes, notifications: notifications }));
};
export default observer(Profile);
