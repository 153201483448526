import React from 'react';
import { Helmet } from 'react-helmet-async';
import { ProblemTemplate, NotFoundTemplate, } from 'ui/templates';
import { useMobile, useStore } from 'hooks';
import { observer } from 'mobx-react-lite';
const Account = () => {
    const { problemsStore, store, commonStore } = useStore();
    const { isAboveTablet } = useMobile();
    const problem = problemsStore.dataI18n;
    if (problemsStore.error || !problem) {
        return (React.createElement(NotFoundTemplate, { setStatusAction: store.setStatus }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, problem.localization.headTitle),
            React.createElement("meta", { name: "description", content: problem.localization.headDescription })),
        React.createElement(ProblemTemplate, { title: problem.localization.title, description: problem.localization.description, redirectToTechSupportAction: isAboveTablet
                ? commonStore.redirectToTechSupport
                : commonStore.mobileRedirectToTechSupport })));
};
export const preload = ({ store, match }) => store
    .problemsStore.fetchProblem(match.params.slug);
export default observer(Account);
