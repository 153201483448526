import React, { createContext } from 'react';
import { useStore } from 'hooks';
import { observer } from 'mobx-react-lite';
export const RoleContext = createContext({});
const RoleProvider = ({ children }) => {
    var _a, _b;
    const { authStore } = useStore();
    const isOwner = ((_a = authStore.data) === null || _a === void 0 ? void 0 : _a.role) === 'owner';
    const isTechSupport = ((_b = authStore.data) === null || _b === void 0 ? void 0 : _b.role) === 'techsupport';
    return (React.createElement(RoleContext.Provider, { value: {
            user: authStore.data,
            isAuth: authStore.isAuth,
            isOwner,
            isTechSupport,
            logout: authStore.logout,
        } }, children));
};
export default observer(RoleProvider);
