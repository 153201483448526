import React from 'react';
import { observer } from 'mobx-react-lite';
import { colors, vars } from 'ui/theme';
import { Icon } from 'ui/atoms';
import style from '../style.module.scss';
const FilesToUploadComponent = ({ files, removeFile }) => (React.createElement("div", { className: style.file__wrapper }, files.map(({ local, remote }) => (React.createElement("div", { key: remote
        ? remote.id
        : local.id, className: style.file__box },
    React.createElement("img", { src: local
            ? local.urlObj
            : remote.path, className: style.file__image, draggable: "false" }),
    remote && (React.createElement("div", { className: style.file__close, onClick: () => removeFile(remote.id) },
        React.createElement(Icon, { name: "trash", fill: colors.white, width: "10", height: "10" }))),
    !remote && typeof (local === null || local === void 0 ? void 0 : local.progress) !== 'undefined' && (React.createElement("div", { className: style.file__progress, style: vars(['--progress', `${local.progress / 100}`]) })))))));
export const FilesToUpload = observer(FilesToUploadComponent);
