import React from 'react';
import { Container, PageDescription } from 'ui/atoms';
import { useLanguage, useMobile } from 'hooks';
import { ProductHeader } from 'ui/molecules';
import { CheatsCatalog } from 'ui/organisms';
import { observer } from 'mobx-react-lite';
const GameTemplate = ({ game }) => {
    var _a;
    const { isMobile } = useMobile();
    const { i18n } = useLanguage();
    return (React.createElement(React.Fragment, null,
        React.createElement(ProductHeader, { title: i18n.t('CHEATS_CATALOG_TITLE').replace('#game#', game.name), alt: game.name, jpeg: isMobile
                ? game.backgroundVerticalJPEG
                : game.backgroundHorizontalJPEG, webp: isMobile
                ? game.backgroundVerticalWebP
                : game.backgroundHorizontalWebP }),
        React.createElement(CheatsCatalog, { gameSlug: game.slug, cheats: game.cheats }),
        React.createElement(Container, null,
            React.createElement(PageDescription, { html: (_a = game.localization) === null || _a === void 0 ? void 0 : _a.pageText }))));
};
export default observer(GameTemplate);
