export const languageMask = /^\/en/;
export const Language = {
    RU: 'ru',
    EN: 'en',
};
export const getDefaultLanguageState = () => Object.values(Language).reduce((acc, lang) => (Object.assign(Object.assign({}, acc), { [lang]: {} })), {});
export const SOCIAL_ICONS = [
    { icon: 'steam', link: '/api/users/steam-login' },
    { icon: 'google', link: '/api/users/google-login' },
    { icon: 'vk', link: '/api/users/vk-login' },
    { icon: 'discord', link: '/api/users/discord-login' },
];
export var Subject;
(function (Subject) {
    Subject["CheatProblem"] = "cheatProblem";
    Subject["PaymentProblem"] = "paymentProblem";
    Subject["AnotherQuestion"] = "anotherQuestion";
})(Subject || (Subject = {}));
export var MessageType;
(function (MessageType) {
    MessageType["Text"] = "text";
    MessageType["NeedHelpRequest"] = "need_help_request";
    MessageType["AutoClosed"] = "auto_closed";
    MessageType["Files"] = "files";
    MessageType["TextWithFiles"] = "text_with_files";
})(MessageType || (MessageType = {}));
