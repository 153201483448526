export const isHasError = (fieldState, formState) => {
    var _a;
    return (fieldState.isTouched || (formState === null || formState === void 0 ? void 0 : formState.isSubmitted))
        && ((_a = fieldState.error) === null || _a === void 0 ? void 0 : _a.message) || undefined;
};
export const validateNoCyrillic = (text) => {
    const regexCyrillic = (/[А-Яа-яёЁ]+/ig);
    return !text || regexCyrillic.test(String(text));
};
export const validateEmail = (email) => {
    if (!email) {
        return true;
    }
    if (validateNoCyrillic(email)) {
        return true;
    }
    const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return !re.test(String(email).toLowerCase());
};
export const validateRequired = (value) => Array.isArray(value)
    ? value.length === 0
    : !value || typeof value === 'undefined' || value === null;
export const validateMinLength = (text, length) => {
    if (!text) {
        return true;
    }
    return text.length < length;
};
export const validateMaxLength = (text, length) => {
    if (!text) {
        return true;
    }
    return text.length > length;
};
export const errorMsg = (message) => ({
    type: 'manual',
    message,
});
