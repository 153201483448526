import React from 'react';
import { Switch } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { renderRoutes } from 'libs/router';
import { ModalPopup } from 'ui/organisms';
import { routes } from 'routes';
import 'styles/main.scss';
const App = () => (React.createElement(React.Fragment, null,
    React.createElement(ModalPopup, null),
    React.createElement(Switch, null, renderRoutes(routes))));
export default observer(App);
