import React from 'react';
import { Helmet } from 'react-helmet-async';
import { GameTemplate, NotFoundTemplate } from 'ui/templates';
import { useStore } from 'hooks';
import { observer } from 'mobx-react-lite';
const Game = () => {
    const { gamesStore, store } = useStore();
    const setStatusAction = store.setStatus;
    const error = gamesStore.error;
    const game = gamesStore.dataI18n;
    if (error || !game) {
        return (React.createElement(NotFoundTemplate, { setStatusAction: setStatusAction }));
    }
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, game.localization.pageTitle),
            React.createElement("meta", { name: "description", content: game.localization.pageDescription })),
        React.createElement(GameTemplate, { game: game })));
};
export const preload = ({ store, match }) => store
    .gamesStore.fetchGame(match.params.slug);
export default observer(Game);
