import React from 'react';
import { useLanguage } from 'hooks';
import style from './style.module.scss';
const RequirementsTemplate = ({ requirements }) => {
    const { i18n } = useLanguage();
    return (React.createElement("div", { className: style.wrapper, "data-empty": (requirements === null || requirements === void 0 ? void 0 : requirements.length) === 0 }, (requirements === null || requirements === void 0 ? void 0 : requirements.length)
        ? requirements.map(({ id, name, value }) => (React.createElement("div", { key: id, className: style.row },
            React.createElement("div", { className: style.name },
                React.createElement("span", null, name)),
            React.createElement("div", { className: style.value }, value))))
        : i18n.t('CHEAT_TAB_REQUIREMENTS_EMPTY')));
};
export default RequirementsTemplate;
