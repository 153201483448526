import React, { useRef, } from 'react';
import { useLanguage } from 'hooks';
import { observer } from 'mobx-react-lite';
import { Tickets } from './components';
import style from './style.module.scss';
const TicketsTable = ({ tickets, groupedMessages, messagerStatus, needHelpActionVisible, needOwnerHelpAction, blockUserByTicketAction, }) => {
    const { i18n } = useLanguage();
    const rowsRef = useRef(null);
    return (React.createElement("div", { className: style.wrapper },
        React.createElement("div", { className: style.header },
            React.createElement("div", { className: style.header__title }, i18n.t('TICKETS_TABLE_TITLE')),
            React.createElement("div", { className: style.header__row },
                React.createElement("div", { className: style['header__col-1'] }, i18n.t('TICKETS_TABLE_COL_1')),
                React.createElement("div", { className: style['header__col-2'] }, i18n.t('TICKETS_TABLE_COL_2')),
                React.createElement("div", { className: style['header__col-3'] }, i18n.t('TICKETS_TABLE_COL_3')))),
        React.createElement(Tickets, { ref: rowsRef, tickets: tickets, groupedMessages: groupedMessages, messagerStatus: messagerStatus, needHelpActionVisible: needHelpActionVisible, needOwnerHelpAction: needOwnerHelpAction, blockUserByTicketAction: blockUserByTicketAction })));
};
export default observer(TicketsTable);
