import React from 'react';
import { observer } from 'mobx-react-lite';
import { ScrollArea } from 'libs/Scrollbar';
import { useLanguage, useMount, useStore } from 'hooks';
import { formatMsgDate } from 'utils/functions';
import style from './Messages.module.scss';
const Messages = () => {
    const lang = useLanguage();
    const { aiChatStore } = useStore();
    const isMobile = window.innerWidth <= 500;
    useMount(() => {
        if (isMobile) {
            document.body.classList.add('disable-scroll');
            return () => document.body.classList.remove('disable-scroll');
        }
    });
    return (React.createElement(ScrollArea, { containerHeight: isMobile ? window.innerHeight - 131 : 269, containerClassName: style.scrollbar, direction: "reverse" }, aiChatStore.allList.map(msg => (React.createElement("div", { key: msg.id, className: style.row, "data-bot": msg.isBot },
        React.createElement("div", { className: style.message },
            msg.text,
            React.createElement("div", { className: style.date }, formatMsgDate(msg.createdAt, lang))))))));
};
export default observer(Messages);
