import React, { useEffect, useRef, useState, } from 'react';
import { NavLink } from 'react-router-dom';
import { useLanguage } from 'hooks';
import { colors } from 'ui/theme';
import { Icon } from 'ui/atoms';
import style from '../style.module.scss';
export const NavbarItem = ({ text, to, subLinks }) => {
    const { language, i18n, makeUrl } = useLanguage();
    const linkRef = useRef(null);
    const [width, setWidth] = useState(0);
    useEffect(() => {
        if (linkRef.current) {
            setWidth(linkRef.current.clientWidth + 1);
        }
    }, []);
    useEffect(() => {
        setWidth(0);
    }, [language]);
    if (subLinks) {
        return (React.createElement("div", { className: style.link, "data-sub-links": "true" },
            i18n.t(text),
            React.createElement(Icon, { name: "sm-arrow-down", width: "8", height: "8", fill: colors.white }),
            React.createElement("div", { className: style.dropdown }, subLinks.map(subLink => (React.createElement(NavLink, Object.assign({ key: subLink.to, to: makeUrl(subLink.to), className: style.sublink, activeClassName: style.sublink__active }, (subLink.to === '/faq'
                ? { 'data-faq': true }
                : {})), i18n.t(subLink.text)))))));
    }
    return (React.createElement(NavLink, { ref: linkRef, exact: true, to: makeUrl(to), className: style.link, activeClassName: style.link__active, style: width ? { width } : {} }, i18n.t(text)));
};
