import React from 'react';
import { observer } from 'mobx-react-lite';
import { Helmet } from 'react-helmet-async';
import { RequirementsTemplate } from 'ui/templates';
import { useLanguage, useStore } from 'hooks';
const Requirements = () => {
    const { cheatsStore } = useStore();
    const { i18n } = useLanguage();
    const requirementsData = cheatsStore.selectRequirements;
    const data = cheatsStore.selectData;
    const cheatTitle = i18n.t('CHEAT_REQUIREMENTS_TITLE')
        .replace('#cheat#', data.name)
        .replace('\n', '');
    const gameTitle = i18n.t('CHEAT_SUBTITLE')
        .toLowerCase()
        .replace('#game#', data.game.name);
    const description = i18n.t('CHEAT_REQUIREMENTS_DESCRIPTION')
        .replace('#game#', data.game.name)
        .replace('#features#', requirementsData.features.join(', '));
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, `${cheatTitle} ${gameTitle}`),
            React.createElement("meta", { name: "description", content: description })),
        React.createElement(RequirementsTemplate, { requirements: requirementsData.requirements })));
};
export const preload = ({ store, match }) => {
    const { gameSlug, cheatSlug } = match.params;
    return store.cheatsStore.fetchRequirements(gameSlug, cheatSlug);
};
export default observer(Requirements);
