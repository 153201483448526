import React, { forwardRef } from 'react';
import { useLanguage } from 'hooks';
import { getStatus } from 'ui/theme';
import { StatusBox } from 'ui/atoms';
import style from './style.module.scss';
const tooltipArrowIcon = (React.createElement("svg", { xmlns: "http://www.w3.org/2000/svg", width: "30", height: "6", viewBox: "0 0 30 6" },
    React.createElement("path", { fill: "url(#asdads)", d: "M.5 0h29-1.71a8.8 8.8 0 00-6.4 2.75 8.8 8.8 0 01-12.78 0A8.8 8.8 0 002.2 0H.5z" }),
    React.createElement("defs", null,
        React.createElement("linearGradient", { id: "asdads", x1: "-64.5", x2: "264", y1: "-36", y2: "-28", gradientUnits: "userSpaceOnUse" },
            React.createElement("stop", { stopColor: "#2D2D2D" }),
            React.createElement("stop", { offset: ".98", stopColor: "#363636" })))));
export const StatusSingletoneTooltip = forwardRef(({ status, isUnmounting }, ref) => {
    const { i18n } = useLanguage();
    return (React.createElement("div", { ref: ref, className: style.position, "data-name": "tooltip" },
        React.createElement("div", { className: style.wrapper, "data-unmouting": isUnmounting },
            React.createElement(StatusBox, { status: status }),
            React.createElement("span", { className: style.hint__text }, i18n.t(getStatus(status).hint)),
            React.createElement("div", { "data-popper-arrow": true }, tooltipArrowIcon))));
});
StatusSingletoneTooltip.displayName = 'StatusSingletoneTooltip';
