var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import { action, configure, makeObservable, observable, toJS, } from 'mobx';
import { enableStaticRendering } from 'mobx-react-lite';
import { RouterStore, syncHistoryWithStore } from 'mobx-react-router';
import { createBrowserHistory, createMemoryHistory, } from 'history';
import { isBrowser } from 'utils/selectors';
import { BestGamesStore } from 'ducks/bestGames';
import { AIChatStore } from 'ducks/ai-chat';
import { CommonStore } from 'ducks/common';
import { AuthStore } from 'ducks/auth';
import { CheatsStore } from 'ducks/cheats';
import { GamesStore } from 'ducks/games';
import { ModalStore } from 'ducks/modal';
import { TicketsStore } from 'ducks/tickets';
import { AccountsStore } from 'ducks/accounts';
import { ProblemsStore } from 'ducks/problems';
export class Store {
    constructor(routingStore, initialState) {
        Object.defineProperty(this, "routingStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "bestGamesStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "ticketsStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "accountsStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "commonStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "cheatsStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "gamesStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "modalStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "authStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "aiChatStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "problemsStore", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: void 0
        });
        Object.defineProperty(this, "status", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: null
        });
        Object.defineProperty(this, "setStatus", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (status) => {
                this.status = status;
            }
        });
        Object.defineProperty(this, "serialize", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: () => ({
                bestGamesStore: this.serializeChild(this.bestGamesStore),
                commonStore: this.serializeChild(this.commonStore),
                cheatsStore: this.serializeChild(this.cheatsStore),
                authStore: this.serializeChild(this.authStore),
                gamesStore: this.serializeChild(this.gamesStore),
                modalStore: this.serializeChild(this.modalStore),
                accountsStore: this.serializeChild(this.accountsStore),
                problemsStore: this.serializeChild(this.problemsStore),
                aiChatStore: this.serializeChild(this.aiChatStore),
                status: this.status,
            })
        });
        Object.defineProperty(this, "serializeChild", {
            enumerable: true,
            configurable: true,
            writable: true,
            value: (_a) => {
                var { store: _ } = _a, stores = __rest(_a, ["store"]);
                return toJS(stores);
            }
        });
        this.bestGamesStore = new BestGamesStore(this);
        this.accountsStore = new AccountsStore(this);
        this.ticketsStore = new TicketsStore(this);
        this.commonStore = new CommonStore(this);
        this.cheatsStore = new CheatsStore(this);
        this.gamesStore = new GamesStore(this);
        this.modalStore = new ModalStore(this);
        this.authStore = new AuthStore(this);
        this.aiChatStore = new AIChatStore(this);
        this.problemsStore = new ProblemsStore(this);
        this.routingStore = routingStore;
        makeObservable(this, {
            status: observable,
            setStatus: action.bound,
        });
        if (initialState)
            this.loadFromJson(initialState);
    }
    loadFromJson(initialState) {
        for (const key in initialState) {
            const storeKey = key;
            const initialStore = initialState[storeKey];
            const childStore = this[storeKey];
            if (storeKey === 'status') {
                this.status = initialStore;
            }
            else {
                for (const prop in initialStore) {
                    childStore[prop] = initialStore[prop];
                }
            }
        }
    }
}
enableStaticRendering(!isBrowser);
configure({
    enforceActions: isBrowser
        ? 'observed'
        : 'never',
});
export const createStore = (url = '/') => {
    const history = isBrowser
        ? createBrowserHistory()
        : createMemoryHistory({ initialEntries: [url] });
    const routingStore = new RouterStore();
    const storeHistory = syncHistoryWithStore(history, routingStore);
    const store = isBrowser
        ? new Store(routingStore, window.SSR_STATE)
        : new Store(routingStore);
    return {
        storeHistory,
        history,
        store,
    };
};
