import React, { useCallback, useMemo } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import cn from 'classnames';
import { useInput, useLanguage, useUpdate } from 'hooks';
import { getUrlParam } from 'utils/selectors';
import { debounce } from 'utils/functions';
import { SearchInput } from 'ui/atoms';
import style from './style.module.scss';
const SimpleSearchForm = ({ pathname = '/catalog', titleI18n = 'GAMES_CATALOG_TITLE', className, }) => {
    const { i18n } = useLanguage();
    const history = useHistory();
    const { search } = useLocation();
    const searchParam = useMemo(() => getUrlParam(search, 'search'), [search]);
    const { props: input } = useInput(searchParam || '');
    const handleRedirect = useCallback((value) => history.push({
        pathname,
        search: value
            ? `?search=${value}`
            : undefined,
    }), []);
    const handleDebouncedRedirect = useCallback(debounce(handleRedirect, 300), []);
    const handleSearchClick = useCallback(() => handleRedirect(input.value), [input.value]);
    useUpdate(() => {
        handleDebouncedRedirect(input.value);
    }, [input.value]);
    const title = i18n.t(searchParam
        ? 'SEARCH_RESULT_TITLE'
        : titleI18n);
    return (React.createElement("div", { className: cn(style.header, className) },
        React.createElement("div", { className: style.title }, (searchParam
            ? title.replace('#search#', searchParam)
            : title.toUpperCase())),
        React.createElement(SearchInput, Object.assign({}, input, { placeholder: i18n.t('SEARCH_PLACEHOLDER'), onSearchClick: handleSearchClick }))));
};
export default SimpleSearchForm;
