import React from 'react';
import { ChangePasswordForm, ChangeEmailForm } from 'forms/Profile';
import { PersonalSocials } from 'ui/molecules';
import { PersonalFormsGroup } from 'ui/organisms';
import { observer } from 'mobx-react-lite';
import style from './style.module.scss';
const PersonalTemplate = ({ user, changeAvatarAction, changePasswordAction, changeEmailAction, changeLoginAction, }) => (React.createElement("div", { className: style.wrapper },
    React.createElement(PersonalFormsGroup, { user: user, changeAvatarAction: changeAvatarAction, changeLoginAction: changeLoginAction }),
    React.createElement(ChangePasswordForm, { changePasswordAction: changePasswordAction }),
    React.createElement(ChangeEmailForm, { changeEmailAction: changeEmailAction }),
    React.createElement(PersonalSocials, { vk: user.vk, steam: user.steam, discord: user.discord, google: user.google })));
export default observer(PersonalTemplate);
