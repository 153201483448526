import React, { useMemo } from 'react';
import { Container, Image } from 'ui/atoms';
import AccountOrderForm from 'forms/AccountOrderForm';
import { HighlightCarousel } from 'ui/molecules';
import AccountCardTabs from '../AccountCardTabs';
import style from './style.module.scss';
const AccountCardDetail = ({ account }) => {
    const highlights = useMemo(() => [
        {
            id: 'default',
            type: 'image',
            src: {
                webp: account.backgroundHorizontalWebP,
                jpeg: account.backgroundHorizontalJPEG,
            },
        },
        ...account.screenshots.map(({ id, previewJPEG, previewWebP }) => ({
            id,
            type: 'image',
            src: {
                webp: previewWebP,
                jpeg: previewJPEG,
            },
        })),
    ], [account]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Container, null,
            React.createElement("div", { className: style.wrapper },
                React.createElement(HighlightCarousel, { highlights: highlights }),
                React.createElement(AccountOrderForm, { name: account.name, prices: account.prices, globalPrices: account.globalPrices, isPublished: account.isPublished }),
                React.createElement(AccountCardTabs, { description: account.localization.description }))),
        React.createElement("div", { className: style.spacer },
            React.createElement(Image, { alt: "RadialRedGradeint", jpeg: "/public/images/backgrounds/RadialRedGradient.jpg", webp: "/public/images/backgrounds/RadialRedGradient.webp" }))));
};
export default AccountCardDetail;
