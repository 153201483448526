import React, { useCallback, useMemo } from 'react';
import { Helmet } from 'react-helmet-async';
import { observer } from 'mobx-react-lite';
import { useLanguage, useMobile, useStore } from 'hooks';
import { ProblemsCatalog as ProblemsCatalogList } from 'ui/organisms';
import { getUrlParam } from 'utils/selectors';
import { ProductHeader } from 'ui/molecules';
const ProblemsCatalog = ({ location }) => {
    const { i18n } = useLanguage();
    const { isAboveTablet, isMobile } = useMobile();
    const { problemsStore, commonStore } = useStore();
    const problemsList = problemsStore.allI18nList;
    const pagination = problemsStore.pagination;
    const problemsSearchList = problemsStore.searchI18nList;
    const searchPagination = problemsStore.searchPagination;
    const isLoading = problemsStore.pending;
    const search = useMemo(() => getUrlParam(location.search, 'search'), [location.search]);
    const loadMoreAction = useCallback(() => search
        ? problemsStore.searchProblemsList(true)
        : problemsStore.fetchProblemsList(true), [search]);
    return (React.createElement(React.Fragment, null,
        React.createElement(Helmet, null,
            React.createElement("title", null, i18n.t(search
                ? 'SEARCH_RESULT_LOWER_TITLE'
                : 'PROBLEMS_CATALOG_TITLE').replace('#search#', search))),
        !isMobile && (React.createElement(ProductHeader, { webp: "/public/images/backgrounds/RadialOrangeGradient.webp", jpeg: "/public/images/backgrounds/RadialOrangeGradient.jpg", alt: "RadialOrangeGradient" })),
        React.createElement(ProblemsCatalogList, { problems: search
                ? problemsSearchList
                : problemsList, search: search, isLoading: isLoading, isShowLoadMore: search
                ? !!searchPagination.nextPage
                : !!pagination.nextPage, loadMoreAction: loadMoreAction, redirectToTechSupportAction: isAboveTablet
                ? commonStore.redirectToTechSupport
                : commonStore.mobileRedirectToTechSupport })));
};
export const preload = ({ store, location }) => location.search
    ? store.problemsStore.searchProblemsList()
    : store.problemsStore.fetchProblemsList();
export default observer(ProblemsCatalog);
