import React, { StrictMode } from 'react';
import ReactGA from 'react-ga';
import { HelmetProvider } from 'react-helmet-async';
import { Router } from 'react-router-dom';
import { AdaptiveProvider, LanguageProvider, RoleProvider, SocketProvider, StoreProvider, } from 'providers';
import { isDevelopment } from 'utils/selectors';
import { createStore } from 'store/main';
import { useMount } from 'hooks';
import { initI18N } from 'i18n';
import App from './App';
const { store, storeHistory } = createStore();
const i18n = initI18N(window.SSR_STATE.commonStore.language);
if (process.env.RAZZLE_APP_GOOGLE_ANALYTICS_ID && !isDevelopment) {
    ReactGA.initialize(process.env.RAZZLE_APP_GOOGLE_ANALYTICS_ID);
    ReactGA.pageview(window.location.pathname + window.location.search);
    storeHistory.listen(location => ReactGA.pageview(location.pathname + location.search));
}
const Root = () => {
    useMount(() => {
        window.HYDRATED = true;
    });
    return (React.createElement(StrictMode, null,
        React.createElement(AdaptiveProvider, null,
            React.createElement(HelmetProvider, null,
                React.createElement(StoreProvider, { store: store },
                    React.createElement(LanguageProvider, { initialI18n: i18n },
                        React.createElement(RoleProvider, null,
                            React.createElement(SocketProvider, null,
                                React.createElement(Router, { history: storeHistory },
                                    React.createElement(App, null))))))))));
};
export default Root;
