import { useRef } from 'react';
import { useLayoutSSR } from './useLayoutSSR';
export const useResizeObserver = (cb, elementRef, deps = []) => {
    const resizeObserverRef = useRef();
    useLayoutSSR(() => {
        if (!resizeObserverRef.current && elementRef.current) {
            resizeObserverRef.current = new ResizeObserver(entries => {
                if (!Array.isArray(entries))
                    return;
                cb();
            });
            resizeObserverRef.current.observe(elementRef.current);
            return () => {
                var _a;
                elementRef.current
                    && ((_a = resizeObserverRef.current) === null || _a === void 0 ? void 0 : _a.unobserve(elementRef.current));
            };
        }
    }, [cb, ...deps]);
};
