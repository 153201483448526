import React from 'react';
import { Link } from 'react-router-dom';
import { useLanguage } from 'hooks';
import { Button, GradientBorder, Image, Price, } from 'ui/atoms';
import { StatusLazyTooltip } from '../StatusLazyTooltip';
import style from './style.module.scss';
export const Recommendation = ({ to, title, status, minPrice, features, previewJPEG, previewWebP, isPriceOutOfStock, }) => {
    const { makeUrl, i18n } = useLanguage();
    return (React.createElement(Link, { to: makeUrl(to), className: style.wrapper },
        React.createElement("div", { className: style['preview__wrapper'] },
            React.createElement(Button, { theme: "outline", className: style['preview__btn'] }, i18n.t('MORE_DETAILS')),
            React.createElement("div", { className: style['preview__position'] },
                React.createElement(Image, { jpeg: previewJPEG, webp: previewWebP, alt: title, className: style.preview }))),
        React.createElement("div", null,
            React.createElement("div", { className: style['title__wrapper'] },
                React.createElement("div", { className: style.title }, title),
                React.createElement(StatusLazyTooltip, { status: status, id: to })),
            React.createElement("div", { className: style['price__wrapper'] }, isPriceOutOfStock
                ? (React.createElement(Price, null)) : (React.createElement(React.Fragment, null,
                React.createElement(Price, { from: true, amount: (minPrice === null || minPrice === void 0 ? void 0 : minPrice.discountPrice) || (minPrice === null || minPrice === void 0 ? void 0 : minPrice.price), currency: minPrice === null || minPrice === void 0 ? void 0 : minPrice.currency, className: style.price }),
                (minPrice === null || minPrice === void 0 ? void 0 : minPrice.discountPrice) && (React.createElement(Price, { amount: minPrice === null || minPrice === void 0 ? void 0 : minPrice.price, currency: minPrice === null || minPrice === void 0 ? void 0 : minPrice.currency, type: "discount" }))))),
            React.createElement("div", { className: style.feature__wrapper }, features.map(feature => (React.createElement("div", { className: style.feature, key: feature }, feature))))),
        React.createElement(GradientBorder, { idPrefix: to, size: "mlarge", viewBox: "0 0 444 187" })));
};
