import { RenderRoutesPlaceholder } from 'libs/router';
import * as Profile from './Profile';
import GeneralInformation from './Tabs/GeneralInformation';
import Personal from './Tabs/Personal';
import Tickets from './Tickets';
import TechSupportTabs from './TechSupport/TechSupportTabs';
import TicketsOnReview from './TechSupport/TicketsOnReview';
import MyTickets from './TechSupport/MyTickets';
import Closed from './TechSupport/Closed';
import OwnerTabs from './Owner/OwnerTabs';
import OwnerNeedHelp from './Owner/OwnerNeedHelp';
import OwnerMy from './Owner/OwnerMy';
import OwnerAll from './Owner/OwnerAll';
export const routes = [
    {
        path: '/profile',
        private: true,
        component: Profile.default,
        routes: [
            {
                path: '',
                exact: true,
                component: GeneralInformation,
            },
            {
                path: '/personal',
                component: Personal,
            },
            {
                path: '/tickets',
                component: RenderRoutesPlaceholder,
                routes: [
                    {
                        path: '',
                        exact: true,
                        component: Tickets,
                    },
                ],
            },
            {
                path: '/techsupport',
                component: TechSupportTabs,
                roles: ['techsupport', 'owner'],
                routes: [
                    {
                        path: '',
                        exact: true,
                        component: TicketsOnReview,
                    },
                    {
                        path: '/my',
                        component: MyTickets,
                    },
                    {
                        path: '/closed',
                        component: Closed,
                    },
                ],
            },
            {
                path: '/owner',
                component: OwnerTabs,
                roles: ['owner'],
                routes: [
                    {
                        path: '',
                        exact: true,
                        component: OwnerNeedHelp,
                    },
                    {
                        path: '/my',
                        component: OwnerMy,
                    },
                    {
                        path: '/all',
                        component: OwnerAll,
                    },
                ],
            },
        ],
    },
];
