import React from 'react';
import { useParams } from 'react-router-dom';
import { useLanguage } from 'hooks';
import { HTMLPreviewer, TabLink } from 'ui/atoms';
import style from './style.module.scss';
const AccountCardTabs = ({ description }) => {
    const { i18n } = useLanguage();
    const { slug } = useParams();
    const baseUrl = `/account/${slug}`;
    return (React.createElement("div", null,
        React.createElement("div", { className: style.tabs },
            React.createElement(TabLink, { to: baseUrl, exact: true, className: style.tab }, i18n.t('ACCOUNT_DESCRIPTION'))),
        React.createElement(HTMLPreviewer, { html: description })));
};
export default AccountCardTabs;
